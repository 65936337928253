

     //pads left
    String.prototype.pad = function(length) {
        var padString = "0";
        var str = this;
        while (str.length < length)
            str = padString + str;
        if(str.length > length) str = str.substr(0, length);

        return str;
    }
    String.prototype.lpad = function(padString, length) {
        var str = this;
        while (str.length < length)
            str = padString + str;
        if(str.length > length) str = str.substr(0, length);

        return str;
    }

    //pads right
    String.prototype.rpad = function(padString, length) {
        var str = this;
        while (str.length < length)
            str = str + padString;
        if(str.length > length) str = str.substr(0, length);
        
        return str;
    }

    //trimming space from both side of the string
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g,"");
    }
     
    //trimming space from left side of the string
    String.prototype.ltrim = function() {
        return this.replace(/^\s+/,"");
    }

    //trimming space from right side of the string
    String.prototype.rtrim = function() {
        return this.replace(/\s+$/,"");
    }
    //trimming space and zero from left side of the string
    String.prototype.ztrim = function() {
        if(this.length < 1) return "";
        return this.trim().replace(/^[0]+/,"");
    }
    Number.prototype.ztrim = function() {
		 var str = this;
		 var tkn = "";
		 str = str.toString();
        return str;
    }

    String.prototype.lastnrs = function() {
        var str = this;
        str = str.ltrim();
        //str = str.replace("0","");

		var from = str.length - 5;
		str = str.substr(from, 5);

        return str;
    };
    String.prototype.tonum = function(length) {
        var str = this;
        var padString = "0";
        str = str.ltrim();
        //str = str.replace("0","");

        while (str.length < length)
            str = padString + str;
        if(str.length > length) str = str.substr(0, length);

        return str;
    };
    String.prototype.topris = function(length) {
        var str = this;
        var padString = "0";
        str = str.trim();
        if(str.length < 1) str = "0";
        var num = parseInt(str);
		num = Math.round(num * 100);
		var pris = num.toString();

        while (pris.length < length)
            pris = padString + pris;
        if(pris.length > length) pris = pris.substr(pris.length-length);

        return pris;
    };
    String.prototype.totid = function() {
        var str = this;
        var padString = "0";
        var length = 4;
        str = str.ltrim();
        var rc = str.indexOf(".");
        if(rc != -1) str = str.replace(".","");
		//else str = str + "00";
		if(str.length == 1) str = "0" + str + "00";
		if(str.length == 2) str = str + "00";
		if(str.length == 3) str = "0" + str;
        while (str.length < length)
            str = padString + str;
        if(str.length > length) str = str.substr(str.length-length);

        return str;
    }
	 String.prototype.dec2 = function() {
		 var str = this;
		 str = str.toString();
		 str = str.ztrim();
		var rc = str.indexOf(".");
		if(rc != -1) str = str.replace(".","");

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);

		 return str;
	 }
	 Number.prototype.dec2 = function() {
		 var str = this;
		 str = str.toString();
		 str = str.ztrim();
        var rc = str.indexOf(".");
        if(rc != -1) str = str.replace(".","");

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);

		 return str;
	 }
	 String.prototype.jgdpris = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(num > 799999999900) {
			 num = 1000000000000 - num;
			 str = "-" + num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);

		 return str;
	 }
	 String.prototype.ekopris = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(num > 79999999900) {
			 num = 100000000000 - num;
			 str = "-" + num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);
		 if(str.length > 6) str = str.substr(0, (str.length - 6)) + " " + str.substr((str.length - 6));

		 return str;
	 }
	 String.prototype.formatpris = function() {
		 var str = this;
		 var tkn = "";
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(str.substr(0, 1) == "-") {
			tkn = "-";
			str = str.substr(1);
		 	num = parseInt(str);
		 }
		 if(num > 79999999900) {
			 num = 100000000000 - num;
			 tkn = "-";
			 str = num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);
		 if(str.length == 2) str = "0." + str;
		 if(str.length == 1) str = "0.0" + str;
		 if(num == 0) str = "0.00";
		 if(str.length > 6) str = str.substr(0, (str.length - 6)) + " " + str.substr((str.length - 6), 6);
		 if(str.length > 10) str = str.substr(0, (str.length - 10)) + " " + str.substr((str.length - 10), 10);
		 str = tkn + str;

		 return str;
	 }
	 Number.prototype.formatpris = function() {
		 var str = this;
		 var tkn = "";
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(str.substr(0, 1) == "-") {
			tkn = "-";
			str = str.substr(1);
		 	num = parseInt(str);
		 }
		 if(num > 799999999900) {
			 num = 1000000000000 - num;
			 tkn = "-";
			 str = num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);
		 if(str.length == 2) str = "0." + str;
		 if(str.length == 1) str = "0.0" + str;
		 if(num == 0) str = "0.00";
		 if(str.length > 6) str = str.substr(0, (str.length - 6)) + " " + str.substr((str.length - 6), 6);
		 if(str.length > 10) str = str.substr(0, (str.length - 10)) + " " + str.substr((str.length - 10), 10);
		 str = tkn + str;

		 return str;
	 }
	 String.prototype.cdipris = function() {
		 var str = this;
		 var tkn = "";
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(str.length < 1) {
			str = "0";
			num = parseInt(str);
		 }

		 if(str.substr(0, 1) == "-") {
			tkn = "-";
			str = str.substr(1);
		 	num = parseInt(str);
		 }
		 if(num > 79999999900) {
			 num = 100000000000 - num;
			 tkn = "-";
			 str = num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "." + str.substr((str.length - 2), 2);
		 if(str.length == 2) str = "0." + str;
		 if(str.length == 1) str = "0.0" + str;
		 if(num == 0) str = "0.00";
		 if(str.length > 6) str = str.substr(0, (str.length - 6)) + "." + str.substr((str.length - 6), 6);
		 if(str.length > 10) str = str.substr(0, (str.length - 10)) + "." + str.substr((str.length - 10), 10);
		 str = tkn + str;

		 return str;
	 }
	 Number.prototype.cdipris = function() {
		 var str = this;
		 var tkn = "";
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Pris : " + num);

		 if(str.substr(0, 1) == "-") {
			tkn = "-";
			str = str.substr(1);
		 	num = parseInt(str);
		 }
		 if(num > 799999999900) {
			 num = 1000000000000 - num;
			 tkn = "-";
			 str = num.toString();
		 }
		 else str = num.toString();

		 if(str.length > 2) str = str.substr(0, (str.length - 2)) + "," + str.substr((str.length - 2), 2);
		 if(str.length == 2) str = "0." + str;
		 if(str.length == 1) str = "0.0" + str;
		 if(num == 0) str = "0.00";
		 if(str.length > 6) str = str.substr(0, (str.length - 6)) + "." + str.substr((str.length - 6), 6);
		 if(str.length > 10) str = str.substr(0, (str.length - 10)) + "." + str.substr((str.length - 10), 10);

		 str = tkn + str;

		 return str;
	 }
	 String.prototype.pris2cdi = function() {
		 var str = this;
         var length = 11;
         var padString = "0";
		 str = str.toString();
		 str = str.trim();
         if(str.length < 1) str = "0";
         var rc = str.indexOf(".");
         if(rc != -1) str = str.replace(".","");
		 else str = str + "00";
		 var num = parseInt(str);
		 var pris = num.toString();

        while (pris.length < length)
            pris = padString + pris;
        if(pris.length > length) pris = pris.substr(pris.length-length);

        return pris;
	 }

	 String.prototype.cdixnum = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Numeric : " + num);

		 if(num > 79999999900) {
			 num = 100000000000 - num;
			 str = "-" + num.toString();
		 }
		 else str = num.toString();

		 return str;
	 }
	 String.prototype.cdinum = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Numeric : " + num);

		 if(num > 799999999900) {
			 num = 1000000000000 - num;
			 str = "-" + num.toString();
		 }
		 else str = num.toString();

		 return str;
	 }
	 Number.prototype.cdinum = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Numeric : " + num);

		 if(num > 799999999900) {
			 num = 1000000000000 - num;
			 str = "-" + num.toString();
		 }
		 else str = num.toString();

		 return str;
	 }
	 String.prototype.numcdi = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Numeric : " + num);

		 if(num < 0) {
			 num = 100000000000 + num;
		 }
		 str = num.toString();

		 return str;
	 }
	 Number.prototype.numcdi = function() {
		 var str = this;
		 str = str.toString();
		 var num = parseInt(str);
		 //console.log("CDI Numeric : " + num);

		 if(num < 0) {
			 num = 100000000000 + num;
		 }
		 str = num.toString();

		 return str;
	 }
    String.prototype.sweday = function() {
  	  var sv = this;
  	  if(sv == "Mon") sv = "Mån";
  	  if(sv == "Tue") sv = "Tis";
  	  if(sv == "Wed") sv = "Ons";
  	  if(sv == "Thu") sv = "Tor";
  	  if(sv == "Fri") sv = "Fre";
  	  if(sv == "Sat") sv = "Lör";
  	  if(sv == "Sun") sv = "Sön";
  	  return sv;
	};
    String.prototype.txt2cdi = function() {
  	  var sv = this;
  	  sv = sv.replace(/\0/g," ");
  	  sv = sv.replace(/\ö/g,"|");
  	  sv = sv.replace(/\Ö/g,"\\");
  	  sv = sv.replace(/\ä/g,"{");
  	  sv = sv.replace(/\Ä/g,"[");
  	  sv = sv.replace(/\å/g,"}");
  	  sv = sv.replace(/\Å/g,"]");
  	  return sv;
	};
    String.prototype.cdi2txt = function() {
  	  var sv = this;
  	  sv = sv.replace(/\|/g,"ö");
  	  sv = sv.replace(/\\/g,"Ö");
  	  sv = sv.replace(/\{/g,"ä");
  	  sv = sv.replace(/\[/g,"Ä");
  	  sv = sv.replace(/\}/g,"å");
  	  sv = sv.replace(/\]/g,"Å");
  	  sv = sv.replace(/\s+$/,"");
  	  return sv;
	};
    String.prototype.toswe = function() {
  	  var sv = this;
  	  sv = sv.replace(/\|/g,"ö");
  	  sv = sv.replace(/\\/g,"Ö");
  	  sv = sv.replace(/#/g,"Ö");
  	  sv = sv.replace(/\{/g,"ä");
  	  sv = sv.replace(/\[/g,"Ä");
  	  sv = sv.replace(/\}/g,"å");
  	  sv = sv.replace(/\]/g,"Å");
  	  sv = sv.replace(/\s+$/,"");
        return sv;
      }
    String.prototype.fromswe = function() {
  	  var sv = this;
  	  sv = sv.replace(/\ö/g,"|");
  	  sv = sv.replace(/\Ö/g,"\\");
  	  sv = sv.replace(/\ä/g,"{");
  	  sv = sv.replace(/\Ä/g,"[");
  	  sv = sv.replace(/\å/g,"}");
  	  sv = sv.replace(/\Å/g,"]");
  	  return sv;
      }
    String.prototype.noswedots = function() {
  	  var sv = this;
  	  sv = sv.replace(/\ö/g,"o");
  	  sv = sv.replace(/\Ö/g,"O");
  	  sv = sv.replace(/\ä/g,"a");
  	  sv = sv.replace(/\Ä/g,"A");
  	  sv = sv.replace(/\å/g,"a");
  	  sv = sv.replace(/\Å/g,"A");
  	  return sv;
      }
	  String.prototype.fromcdidate = function() {
		 var input = this;
		 if(!input) return "";
		 if(input == undefined) return "";
		 if(input.length != 6) return input;

			var y = input.substr(0,1);
			var ymmdd = input.substr(1,5);
			if(y == "A") y = "0";
			if(y == "B") y = "1";
			if(y == "C") y = "2";
			if(y == "D") y = "3";
			var datum = "20" +  y + ymmdd;

			return datum;
      };
	  String.prototype.tocdidate = function() {
		 var input = this;
		 if(!input) return "";
		 if(input == undefined) return "";

         var rc = input.indexOf(".");
         if(rc != -1) input = input.replace(".","");
         rc = input.indexOf("-");
         if(rc != -1) input = input.replace("-","");

		 var datum = input;
		 if(input.length == 6) {
		 	var yy = input.substr(0, 2);
		 	var mmdd = input.substr(2, 4);

		 	var pre = input.substr(0, 1);
		 	var y1 = input.substr(1, 1);
		 	if(pre == "0") yy = "A" + y1;
		 	if(pre == "1") yy = "B" + y1;
		 	if(pre == "2") yy = "C" + y1;
		 	if(pre == "3") yy = "D" + y1;
		 	datum = yy + mmdd;
         }
		 if(input.length == 8) {
		 	var yy = input.substr(2, 2);
		 	var mmdd = input.substr(4, 4);

		 	var pre = input.substr(2, 1);
		 	var y1 = input.substr(3, 1);
		 	if(pre == "0") yy = "A" + y1;
		 	if(pre == "1") yy = "B" + y1;
		 	if(pre == "2") yy = "C" + y1;
		 	if(pre == "3") yy = "D" + y1;
		 	datum = yy + mmdd;
         }
		 //alert(datum);
		 return datum;
	 }
	 String.prototype.cdidate = function() {
		 var input = this;
		 if(!input) return "";
		 if(input == undefined) return "";

		 var datum = input;
		 if(input.length == 6) {
		 	var pre = input.substr(0, 1);
		 	var intxt = input.substr(1, 5);
		 	if(pre == "A") pre = "0";
		 	if(pre == "B") pre = "1";
		 	if(pre == "C") pre = "2";
		 	if(pre == "D") pre = "3";
		 	intxt = pre + intxt;
		 	datum = "20" + intxt.substr(0, 2) + "-" + intxt.substr(2, 2) + "-" + intxt.substr(4, 2);
         }
		 else if(input.length == 8) datum = input.substr(0, 4) + "-" + input.substr(4, 2) + "-" + input.substr(6, 2);
		 else if(input.length == 10) {
		 	var pre = input.substr(0, 1);
		 	var intxt = input.substr(1, 9);
		 	if(pre == "A") pre = "0";
		 	if(pre == "B") pre = "1";
		 	if(pre == "C") pre = "2";
		 	if(pre == "D") pre = "3";
		 	intxt = pre + intxt;
		 	datum = "20" + intxt.substr(0, 2) + "-" + intxt.substr(2, 2) + "-" + intxt.substr(4, 2) + " " + intxt.substr(6, 2) + ":" + intxt.substr(8, 2);
         }
		 else if(input.length == 12) {
		 	var pre = input.substr(0, 1);
		 	var intxt = input.substr(1, 11);
		 	if(pre == "A") pre = "0";
		 	if(pre == "B") pre = "1";
		 	if(pre == "C") pre = "2";
		 	if(pre == "D") pre = "3";
		 	intxt = pre + intxt;
		 	datum = "20" + intxt.substr(0, 2) + "-" + intxt.substr(2, 2) + "-" + intxt.substr(4, 2) + " " + intxt.substr(6, 2) + ":" + intxt.substr(8, 2) + "." + intxt.substr(10, 2);
         }
		 //alert(datum);
		 return datum;
	 };
	 String.prototype.cditsdate = function() {
		 var input = this;
		 if(!input) return "";
		 if(input == undefined) return "";
		 if(input.length != 12) return "";

		 var datum = input.substr(0, 8);
		 var tid = input.substr(8, 4);
		 if(datum.length == 8) datum = datum.substr(0, 4) + "-" + datum.substr(4, 2) + "-" + datum.substr(6, 2);
		 if(tid.length == 4) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2);
		 var output = datum + " " + tid;
		 //alert(datum);
		 return output;
	 };
	 String.prototype.cditid = function() {
		 var input = this;
		 if(!input) return "";
		 if(input == undefined) return "";
		 if(input.length > 6) return "";

		 var tid = input.substr(0, 6);
		 if(tid.length == 6) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2) + "." + tid.substr(4, 2);
		 if(tid.length == 4) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2);
		 if(tid.length == 3) tid = tid.substr(0, 1) + "." + tid.substr(1, 2);
		 if(tid.length == 2) tid = "0" + "." + tid.substr(0, 2);
		 if(tid.length == 1) tid = "0.0" + tid;
		 var output = tid;
		 //alert(datum);
		 return output;
	 };
	 Number.prototype.cditid = function() {
		 var input = this.toString();
		 if(!input) return "";
		 if(input == undefined) return "";
		 if(input.length > 6) return "";

		 var tid = input.substr(0, 6);
		 if(tid.length == 6) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2) + "." + tid.substr(4, 2);
		 if(tid.length == 4) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2);
		 if(tid.length == 3) tid = tid.substr(0, 1) + "." + tid.substr(1, 2);
		 if(tid.length == 2) tid = "0" + "." + tid.substr(0, 2);
		 if(tid.length == 1) tid = "0.0" + tid;
		 var output = tid;
		 //alert(datum);
		 return output;
	 };
	 String.prototype.cditid2 = function() {
		 var input = this;
		 if(!input) return "00.00";
		 if(input == undefined) return "00.00";

		 var num = parseInt(input);
		 var tid = num.toString();
		 if(tid == "NaN") return "00.00";
		 //var tid = input.substr(0, 6);
		 if(tid.length == 9) tid = tid.substr(0, 7) + "." + tid.substr(7, 2);
		 if(tid.length == 8) tid = tid.substr(0, 6) + "." + tid.substr(6, 2);
		 if(tid.length == 7) tid = tid.substr(0, 5) + "." + tid.substr(5, 2);
		 if(tid.length == 6) tid = tid.substr(0, 4) + "." + tid.substr(4, 2);
		 if(tid.length == 5) tid = tid.substr(0, 3) + "." + tid.substr(3, 2);
		 if(tid.length == 4) tid = tid.substr(0, 2) + "." + tid.substr(2, 2);
		 if(tid.length == 3) tid = "0" + tid.substr(0, 1) + "." + tid.substr(1, 2);
		 if(tid.length == 2) tid = "00." + tid;
		 if(tid.length == 1) tid = "00.0" + tid;
		 var output = tid;
		 //alert(datum);
		 return output;
	 }
	 Number.prototype.cditid2 = function() {
		 var input = this.toString();
		 if(!input) return "00.00";
		 if(input == undefined) return "00.00";

		 var num = parseInt(input);
		 var tid = num.toString();
		 //var tid = input.substr(0, 6);
		 if(tid.length == 9) tid = tid.substr(0, 7) + "." + tid.substr(7, 2);
		 if(tid.length == 8) tid = tid.substr(0, 6) + "." + tid.substr(6, 2);
		 if(tid.length == 7) tid = tid.substr(0, 5) + "." + tid.substr(5, 2);
		 if(tid.length == 6) tid = tid.substr(0, 4) + "." + tid.substr(4, 2);
		 if(tid.length == 5) tid = tid.substr(0, 3) + "." + tid.substr(3, 2);
		 if(tid.length == 4) tid = tid.substr(0, 2) + "." + tid.substr(2, 2);
		 if(tid.length == 3) tid = "0" + tid.substr(0, 1) + "." + tid.substr(1, 2);
		 if(tid.length == 2) tid = "00." + tid;
		 if(tid.length == 1) tid = "00.0" + tid;
		 var output = tid;
		 //alert(datum);
		 return output;
	 }
	 String.prototype.cdimin2tid = function() {
		 var input = this;
		 if(!input) return "0.00";
		 if(input == undefined) return "0.00";

         var rc = input.indexOf(".");
         if(rc != -1) input = input.replace(".","");
         rc = input.indexOf(",");
         if(rc != -1) input = input.replace(",","");

		  var min = (input * 1);
		  var hh = Math.floor(min / 60);
		  var mm = min % 60;
		  var mms = mm.toString();
		  if(mms.length == 1) mms = "0" + mms;
		  var tid = hh.toString() + "." + mms;
		  if(tid.length == 2) tid = "0" + tid;
		 var output = tid;
		 //alert(datum);
		 return output;
	 }

    String.prototype.cdirumsnr = function() {
  	  	var input = this;
		  var rnr = 0;
		  if(!input) return "";
		  if(input.length == 4) rnr = (parseInt(input)).toFixed(0);
		  if(input.length == 6) rnr = (parseInt(input) / 100).toFixed(0);
		  if(isNaN(rnr)) rnr = "";
		  if(rnr == 0) rnr = "";
		  
	    return rnr;
      }

	 String.prototype.utf8_decode = function () {
		 var str_data = this;
		 var tmp_arr = [],
			 i = 0,
			 ac = 0,
			 c1 = 0,
			 c2 = 0,
			 c3 = 0;

		 str_data += '';

		 while (i < str_data.length) {
			 c1 = str_data.charCodeAt(i);
			 if (c1 < 128) {
				 tmp_arr[ac++] = String.fromCharCode(c1);
				 i++;
			 } else if (c1 > 191 && c1 < 224) {
				 c2 = str_data.charCodeAt(i + 1);
				 tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));
				 i += 2;
			 } else {
				 c2 = str_data.charCodeAt(i + 1);
				 c3 = str_data.charCodeAt(i + 2);
				 tmp_arr[ac++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
				 i += 3;
			 }
		 }

		 return tmp_arr.join('');
	 }
