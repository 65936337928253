import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode"
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sbo from "../sbo";

class Shop_ScanQR extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.html5QrCode = null;

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.qr = "";
        this.state.result = "";
        this.state.bord = "";

        this.state.step = 1;
    }

    componentWillUnmount(){
        if(this.html5QrCode != null) {
            try {
                this.html5QrCode.stop()
                    .then((ignore) => {
                        // QR Code scanning is stopped.
                    }).catch((err) => {
                    // Stop failed, handle it.
                });
            }
            catch(e) {
                console.log("Scanner Err: " + JSON.stringify(e));
            }
        }
    }
    componentDidMount(){
        //this.scan_qr();

        var self = this;
        setTimeout(function() {
            self.qr_init();
        }, 500);

    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    qr_css() {
        var css = {};
        css.width = "100px";
        css.height = "100px";
        css.border = "1px solid #ff9900";
        css.display = "flex";
        css.justifyContent = "center";
        return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    pickup_qr() {
        var self = this;

        var prm = {};
        prm.req = "vcm.ps.qr";
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                //var qr = 'data:image/png;base64,'+ ret.qr;
                self.setState({qr: ret.qr});
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    scan_qr() {
        var self = this;
        navigator.mediaDevices.getUserMedia({video: true});

        if (!navigator.mediaDevices) {
            throw new Error("mediaDevices API unavailable.");
        }

        navigator.mediaDevices.enumerateDevices()
        .then(function(devices){
            var cameras = devices.filter(d => (d.kind === "videoinput"));
            const video = document.getElementById("video");
            var stream = cameras[0];
            alert(JSON.stringify(cameras));
            video.srcObject = stream;
            video.play();
        });

    };

    qr_start(cameraId) {
        var self = this;
        const html5QrCode = new Html5Qrcode(/* element id */ "qrreader");
        this.html5QrCode = html5QrCode;
        const aspectRatio = 1.7777777778;

        html5QrCode.start(
            //cameraId,
            { facingMode: "environment" },
            {
                videoConstraints : { aspectRatio: aspectRatio, facingMode: "environment", advanced: [{focusMode: "continuous"}]  },
                fps: 20,    // Optional, frame per seconds for qr code scanning
                qrbox: { width: 200, height: 200 }  // Optional, if you want bounded box UI
                //formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ]
            },
            (decodedText, decodedResult) => {
                // do something when code is read
                //alert("SCAN: " + JSON.stringify(decodedText));
                //alert("RES: " + JSON.stringify(decodedResult));
                //self.setState({result: decodedText});
                self.qr_result(decodedText);
            },
            (errorMessage) => {
                // parse error, ignore it.
                //alert("ERR: " + JSON.stringify(errorMessage));
            })
            .catch((err) => {
                // Start failed, handle it.
                //alert("FAIL: " + JSON.stringify(err));
            });
    };

    qr_stop(html5QrCode) {
        var self = this;
        html5QrCode.stop().then((ignore) => {
            // QR Code scanning is stopped.
        }).catch((err) => {
            // Stop failed, handle it.
        });
    };

    qr_camera() {
        var self = this;
        if(true) {
            self.qr_start("");
            return;
        }

        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            //alert("DEVS: " + JSON.stringify(devices));
            if (devices && devices.length) {
                var cameraId = devices[0].id;
                self.qr_start(cameraId);
            }
        }).catch(err => {
            // handle err
        });
    }

    async qr_init() {
        this.qr_camera();
        /*
        try {
            // User is prompted to grant both camera and PTZ access in a single call.
            // If camera doesn't support PTZ, it falls back to a regular camera prompt.
            const stream = await navigator.mediaDevices.getUserMedia({
                // Website asks to control camera PTZ as well without altering the
                // current pan, tilt, and zoom settings.
                video: { pan: 0, facingMode: { exact: "environment" } }
                //video: { pan: false, tilt: false, zoom: true }
            });

            // Show camera video stream to user.
            document.querySelector("video").srcObject = stream;
        } catch (error) {
            // User denies prompt or matching media is not available.
            console.log(error);
        }
        <video id="video" style={{ width: "150px", height: "150px" }} autoPlay />
         */
    }
    qr_result(sText) {
        var self = this;
        alert(sText);
        var bord = "";
        var txt = sText.split("#");
        if(txt[1]) bord = txt[1];
        self.setState({bord: bord});
    }

    tb_open() {
        var self = this;
        if(self.props.wnds && self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.bord = this.state.bord;
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });
    };

    render() {

    return (
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }

        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>SKANNA BORD</div>
            </div>
            <div className="mx_content flex">
                <div className="flex-center">
                    <div className="txt_22">SKANNA KODEN PÅ ERT BORD</div>
                </div>
                <div className="divider_blue"></div>

                <div className="flex-col flex-cc flex" style={{ overflow: "hidden" }}>
                    <div className="flex-row">
                        <div id="qrreader" style={{ borderRadius: "20px" }}></div>
                    </div>
                    <div className="flex-row hide">
                        { this.state.qr ?
                            <img src={ this.state.qr } alt="" width="300px" height="300px" />
                            : null}
                    </div>
                </div>

                <div className="flex-center">
                    <div className="txt_16">Bord</div>
                </div>
                <div className="flex-center">
                    <div className="txt_22">{this.state.bord}</div>
                </div>

                <div className="txt_22 web-btn hide" onClick={() => this.qr_init() }>STARTA QR SCANNER</div>
                <div className="txt_14 hide" onClick={() => this.tb_open() }>LOGGA IN MANUELLT</div>
                <div className="divider_blue"></div>
                <div className="txt_18 web-btn-01 hide" onClick={() => this.tb_open() }>ANGE BORDSNR</div>
                <div className="txt_18 web-btn-01" onClick={() => this.tb_open() }>ÖPPNA BORD</div>
                <div className="txt_18 web-btn-01 hide" onClick={() => this.qr_init() }>STARTA</div>
                <div className="txt_18 web-btn-01" onClick={() => this.wnd_close() }>STÄNG</div>

            </div>
        </div>
        </div>
    );
    }
}
export default withRouter(observer(Shop_ScanQR));