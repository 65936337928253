import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import sbo from "../sbo";
import * as srs from "../../lib/srs";
import {withRouter} from "../../lib/react/withRouter";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Shop_Order extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.orderid = "";
        this.state.bord = "";
        this.state.order = {};
        this.state.order.order00 = {};
        this.state.sbo = {};
        this.state.sbo.cart = {};
        this.state.sbo.cart.rows = [];

        this.state.msgtext = "VÄNTAR";
        this.state.step = 1;
        this.state.paid = "0";
        this.state.retries = 0;
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        var self = this;
        var hash = window.location.hash;
        //var params = new URLSearchParams(window.location.search);
        var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
        if(params) {
            this.setState({ orderid: params.orderid, bord: params.bord }, function() {
                setTimeout(function() {
                    self.order_check();
                }, 1000);
            });
        }
    }

    queryStringToJSON(queryString) {
        if(queryString.indexOf('?') > -1){
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        if(this.props.wnds.cb) this.props.wnds.cb(this.props.ctx);
        else this.props.ctx.setState({ wnds: {} });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    order_check() {
        var self = this;
        self.setState({ msgtext: "HÄMTAR ORDER INFORMATION" });

        var prm = {};
        prm.req = "vcm.ecom.ecom_morder";
        prm.sid = sbo.cfg.sid;
        prm.orderid = this.state.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var order = ret.order;
                    var sbo = ret.sbo;
                    var paid = order.order00.paid;
                    //alert(JSON.stringify(order.order00.paid));
                    var msgtext = "VÄNTAR PÅ BETALNING";
                    if(paid == "1") msgtext = "ORDER BETALD";
                    self.setState({ paid: paid, order: order, sbo: sbo, msgtext: msgtext });
                }
                else {
                    var retries = this.state.retries;
                    if(retries < 10) {
                        retries = retries + 1;
                        self.setState({ msgtext: "ORDER PROCESSAS" , retries: retries });
                        setTimeout(function() {
                            self.order_check();
                        }, 1000);   
                    }
                    else {
                        self.setState({ msgtext: "ORDER EJ BEKRÄFTAD" , retries: retries });
                    }
                    //throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };
    order_kvitto() {
        var self = this;
        self.setState({ msgtext: "HÄMTAR ORDER INFORMATION" });

        var prm = {};
        prm.req = "vcm.ecom.mcom_sms";
        prm.sid = sbo.cfg.sid;
        prm.orderid = this.state.orderid;
        prm.tele = "";

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var order = ret.order;
                    var paid = order.order00.paid;
                    //alert(JSON.stringify(order.order00));
                    var msgtext = "VÄNTAR PÅ BETALNING";
                    if(paid == "1") msgtext = "ORDER BETALD";
                    self.setState({ paid: paid, order: order, msgtext: msgtext });
                }
                else {
                    self.setState({ msgtext: "FINNER EJ ORDER" });
                    //throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };
    order_close() {
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        sbo.cfg.bord = "";

        var url = "/";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
        //if(sbo.cfg.vurl) url = "/" + sbo.cfg.vurl;
        url = url + "/bye";
        this.props.navigate(url);
    };
    show_notanr() {
        var notanr = "11";
        var order = this.state.order;
        if(order && order.order00 && order.order00.notanr) {
            notanr = order.order00.notanr;
        }
        return notanr;
    };
    show_paid() {
        var paid = "0";
        var order = this.state.order;
        if(order && order.order00 && order.order00.paid) {
            paid = order.order00.paid;
        }
        return paid;
    };

    render() {

    return (
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>KÖP ORDER</div>
            </div>
            <div className="mx_content flex">
            <div className="flex-center hide">
                    <div className="txt_22">ORDERID: { utils.toNum( this.state.orderid ) }</div>
                </div>
                <div className="flex-center">
                    <div className="txt_22">BORD: { utils.toNum( this.state.bord ) }</div>
                </div>
                <div className="divider_blue"></div>
                <div className="flex-center">
                    <div className="txt_16">{ this.state.msgtext }</div>
                </div>

                <div className="flex"></div>

                { this.state.paid == "1" ?
                <div className="px-5">
                    <div className="web-kvitto flex">
                        <div className="txt_16">KVITTO</div>
                        <div className="txt_12">Datum: { utils.toDate(this.state.order.order00.datum) }</div>
                        <div className="txt_12">Bord: { this.state.order.order00.bord }</div>
                        <div className="txt_12">Notanr: { utils.toNum(this.state.order.order00.notanr) }</div>
                        <div className="divider_grey"></div>
                        { this.state.sbo.cart.rows.map((row, key) =>
                            <div key={key} className="flex-row">
                                <div className="txt_12">{ utils.toLang(row.text) }</div>
                                <div className="flex"></div>
                                <div className="txt_12">{ utils.toPris(row.belopp) }</div>
                            </div>
                        )}
                        <div className="divider_grey"></div>
                        <div className="txt_12">Totalt: { utils.toPris(this.state.order.order00.belopp) }</div>
                        <div className="txt_12">Betalt: { utils.toLang(this.state.order.order00.betkod) }</div>
                    </div>
                </div>
                :
                    <div className="px-5">
                        <div className="flex-center">
                            <div className="txt_16">VÄNTAR PÅ BETALNING</div>
                        </div>
                    </div>
                }
                <div className="flex"></div>

                <div className="divider_blue"></div>
                <div className="txt_22 web-btn hide" onClick={() => this.order_kvitto() }>KVITTO</div>
                <div className="txt_22 web-btn" onClick={() => this.order_close() }>STÄNG</div>

            </div>
        </div>
        </div>
    );
    }
}
export default withRouter(observer(Shop_Order));