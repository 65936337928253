import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as net from "../../../lib/net";
import sbo from "../../sbo";
import * as srs from "../../../lib/srs";
import {withRouter} from "../../../lib/react/withRouter";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Shop_Swish_SS extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.qr = "";
        this.state.image_content = "";
        this.state.orderid = "";
        this.state.msgtext = "";

        this.state.step = 1;
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        if(sbo.cfg.swish && (sbo.cfg.swish == "1")) {
            //this.swish_open();
        }
        this.swish_qr();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        if(this.props.wnds.cb) this.props.wnds.cb(this.props.ctx);
        else this.props.ctx.setState({ wnds: {} });
    };
    wnd_cancel = () => {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    };

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };

    swish_tst() {
        var self = this;
        //var url = "https://shop.svepos.se/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
        var url = "/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
        //this.props.navigate(url);
        window.location = url;
    };
    swish_fake() {
        var self = this;
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.orderid = sbo.cart.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.mcom_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
        })
        .catch(function(e) {
        })
    };

    swish_qr() {
        var self = this;
        var prm = {};
        prm.req = "vcm.swish.swish_qr";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        //prm.notanr = "123457E";
        prm.notanr = sbo.cart.orderid;
        prm.belopp = sbo.cart.belopp;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    //var token = ret.token;
                    //var cburl = "merchant%253A%252F%252F";
                    //var image_content = "data:image/svg+xml;base64," + ret.img;
                    var image_content = "data:image/png;base64," + ret.img;
                    self.setState({ image_content: image_content });

                    setTimeout(function() {
                        self.order_check();
                    }, 1000);

                }
                else {
                    self.setState({ info: "", error: "Kan ej skapa Swish.", errormsg: "Vänligen försök igen" });
                    throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };
    order_check() {
        var self = this;
        self.setState({ msgtext: "HÄMTAR ORDER INFORMATION" });

        var prm = {};
        prm.req = "vcm.ecom.ecom_morder";
        prm.sid = sbo.cfg.sid;
        prm.orderid = sbo.cart.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var order = ret.order;
                    var sbo = ret.sbo;
                    var paid = order.order00.paid;
                    //alert(JSON.stringify(order.order00));
                    if(paid == "1") {
                        //alert("order betald");
                        var rsp = {};
                        rsp.ok = "000";
                        if(self.props.wnds.cb) self.props.wnds.cb(self.props.ctx, rsp);
                        else self.props.ctx.setState({ wnds: {} });
                        return;
                    }
                    self.setState({ paid: paid, order: order, sbo: sbo, msgtext: "ORDER HÄMTAD" });
                    setTimeout(function() {
                        self.order_check();
                    }, 1000);
                }
                else {
                    self.setState({ msgtext: "FINNER EJ ORDER" });
                    //throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };
    swish_open() {
        var self = this;
        var prm = {};
        prm.req = "vcm.swish.mcom";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        //prm.notanr = "123457E";
        prm.notanr = sbo.cart.orderid;
        prm.topay = sbo.cart.belopp;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                var token = ret.token;
                //var cburl = "merchant%253A%252F%252F";
                var cburl = "https://shop.svepos.se/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                var url = "swish://paymentrequest?token=" + token + "&callbackurl=" + cburl;
                //alert(JSON.stringify(url));
                window.location = url;
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa Swish.", errormsg: "Vänligen försök igen" });
                throw "999 Error";
            }
        })
        .catch(function(e) {
        })
    };

    render() {

    return (
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>SWISH</div>
            </div>
            <div className="mx_content flex">

                <div className="flex-center">
                    <div className="txt_22">SKANNA QR KODEN NEDAN</div>
                </div>
                <div className="divider_blue"></div>
                <div className="flex"></div>
                <div className="flex flex-cc">
                    <object data={ this.state.image_content } type="image/png" style={{width: "50%", border: "1px dotted #ccc"}}></object>
                </div>

                <div className="flex"></div>
                <div className="flex-center">
                    <div className="txt_16">{ this.state.msgtext }</div>
                </div>

                <div className="divider_blue"></div>
                { sbo.cfg.swish == "0" ?
                    <div className="">
                    <div className="txt_22 web-btn" onClick={() => this.swish_fake() }>SWISH SVAR</div>
                    <div className="txt_22 web-btn" onClick={() => this.swish_tst() }>KONTROLLERA</div>
                    </div>
                : null }
                <div className="txt_22 web-btn" onClick={() => this.wnd_cancel() }>AVBRYT</div>

            </div>
        </div>
        </div>
    );
    }
}
export default withRouter(observer(Shop_Swish_SS));