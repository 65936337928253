import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import { QrReader } from 'react-qr-reader'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../../lib/srs";
import * as utils from "../../../assets/utils";
import {withRouter} from "../../../lib/react/withRouter";

import sbo from '../../sbo.js'

class Flow_NoAuth extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.wnds = {};

      this.state.info = "";
      this.state.error = "";
      this.state.errormsg = "";

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";
      this.state.msgtext = "";

      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }
      if(!sbo.cart.pro) {
          sbo.cart.pro = {};
      }

      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
      }
      //if(!sbo.cdo) sbo.cdo = {};
      this.state.sbo = {};
      if(sbo.cdo) this.state.sbo.cdo = sbo.cdo;
      if(sbo.cart) this.state.sbo.cart = sbo.cart;
      if(false) {
          sbo.cdo = {};
          sbo.cart = {};
          sbo.cart.belopp = "0";
          sbo.cart.rows = [];
          sbo.cart.pro = {};
          sessionStorage.setItem("sbo", JSON.stringify(sbo));
      }

      
      var qs = window.location.search;
      qs = qs.replace("?", "");
      var qa = qs.split("&");
      this.state.qso = {};
      for(var item of qa) {
          var va = item.split("=");
          this.state.qso[va[0]] = va[1];
      }

      if(false) {
        var okey = this.state.qso.okey;
        //okey = okey.replace(/\s/g, '');
        var orderkey = atob(okey);
        var oa = orderkey.split(";");
        this.state.okey = okey;
        this.state.sid = oa[0];
        this.state.orderid = oa[1];
    }

      if(this.state.qso.sid) this.state.sid = this.state.qso.sid;
      if(this.state.qso.orderid) this.state.orderid = this.state.qso.orderid;
      

      this.state.order = {};
      this.state.order.order00 = {};
      this.state.order.order01 = {};
      this.state.order.order02 = {};
      this.state.order.order03 = {};

      //alert(JSON.stringify(this.state.sid));
      //alert(JSON.stringify(this.state.orderid));
      this.state.info = "VÄNTAR PÅ BETALNING";
      this.state.poll = 0;
      this.state.retries = 0;
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        sbo.cfg.nocart = 1;
        //this.ecom_result();
        //this.order_get();

        //this.order_fkn();
        //var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
    }

    order_fkn() {
        var self = this;
        var hash = window.location.hash;
        //var params = new URLSearchParams(window.location.search);
        var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
        if(params) {
            this.setState({ orderid: params.orderid, bord: params.bord, msgtext: "HÄMTAR ORDER INFORMATION" }, function() {
                setTimeout(function() {
                    self.order_check();
                }, 1000);
            });
        }
    }
    queryStringToJSON(queryString) {
        if(queryString.indexOf('?') > -1){
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }
    order_check() {
        var self = this;
        //self.setState({ msgtext: "HÄMTAR ORDER INFORMATION" });

        var prm = {};
        prm.req = "vcm.ecom.ecom_morder";
        prm.sid = sbo.cfg.sid;
        prm.orderid = this.state.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var order = ret.order;
                    var sbo = ret.sbo;
                    var paid = order.order00.paid;
                    //alert(JSON.stringify(order.order00.paid));
                    var msgtext = "VÄNTAR PÅ BETALNING";
                    if(paid == "1") {
                        msgtext = "ORDER BETALD";
                        self.setState({ paid: paid, order: order, sbo: sbo, msgtext: "", info: "" });
                    }
                    else {
                        //self.setState({ paid: paid, order: order, sbo: sbo, msgtext: msgtext });
                        var retries = self.state.retries;
                        if(retries < 10) {
                            retries = retries + 1;
                            self.setState({ msgtext: "ORDER PROCESSAS" , retries: retries });
                            setTimeout(function() {
                                self.order_check();
                            }, 1000);   
                        }
                        else {
                            self.setState({ msgtext: "ORDER EJ BEKRÄFTAD" , retries: retries });
                        }
                    }
                }
                else {
                    var retries = this.state.retries;
                    if(retries < 10) {
                        retries = retries + 1;
                        self.setState({ msgtext: "ORDER PROCESSAS" , retries: retries });
                        setTimeout(function() {
                            self.order_check();
                        }, 1000);   
                    }
                    else {
                        self.setState({ msgtext: "ORDER EJ BEKRÄFTAD" , retries: retries });
                    }
                    //throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };

    order_get() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sid;
        prm.req = "vcm.ecom.ecom_order";
        prm.orderid = this.state.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.setState({ order: ret.order, sbo: ret.sbo });
                return;
            }
            else {
                //self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            }

        })
   }

    ecom_result() {
    var self = this;

        //var sid = this.state.sbo.cdo.unit.sid;
        //var orderid = this.state.sbo.cart.orderid;
        var okey = this.state.okey;
        var sid = this.state.sid;
        var orderid = this.state.orderid;
        //alert(JSON.stringify(this.state.qso));

        var txt = "ORDER KONTROLLERAS...";
        if(orderid && orderid.length) txt = "ORDER: " + orderid.ztrim() + " KONTROLLERAS...";

        self.setState({ info: txt });
        var prm = {};
        prm.sid = sid;
        prm.req = "vcm.ecom.ecom_result";
        prm.orderid = orderid;
        prm.okey = okey;

        //alert(JSON.stringify(prm));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok != "000") {
                self.setState({ error: "Order ej godkänd, Kontrollera och försök igen!" });
                return;
            }

            //alert(JSON.stringify(ret.order));
            var order = ret.order;
            var xbo = ret.sbo;
            //alert(JSON.stringify(xbo));
            if(order.order00.paid == "1") {
                self.setState({ info: "", order: order, sbo: xbo });

                var cartvalue = (sbo.cart.belopp * 1) / 100;
                var carttax = (sbo.cart.tax * 1) / 100;
                var cartship = (sbo.cart.levbelopp * 1) / 100;


                if(true) {
                    sbo.cdo = {};
                    sbo.cart = {};
                    sbo.cart.belopp = "0";
                    sbo.cart.rows = [];
                    sbo.cart.pro = {};
                    sessionStorage.setItem("sbo", JSON.stringify(sbo));
                }
            }
            else self.setState({ error: "Order obetald, Kontrollera och försök igen!", order: order });
            //alert(JSON.stringify(ret));
        })

    }

    bg_style() {
        var css = {};
        if((this.state.info.length > 0) || (this.state.error.length > 0)) {
            css.display = "none";
        }
        if((this.state.paid != 1)) {
            css.display = "none";
        }
        css.background = "#fee260";
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }
    bg_info_style() {
        var css = {};
        css.display = "none";
        if(this.state.info.length > 0) {
            css.display = "";
        }
        if(this.state.msgtext.length > 0) {
            css.display = "";
        }
        if(this.state.error.length > 0) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        return css;
    }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }

    cart_mail() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sid;
        prm.req = "vcm.ecom.confirm_mail";
        prm.sbo = this.state.sbo;

        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
            }
            else {
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    cart_sms() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = this.state.sid;
        prm.req = "vcm.ecom.confirm_sms";
        prm.sbo = this.state.sbo;

        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                if(ret.ok == "000") {
                }
                else {
                }
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    back() {
        if(false) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        sessionStorage.removeItem("sbo");
        var url = "/flow";
        this.props.navigate(url);
    }
    wnd_close() {
        if(false) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        //sessionStorage.removeItem("sbo");
        //var url = "/flow";
        //this.props.navigate(url);
    }
    cart_close() {
        sessionStorage.removeItem("sbo");
        var url = "/flow";
        this.props.navigate(url);
    }
    order_kvitto() {
        var self = this;
        self.setState({ msgtext: "HÄMTAR ORDER INFORMATION" });

        var prm = {};
        prm.req = "vcm.ecom.mcom_sms";
        prm.sid = sbo.cfg.sid;
        prm.orderid = this.state.orderid;
        prm.tele = "";

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
            .then(function(ret) {
                //alert(JSON.stringify(ret));
                if(ret.ok == "000") {
                    var order = ret.order;
                    var paid = order.order00.paid;
                    //alert(JSON.stringify(order.order00));
                    var msgtext = "VÄNTAR PÅ BETALNING";
                    if(paid == "1") msgtext = "ORDER BETALD";
                    self.setState({ paid: paid, order: order, msgtext: msgtext });
                }
                else {
                    self.setState({ msgtext: "FINNER EJ ORDER" });
                    //throw "999 Error";
                }
            })
            .catch(function(e) {
            })
    };
    order_close() {
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        sbo.cfg.bord = "";

        var url = "/";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
        //if(sbo.cfg.vurl) url = "/" + sbo.cfg.vurl;
        url = url + "/bye";
        this.props.navigate(url);
    };
    handleError(err) {
        var self = this;
        //alert("ERR: " + JSON.stringify(err));
        //console.error(err)
    }
    handleScan(data) {
        var self = this;
        if (data) {
            //alert("SCAN: " + JSON.stringify(data));
            //this.setState({result: data})
        }
    }
    handleResult(data) {
        var self = this;
        if (data) {
            //alert("RESULT: " + JSON.stringify(data));
            //this.setState({result: data});
        }
    }

  render() {

    return (
    <div className="web-app flex-col">

    <div className="divider_orange my-1"></div>

    <div className="flex"></div>

        <div className="">
        <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    ENHET EJ AKTIV
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    VÄNLIGEN
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    FÖRSÖK IGEN
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    TACK!
                </div>
            </div>
        </div>¨

        
        <div className="flex"></div>

        <div>{this.state.result}</div>
       <div className="txt_18 web-btn-01 hide" onClick={() => this.wnd_close() }>STÄNG</div>
    </div>
  );
}
}

export default withRouter(observer(Flow_NoAuth));
