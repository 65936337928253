import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";
import sbo from "../../sbo";

class Flow_About extends React.Component {
  //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

    if(!sbo.cfg.ftg) sbo.cfg.ftg = {};
    if(!sbo.cfg.ftg.about) sbo.cfg.ftg.about = "Välkommen in till oss!";
  }
  
  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    //this.setAbout();
  }

  setAbout() {
    sbo.cfg.about = "";
    sbo.cfg.about += "Att äta en god måltid tillsammans med nära och kära kan vara dagens höjdpunkt. Men när det är ont om";
    sbo.cfg.about += "tid, inspirationen tryter och alla är oense om vad man ska äta kan det vara dagens jobbigaste stund";
    sbo.cfg.about += "Att stå i köket och och försöka laga hela familjens favoriträtter är nästan omöjligt. Foodle går";
    sbo.cfg.about += "helt enkelt ut på att lösa dessa knutar. Vi fokuserar enbart på mat för leverans och således på att";
    sbo.cfg.about += "göra din ”home delivery”-upplevelse så bra som möjligt. Genom att erbjuda ett brett utbud av olika";
    sbo.cfg.about += "kök vars rätter levereras med en och samma körning, kan alla runt matbordet bestämma vad var och en";
    sbo.cfg.about += "vill äta. Eftersom all mat från Foodle både görs och levereras av våra egna anställda har vi full";
    sbo.cfg.about += "kontroll på hela flödet och, viktigast av allt, ingen annan att skylla på. Alltså, flera egna kök -";
    sbo.cfg.about += "en leverans - alla får bestämma.";
    sbo.cfg.about += "<br /> Bon appétit!";
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    //css.background = "#ff92d4";
    //css.background = "#2e9b88";
    //css.color = "#f43b24";
    //css.color = "#fff";
    css.background = "#fff";
    css.color = "#000";
    css.padding = "0px auto";
    css.height = "100%";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "550px";
    css.padding = "10px 0px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
        <div className="page" style={ this.pg_style() }>
          <div className="flex-row flex-center py-5">
            <div style={ this.ctx_style() }>

            <div className="text-center font-bold font-colby f80 py-3">
                  OM { sbo.cfg.text }
            </div>
            <div className="mx-auto">
                  <div className="text-tomato text-center f16">
                  { sbo.cfg.ftg.about }
                  </div>
              </div>
          </div>
          </div>
        </div>
        <span className="hide">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#008" fillOpacity="1" viewBox=".95 .45 8.1 8.1">
            <circle r="99"/>
              <path d="M1,1h7M2,2h7M1,3h7M2,4h7M1,5h7M2,6h7M1,7h7M2,8h7" stroke="#fff" strokeDasharray="1"/>
          </svg>
        </span>
            <span className="hide">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
                <path fill="#008" fillOpacity="1" d="M0,128L34.3,112C68.6,96,137,64,206,85.3C274.3,107,343,181,411,208C480,235,549,213,617,181.3C685.7,149,754,107,823,128C891.4,149,960,235,1029,229.3C1097.1,224,1166,128,1234,90.7C1302.9,53,1371,75,1406,85.3L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
              </svg>
            </span>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Flow_About);
