import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import {withRouter} from "../../lib/react/withRouter";

import sbo from "../sbo";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Shop_Cart extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
        this.setState({info: "UUU" });
    }
    componentDidMount(){
    }

   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    cart_belopp() {
        var belopp = 0;
        if(sbo.cart.belopp) belopp = sbo.cart.belopp.cdipris();
        return belopp;
    };
    cart_rows() {
        var rows = [];
        if(sbo.cart.rows) rows = sbo.cart.rows;
        return rows;
    };
    cart_row_del(row) {
        if(sbo.cart.rows) {
            //sbo.cart.rows = sbo.cart.rows.filter(function(item) {
            //    return item !== row
            //})
            sbo.cart.rows = sbo.cart.rows.filter(item => item !== row);
            var belopp = 0;
            for(var o of sbo.cart.rows) {
                belopp = belopp + ( o.price * 1 );
            }
            sbo.cart.belopp = belopp;
        }
    };

    back() {
        this.props.navigate(-1);
    };

    render() {

        return (
<div className="web-app">

    <div className="web-body flex-col">

        <div className="flex-center">
            <div className="txt_22">HITTILS BESTÄLLT</div>
        </div>
        <div className="divider_blue"></div>
        <div className="flex px-5">
        { this.cart_rows().map((row, key) =>
            <div key={key} className="flex-row" onClick={() => this.cart_row_del( row ) }>
                <div className="txt_16">{ row.text.cdi2txt() }</div>
                <div className="flex"></div>
                <div className="txt_16">{ row.pbo.plu00.p0pris.cdipris() }</div>
            </div>
        )}
        <div className="divider_green"></div>
        <div className="flex-row">
            <div className="txt_16">Summa</div>
            <div className="flex"></div>
            <div className="txt_16">{ this.cart_belopp() }</div>
        </div>
        </div>
        <div className="divider_blue"></div>
        <div className="txt_22 web-btn" onClick={() => this.fknGo("/shop/checkout") }>BETALA</div>
        <div className="txt_22 web-btn" onClick={() => this.back() }>STÄNG</div>

    </div>
</div>
        );
    }
}
export default withRouter(observer(Shop_Cart));