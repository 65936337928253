import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as net from "../../lib/net";
import * as utils from "../../assets/utils";
import sbo from "../sbo";
import * as srs from "../../lib/srs";
import {withRouter} from "../../lib/react/withRouter";


class Order_Bye extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.orderid = "";
        this.state.order = {};
        this.state.order.order00 = {};
        this.state.sbo = {};
        this.state.sbo.cart = {};
        this.state.sbo.cart.rows = [];

        this.state.msgtext = "VÄLKOMMEN ÅTER!";
        this.state.step = 1;
        this.state.paid = "0";
    }

    componentWillUnmount(){
    }
    componentDidMount(){
    }

    queryStringToJSON(queryString) {
        if(queryString.indexOf('?') > -1){
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        if(this.props.wnds.cb) this.props.wnds.cb(this.props.ctx);
        else this.props.ctx.setState({ wnds: {} });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    shop_close() {
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        sbo.cfg.bord = "";

        //var url = "https://olearys.se/stockholm-tolv-event-center/";
        var url = sbo.cfg.closelink;
        //this.props.navigate(url);
        window.location = url;
    };
    logo(){
        var logo = null;
        //alert(sbo.cfg.logo);
        //var url = "../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        if(sbo.cfg.logo != undefined) logo = require("../../img/" + sbo.cfg.logo);
        return logo;
    }

    render() {

    return (
            <div className="web-app">
            <div className="web-body flex-col">

            <div className="flex-center py-2">
                <img className="svk-logo" src={ this.logo() } />
            </div>
            <div className="divider_grey"></div>
            <div className="flex-center">
                <div className="txt_22">TACK FÖR DIN BESTÄLLNING</div>
            </div>
            <div className="divider_grey"></div>

                 <div className="flex"></div>

                <div className="flex-center">
                    <div className="txt_22">{ this.state.msgtext }</div>
                </div>

                <div className="flex"></div>

                <div className="divider_blue"></div>
                <div className="txt_22 web-btn" onClick={() => this.shop_close() }>STÄNG</div>

            </div>
        </div>
    );
    }
}
export default withRouter(observer(Order_Bye));