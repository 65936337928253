import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as net from "../../../lib/net";
import sbo from "../../sbo";
import * as utils from "../../../assets/utils";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_Prd extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.pro = {};
        this.state.prdid = {};
        if(props.wnds.prdid) {
            this.state.prdid = props.wnds.prdid;
        }
        this.state.step = 1;
        this.state.mna = [];
    }

    componentWillUnmount(){
    }
    componentDidMount(){
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        //img = "http://filpub.svepos.se/app/ct24/" + url;
        img = "http://filpub.svepos.se/app/";
        return img;
    }
    cart_add() {
        //alert(JSON.stringify(this.state.dish));
        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
        }
        sbo.cart.rows.push( this.state.dish );
        //var pris = this.state.dish.price.replaceAll("-", "");
        var pris = this.state.dish.pbo.plu00.p0pris.replaceAll("-", "");
        pris = (pris * 1);
        sbo.cart.belopp = sbo.cart.belopp + pris;
        this.props.ctx.setState({ wnds: {} });
    };

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>{ utils.toLang( this.state.pro.text ) }</div>
            </div>
        <div className="mx_content flex">

            <div className="flex-center hide">
                <img width="250px" height="250px" src={ this.img_url(this.state.pro.img) } style={{ "objectFit": "cover" }}/>
            </div>
            <div className="flex-col flex px-5">
                <div className="flex-row">
                <div className="txt_18">{ utils.toLang( this.state.pro.text ) }</div>
                <div className="flex"></div>
                <div className="txt_18">{ utils.toPris( this.state.pro.pris ) }</div>
                </div>
                <div className="divider_blue"></div>
                <div className="txt_18">Beskrivning</div>
                <div className="txt_18">{ utils.toLang( this.state.pro.text ) }</div>
                <div className="flex"></div>
                { this.props.tval ?
                    <div className="flex-col">
                        <div className="txt_18">Tillval</div>
                        <div className="flex"></div>
                    </div>
                : null }
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="txt_18 web-btn-01 flex" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="txt_18 web-btn-02 flex" onClick={() => this.cart_add() }>KÖP</div>
            </div>

        </div>
    </div>
    </div>
        );
    }
}
export default observer(Mnu_Prd);