import React, { useState, useEffect, useContext } from 'react';

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sbo from "../sbo";
import * as srs from "../../lib/srs";

import Mnu_Dish from "./dish";
import Mnu_Grp from "./grp";

class Mnu_M1 extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.step = 1;
        this.state.gra = [];
        this.state.mna = [];
    }

    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    componentDidMount(){
        //this.gra_init();
        //this.dsi_mp();
        this.dsi_mnus();
    }

    gra_init() {
        var gra = [];
        gra.push({ gid: "001", text: "PASTA", desc: "Pastarätter mm", img: "Tintin_72_dpi_Web_011.jpg" });
        gra.push({ gid: "002", text: "SOPPOR", desc: "Flytande föda mm", img: "Tintin_72_dpi_Web_011.jpg" });
        gra.push({ gid: "003", text: "NUDLAR", desc: "Asiatiska mm", img: "Tintin_72_dpi_Web_011.jpg" });
        gra.push({ gid: "004", text: "HUSMAN", desc: "Svensk husmanskost", img: "Tintin_72_dpi_Web_011.jpg" });
        this.setState({gra: gra});
    }

    mna_init() {
        var mna = [];
        mna.push({ text: "Rätt 1", price: "2700", img: "Tintin_72_dpi_Web_011.jpg" });
        mna.push({ text: "Rätt 2", price: "2900", img: "Tintin_72_dpi_Web_010.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 12", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 16", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 34", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 55", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "5700", img: "Tintin_72_dpi_Web_015.jpg" });
        mna.push({ text: "Rätt 3", price: "3500", img: "Tintin_72_dpi_Web_015.jpg" });
        this.setState({mna: mna});
    }

    header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 185;
        css.overflow = "auto";
        return css;
    }
    img_url(url){
        var img = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //img = require('../../img/logo-sk.png');
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    grp_show(row) {
        var self = this;
        var wnds = {};
        wnds.grp = true;
        wnds.grprow = row;
        this.setState({wnds: wnds});
    }
    dish_show(row) {
        var self = this;
        var wnds = {};
        wnds.dish = true;
        wnds.dishrow = row;
        this.setState({wnds: wnds});
    }
    dsi_mnus() {
        var self = this;

        var channel = "";
        if(sbo.cfg && sbo.cfg.chan && sbo.cfg.chan[sbo.cfg.flow]) channel = sbo.cfg.chan[sbo.cfg.flow].channel;

        var prm = {};
        prm.req = "dsi.dsi_mnu.mnu_list";
        prm.sid = sbo.cfg.sid;
        prm.channel = channel;
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ gra: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    dsi_mp() {
        var self = this;

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_web";
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ mna: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    plu_pris(row) {
        var pris = "";
        if(row.pbo && row.pbo.plu00) {
            var pris = row.pbo.plu00.p0pris;
            pris = utils.toPris( pris );
        }
        return pris;
    }
    plu_anm(row) {
        var anm = "";
        if(row.pbo) {
            anm = row.pbo.anm;
            anm = utils.toLang( anm );
        }
        return anm;
    }
    clear_mnu() {
        var self = this;
        self.setState({ mna: [] });
    }

    render() {

        return (
<div className="web-app">

    <div className="web-body flex-col">

        <div className="flex-row py-2">
            <div className="txt_18 text-center flex" onClick={() => this.dsi_mnus() }>MENY GRUPPER</div>
        </div>
        <div className="flex-row py-2 hide">
            <div className="mnu-btn txt_18 text-center flex" onClick={() => this.dsi_mp() }>FRUKOST</div>
            <div className="mnu-btn txt_18 text-center flex" onClick={() => this.dsi_mp() }>PASTA</div>
            <div className="mnu-btn txt_18 text-center flex" onClick={() => this.clear_mnu() }>DIVERSE</div>
        </div>
        <div className="divider_green"></div>
        <div style={ this.tb_style() }>
        <div className="mnu-grid">
        { this.state.gra.map((row, key) =>
            <div key={key} className="web-btn text-center" onClick={() => this.grp_show(row) }>
                <div className="txt_16">
                    { utils.toLang( row.text ) }
                </div>
                <div className="txt_14">
                    { utils.toLang( row.desc ) }
                </div>
            </div>
        )}
        </div>
        </div>
    </div>

    { this.state.wnds.grp ? <Mnu_Grp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.dish ? <Mnu_Dish wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
    );
    }
}
export default Mnu_M1;