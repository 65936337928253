import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as srs from "../../lib/srs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sbo from "../sbo";
import * as utils from "../../assets/utils";
import * as net from "../../lib/net";

import Mnu_Grp from "./grp";
import Mnu_Dish from "./dish";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_Home extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.gid = "";
        this.state.grp = {};

        this.state.step = 1;
        this.state.mna = [];
        this.state.gra = [];
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        this.dsi_mp();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 125;
        css.overflow = "auto";
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo_css() {
        var css = {};
        css.width = "75%";
        if(sbo.cfg.logo_width) css.width = sbo.cfg.logo_width;
        return css;
    }
    logo() {
        var logo = null;

        //var url = "../../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        //logo = require("../../img/" + sbo.cfg.logo);
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close() {
        this.props.ctx.setState({ wnds: {} });
    };
    dish_show(row) {
        var self = this;
        var wnds = {};
        wnds.dish = true;
        wnds.dishrow = row;
        this.setState({wnds: wnds});
    }

    dsi_mp() {
        var self = this;

        var prm = {};
        prm.sid = "";
        prm.req = "dsi.dsi_mp.mp_web";
        prm.sid = sbo.cfg.sid;
        prm.typ = "P";
        prm.mnuid = this.state.gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({ mna: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    cart_add() {
        //alert(JSON.stringify(this.state.dish));
        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
        }
        sbo.cart.rows.push( this.state.dish );
        //var pris = this.state.dish.price.replaceAll("-", "");
        var pris = this.state.dish.pbo.plu00.p0pris.replaceAll("-", "");
        pris = (pris * 1);
        sbo.cart.belopp = sbo.cart.belopp + pris;
    };
    grp_show(row) {
        var self = this;
        sbo.wso.grp = row;
        this.fknGo("grp");
    }

    plu_pris(row) {
        var pris = "";
        if(row.pbo && row.pbo.plu00) {
            var pris = row.pbo.plu00.p0pris;
            pris = utils.toPris( pris );
        }
        return pris;
    }
    plu_anm(row) {
        var anm = "";
        if(row.pbo) {
            //anm = row.pbo.anm;
            anm = row.desc;
            anm = utils.toLang( anm );
        }
        return anm;
    }
    plu_ing(row) {
        var ing = "";
        if(row.pbo) {
            //anm = row.pbo.anm;
            ing = row.ing;
            ing = utils.toLang( ing );
        }
        return ing;
    }
    grp_show(row) {
        var self = this;
        var wnds = {};
        wnds.grp = true;
        wnds.grprow = row;
        this.setState({wnds: wnds});
    }
    dish_show(row) {
        var self = this;
        var wnds = {};
        wnds.dish = true;
        wnds.dishrow = row;
        this.setState({wnds: wnds});
    }
    dsi_mnus() {
        var self = this;

        var channel = sbo.cfg.chan["eatin"].channel;
        var prm = {};
        prm.req = "dsi.dsi_mnu.mnu_list";
        prm.sid = sbo.cfg.sid;
        prm.channel = channel;
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ gra: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    dsi_mp() {
        var self = this;

        var prm = {};
        prm.req = "dsi.dsi_mp.mp_web";
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ mna: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    render() {

        return (
    <div className="web-app">
        <div className="flex-center">
            <div className="txt_22">BORDSBESTÄLLNING</div>
        </div>
        <div className="flex-center">
            <div className="txt_18">{sbo.cfg.text}</div>
            <div className="txt_18">Bord: {sbo.cfg.bord}</div>
        </div>
        <div className="flex"></div>
        <div className="flex-center py-2">
            <img className="" style={ this.logo_css() } src={ this.logo() } />
        </div>
        <div className="flex"></div>
        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.about() }>OM OSS</div>
            <div className="txt_18 web-btn flex" onClick={() => this.help() }>HJÄLP</div>
        </div>
        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.offer() }>ERBJUDANDE</div>
            <div className="txt_18 web-btn flex" onClick={() => this.offer_week() }>VECKANS</div>
        </div>
        <div className="flex-row">
            <div className="txt_18 web-btn flex" onClick={() => this.shop_exit() }>AVBRYT KÖP</div>
            <div className="txt_18 web-btn flex" onClick={() => this.shop_begin() }>BÖRJA HANDLA</div>
        </div>

    </div>
        );
    }
}
export default observer(Mnu_Home);