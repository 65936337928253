import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sbo from "../sbo";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_M0 extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    componentDidMount(){
    }

   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo_css() {
        var css = {};
        css.width = "75%";
        if(sbo.cfg.logo_width) css.width = sbo.cfg.logo_width;
        return css;
    }
    logo() {
        var logo = null;

        //var url = "../../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        logo = require("../../img/" + sbo.cfg.logo);
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    about() {
    };
    help() {
    };
    offer() {
    };
    offer_week() {
    };

    login() {
        var sdo = {};
        sdo.text = "UUIIDD";
        var xdo = JSON.stringify(sdo);
        //alert(JSON.stringify(xdo));
        sessionStorage.setItem("sxp", xdo);
        //localStorage.setItem("sxp", xdo);
    };
    logout() {
        sessionStorage.removeItem("sxp");
        //localStorage.removeItem("sxp");
        //this.props.history.push("/");
    };
    shop_exit() {
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        sbo.cfg.bord = "";

        var url = "/" + sbo.cfg.uid;
        this.props.navigate(url);
    };
    shop_begin() {
        this.fknGo("m1");
    };

    render() {

        return (
<div className="web-app">

    <div className="web-body flex-col">

        <div className="flex flex-center">
            <div className="txt_22">TA MED</div>
        </div>
        <div className="flex"></div>
        <div className="flex-center py-2">
            <img className="" style={ this.logo_css() } src={ this.logo() } />
        </div>
        <div className="flex"></div>
        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.about() }>OM OSS</div>
            <div className="txt_18 web-btn flex" onClick={() => this.help() }>HJÄLP</div>
        </div>
        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.offer() }>ERBJUDANDE</div>
            <div className="txt_18 web-btn flex" onClick={() => this.offer_week() }>VECKANS</div>
        </div>
        <div className="flex-row">
            <div className="txt_18 web-btn flex" onClick={() => this.shop_exit() }>AVBRYT KÖP</div>
            <div className="txt_18 web-btn flex" onClick={() => this.shop_begin() }>BÖRJA HANDLA</div>
        </div>

    </div>
</div>
        );
    }
}
export default withRouter(Mnu_M0);