import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import styled from "styled-components"
import {observer} from "mobx-react";

import sbo from "./sbo";

import Shop_ScanTable from "./fkn/scan_table";
import Shop_ScanQR from "./fkn/scan_qr";


class Order_Closed extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
    }

    componentDidMount(){

        var xdo = sessionStorage.getItem("sxp");
        if(xdo) {
            var sdo = JSON.parse(xdo);
            this.setState({ sdo: sdo });
        }
        var self = this;
        if(sbo.cfg.ot == 1) {
            sbo.cfg.ot = 0;
            setTimeout(function() {
                self.eatin();
            }, 100);
        }
        if(sbo.cfg.ot == 9) {
            sbo.cfg.ot = 0;
            setTimeout(function() {
                //self.run_v9();
            }, 100);
        }
    }

    logo(){
        var logo = null;
        //var url = "../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        //if(sbo.cfg.logo) logo = require("../img/" + sbo.cfg.logo);
        return logo;
    }
    banner(){
        var logo = null;
        //var url = "../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        //if(sbo.cfg.logo) logo = require("../img/" + sbo.cfg.logo);
        if(sbo.cfg.banner) logo = require("../img/" + sbo.cfg.banner);
        return logo;
    }

    scan_qr() {
        var self = this;
        var wnds = {};
        wnds.scanqr = true;
        wnds.cb = self.tb_cb;
        this.setState({wnds: wnds});
    }
    tb_cb(ctx, rsp) {
        if(rsp.ok == "000") {
            sbo.cfg.bord = rsp.bord;
            if(sbo.cfg.bord.length > 0) ctx.run_v1();
            else ctx.scan_qr();
        }
        ctx.setState({ wnds: {} });
    }

    eatout() {
        var self = this;
        sbo.cfg.bord = "99";
        sbo.cfg.v1 = 1;
        //self.run_v3();
    }

    show_fkn(fkn) {
        var css = {};
        css.display = "none";
        //if(fkn == "eatout") css.display = "";

        if(sbo.cfg.styp == "bas") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "rst") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "ss") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "demo") {
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "tst") {
            if(fkn == "eatout") css.display = "";
            if(fkn == "eatin") css.display = "";
            if(fkn == "v1") css.display = "";
            if(fkn == "v2") css.display = "";
        }
        css.display = "none";

        return css;
    }

    render() {

        return (
        <Bg className="">
        <div className="web-body flex-col">

       <div className="flex-center py-2">
            <img className="svk-logo" src={ this.logo() } />
        </div>
        <div className="divider_grey"></div>
        <div className="flex-center">
            <Txt21 className="">STÄNGT</Txt21>
        </div>
        <div className="divider_grey"></div>
 
        <div className="flex"></div>
 
        </div>
        </Bg>
    );
    }
}
export default withRouter(observer(Order_Closed));

const Txt01 = styled.div`
  font-size: 14px;
  color: #222;
`
const Txt21 = styled.div`
  font-size: 22px;
  color: #222;
`
const Logo = styled.img`
  width: 29px;
  height: 29px;
`
const Banner = styled.img`
  width: 100%;
  height: 75px;
  object-fit: cover;
`
const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("../img/bg-ol12.png");
  background-position: cover;
`
