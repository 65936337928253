import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";

import Pay_Home from "./home";
import Pay_Order from "./pay_order";
import Pay_Std from "./pay_std";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Flow_Pay extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

  render() {

    return (
        <Routes>
            <Route path="order" element={ <Pay_Order store={this.store} />} />
            <Route path="std" element={ <Pay_Std store={this.store} />} />
            <Route path="home" element={ <Pay_Home store={this.store} />} />
            <Route path="*" element={ <Pay_Std store={this.store} />} />
        </Routes>
  );
}
}
//Sys.contextType = ADOContext;
export default observer(Flow_Pay);
