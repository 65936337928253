import React from 'react';
import * as q from '../lib/defer';

import socketIOClient from 'socket.io-client'

    var defer = {};

    var url = 'http://127.0.0.1:9150/';
    if(false) url = 'http://192.168.193.185:9150/';
    if(false) url = 'http://212.112.180.177:9550/';
    if(true) url = 'https://services.svepos.se:9550/';

    //const sio = socketIOClient(url);
    const sio = socketIOClient(url, {transports: ["websocket"]});
    //sio.emit('change color', this.state.color);

	sio.on('connect', function () {
	    //alert('Connected!');
	    //toastr.success("IO CONNECTED");
	});
	sio.on('connect_error', function (e) {
	    //alert('Connect Error : ' + JSON.stringify(e));
	    //toastr.error("IO CONNECT ERROR", e);
	});
	sio.on('error', function (e) {
	    //alert('Socket Error : ' + e);
	    //toastr.error("IO ERROR", e);
	});

	sio.on('xx:msg', function (data) {
		//alert(data);
	});

	sio.on('gcs:rsp', function (base64) {
		//var s = atob(base64);
		//var rsp = JSON.parse(s);
		//alert(JSON.stringify(base64));
		var rsp = base64;
		var reqid = rsp.reqid;
		//if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
		if(defer[reqid]) defer[reqid].resolve(rsp);
	});
	sio.on('sapi:rsp', function (base64) {
	    //alert(JSON.stringify(base64));
		//var s = atob(base64);
		var rsp = base64;
		//var rsp = JSON.parse(s);
		var reqid = rsp.reqid;
		var rco = rsp.rco;
		//alert(JSON.stringify(reqid));
		//if(defer[reqid]) defer[reqid].resolve(rsp);
		if(defer[reqid]) defer[reqid].resolve(rco);
	});
	sio.on('ccs:rsp', function (base64) {
	    //alert(JSON.stringify(base64));
		//var s = atob(base64);
		var rsp = base64;
		//var rsp = JSON.parse(s);
		var reqid = rsp.reqid;
		var rco = rsp.rco;
		//alert(JSON.stringify(reqid));
		//if(defer[reqid]) defer[reqid].resolve(rsp);
		if(defer[reqid]) defer[reqid].resolve(rco);
	});
	sio.on('gfs:rsp', function (base64) {
		var s = atob(base64);
		var rsp = JSON.parse(s);
		//alert(JSON.stringify(rsp));
		var reqid = rsp.reqid;
		//if(self.defer[reqid]) self.defer[reqid].resolve(rsp);
	});
export function gcs_req(req) {

    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    req.reqid = reqid;
    req.sub = "usr";
    //req.token = "8796ac0586ec912fa584c530184c7ad1";
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(reqid));
    sio.emit("gcs:req", req, function () {
    });

    return defer[reqid].promise;
}

export function fkn_req(fkn, req) {

    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    req.reqid = reqid;
    req.sub = "usr";
    //req.token = "8796ac0586ec912fa584c530184c7ad1";
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(fkn));
    sio.emit(fkn, req, function () {
    });

    return defer[reqid].promise;
}
export function sio_req(req) {
    var sdo = {};

    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        sdo = JSON.parse(xdo);
    }
    else {
        sdo.token = "xx1122xx";
    }
    //sdo.token = "xx1122xx";
    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    //var req = {};
    //req.sid = prm.sid;
    req.reqid = reqid;
    req.sub = "pos";
    req.token = sdo.token;
    //req.prm = prm;
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(reqid));
    sio.emit("sapi:req", req, function () {
    });

    return defer[reqid].promise;
/*
    sio.req("sapi:req", rqo)
    .then(function(rsp) {
        //alert(JSON.stringify(rsp));
        defer.resolve(rsp);
    })
    .catch(function(e) {
        alert("ERR :" + JSON.stringify(e));
        defer.reject(e);
    });
*/
}
export function sid_req(req) {
    var sdo = {};

    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        sdo = JSON.parse(xdo);
    }
    else {
        sdo.token = "8796ac0586ec912fa584c530184c7ad1";
    }
    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    req.reqid = reqid;
    req.token = sdo.token;
    //req.token = sdo.token;
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(req));
    sio.emit("sid:req", req, function () {
    });

    return defer[reqid].promise;
}
export function ccs_req(req) {
    var sdo = {};

    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        sdo = JSON.parse(xdo);
    }
    else {
        sdo.token = "xx1122xx";
    }
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    defer[reqid] = q.defer();
    req.reqid = reqid;
    req.token = sdo.token;
    sio.emit("ccs:req", req, function () {
    });

    return defer[reqid].promise;
}
