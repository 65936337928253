import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from "react-router-dom";
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import sbo from './sbo.js'

import Mcom_Bas from "./bas/bas";
import Mcom_Qr from "./qr/qr";
import Mcom_TA from "./ta/ta";
import Mcom_Flow from "./flow/flow";
import Mcom_Kiosk from "./kiosk/kiosk";
import Mcom_Order from "./order/order";

import Mcom_Closed from "./closed";
import Mcom_NoAuth from "./noauth";
import Mcom_Home from "./home";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Mcom extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.pos = props.store.pos;

    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.infotxt = "";
      this.state.wnds = {};
      this.state.mwnd = 0;

      let { id } = props.params;
      sbo.cfg.uid = id;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }

  componentDidMount() {
    //let { id } = this.props.params;
    //alert(JSON.stringify(id));
    //sbo.cfg.id = id;
    //this.env_init();
    }

    env_init() {
        var env = sessionStorage.getItem("sbo");
        sbo.cfg = env.cfg;

    }


  fknGo(url) {
    //props.history.push(row.url);
    this.props.navigate(url);
   };

   theme_sel() {
    var cls = "web-app";
    //alert(sbo.cfg.shoptheme);
    if(sbo.cfg.shoptheme && (sbo.cfg.shoptheme.length > 0)) {
        cls = cls + " " + sbo.cfg.shoptheme;
    }
    else cls = cls +  " shoptheme";

    return cls;
    }

  render() {

    return (
        <div className={ this.theme_sel() }>

        <Routes>
            <Route path="bas/*" element={ <Mcom_Bas store={this.store} />} />
            <Route path="qr/*" element={ <Mcom_Qr store={this.store} />} />
            <Route path="ta/*" element={ <Mcom_TA store={this.store} />} />
            <Route path="kiosk/*" element={ <Mcom_Kiosk store={this.store} />} />
            <Route path="flow/*" element={ <Mcom_Flow store={this.store} />} />
        
            <Route path="order/*" element={ <Mcom_Order store={this.store} />} />
        
            <Route path="closed" element={ <Mcom_Closed store={this.store} />} />
            <Route path="noauth" element={ <Mcom_NoAuth store={this.store} />} />
        
            <Route path="*" element={ <Mcom_Home store={this.store} />} />
        </Routes>

  </div>
  );
}
}
export default withRouter(observer(withParams(Mcom)));
/*
        { sbo.unit.mode == "bas" ? <Mcom_Bas store={this.store} /> : null }
        { sbo.unit.mode == "kiosk" ? <Mcom_Kiosk store={this.store} /> : null }
        { sbo.unit.mode == "flow" ? <Mcom_Flow store={this.store} /> : null }
*/