import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import styled from "styled-components"
import {observer} from "mobx-react";

import sbo from "./sbo";

import Shop_ScanTable from "./fkn/scan_table";
import Shop_ScanQR from "./fkn/scan_qr";

import Shop_Home from "./home";
import Shop_Closed from "./closed";
import Shop_V1 from "./v1/v1";


class Order extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
    }
    componentDidMount(){

        var self = this;
        this.ses_init();

        /*
        if(sbo.cfg.ot == 1) {
            sbo.cfg.ot = 0;
            setTimeout(function() {
                self.eatin();
            }, 100);
        }
        if(sbo.cfg.ot == 9) {
            sbo.cfg.ot = 0;
            setTimeout(function() {
                //self.run_v9();
            }, 100);
        }
        */
    }
    ses_init() {

        var xdo = sessionStorage.getItem("sxp");
        if(xdo) {
            var sdo = JSON.parse(xdo);
            this.setState({ sdo: sdo });
        }

        if(false) {
            sbo.cfg.uid = "tolv";
            sbo.cfg.sid = "S0003401";
            sbo.cfg.styp = "rst";
            sbo.cfg.chan = {};
            sbo.cfg.chan["eatin"] = { "channel": "", "ftid": "", "ttid": "" };
            sbo.cfg.chan["eatout"] = { "channel": "TA", "ftid": "", "ttid": "" };
            sbo.cfg.text = "TOLV";
            sbo.cfg.city = "STOCKHOLM";
            sbo.cfg.tele = "020-104000";
            sbo.cfg.logo = "logo-olearys.png";
            sbo.cfg.banner = "banner-ol12.png";
            sbo.cfg.bord = "";
            sbo.cfg.swish = "2";
            sbo.cfg.shoptheme = "shop-06";
            sbo.cfg.hours = {};
            sbo.cfg.hours.from = "1200";
            sbo.cfg.hours.to = "2200";
            this.setState({valid: 1});
        }

        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            if(bord == "SS") {
                sbo.cfg.ot = 9;
            }
            else {
                sbo.cfg.bord = bord;
                sbo.cfg.ot = 1;
            }
        }
    }

    logo(){
        var logo = null;
        //var url = "../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        //logo = require("../img/" + sbo.cfg.logo);
        return logo;
    }

    scan_qr() {
        var self = this;
        var wnds = {};
        wnds.scanqr = true;
        wnds.cb = self.tb_cb;
        this.setState({wnds: wnds});
    }
    tb_cb(ctx, rsp) {
        if(rsp.ok == "000") {
            sbo.cfg.bord = rsp.bord;
            if(sbo.cfg.bord.length > 0) ctx.run_v1();
            else ctx.scan_qr();
        }
        ctx.setState({ wnds: {} });
    }

    eatout() {
        var self = this;
        sbo.cfg.bord = "99";
        sbo.cfg.v1 = 1;
        //self.run_v3();
    }

    show_fkn(fkn) {
        var css = {};
        css.display = "none";
        //if(fkn == "eatout") css.display = "";

        if(sbo.cfg.styp == "bas") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "rst") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "ss") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "demo") {
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "tst") {
            if(fkn == "eatout") css.display = "";
            if(fkn == "eatin") css.display = "";
            if(fkn == "v1") css.display = "";
            if(fkn == "v2") css.display = "";
        }

        return css;
    }
    theme_sel() {
        var cls = "web-app";
        if(sbo.cfg.shoptheme && (sbo.cfg.shoptheme.length > 0)) {
            cls = cls + " " + sbo.cfg.shoptheme;
        }
        else cls = cls +  " shoptheme";

        return cls;
    }

    render() {

        return (
       <div className={ this.theme_sel() }>
        { sbo.cfg.v1 !== 1 ? <Shop_Closed /> : null }
        { sbo.cfg.v1 == 3 ? <Shop_Home /> : null }
        { sbo.cfg.v1 == 1 ? <Shop_V1 /> : null }
        </div>
    );
    }
}
export default withRouter(observer(Order));

const Txt01 = styled.div`
  font-size: 14px;
  color: #222;
`
const Txt21 = styled.div`
  font-size: 22px;
  color: #222;
`
const Logo = styled.img`
  width: 29px;
  height: 29px;
`
