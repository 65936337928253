import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../../lib/react/withRouter";

import * as srs from "../../../lib/srs";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";

import sbo from '../../sbo.js'

import FoodleCheck from "../img/checkmark.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Pay_Order extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = {};
    this.state.payok = 0;
    this.state.swish_token = "";

    this.state.pro = {};
    this.state.pbo = {};
    if(props.wnds.pbo) {
        //this.state.pbo = props.wnds.pbo;
    }
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    if(sbo.cfg.swish && (sbo.cfg.swish == "1")) {
        this.swish_open();
    }
    if(sbo.cfg.swish && (sbo.cfg.swish == "2")) {
        this.pay_swish();
    }
}

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    content_style() {
        var css = {};
        if(this.state.payok) css.background = "#080";
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    body_style() {
        var css = {};
        if(this.state.payok) css.display = "none";
        return css;
    }
    info_style() {
        var css = {};
        if(!this.state.payok) css.display = "none";
        return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_paid() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    swish_tst() {
        var self = this;
        //var url = "https://shop.svepos.se/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
        var url = "/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
        //this.props.navigate(url);
        //window.location = url;
        this.props.navigate(url);
    };
    swish_fake() {
        var self = this;
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.orderid = sbo.cart.orderid;

        //alert(JSON.stringify(sbo));
        //return;
        srs.mcom_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
        })
        .catch(function(e) {
        })
    };

    swish_open() {
        var self = this;
        var prm = {};
        prm.req = "vcm.swish.mcom";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        //prm.notanr = "123457E";
        prm.notanr = sbo.cart.orderid;
        prm.topay = sbo.cart.belopp;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                var token = ret.token;
                //var cburl = "merchant%253A%252F%252F";
                this.setState({ swish_token: token });
                //var cburl = "https://shop.svepos.se/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&bord=" + sbo.cfg.bord + "&sid=" + sbo.cfg.sid;
                var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&bord=" + sbo.cfg.bord + "&sid=" + sbo.cfg.sid;
                var url = "swish://paymentrequest?token=" + token + "&callbackurl=" + cburl;
                //alert(JSON.stringify(url));
                //window.location = url;
                this.props.navigate(url);
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa Swish.", errormsg: "Vänligen försök igen" });
                throw "999 Error";
            }
        })
        .catch(function(e) {
        })
    };

    pay_swish() {
        //alert(this.state.swish_token);
        //alert(sbo.cfg.sid);
        var cburl = "/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&bord=" + sbo.cfg.bord + "&sid=" + sbo.cfg.sid;
        //alert(cburl);
        //window.location = cburl;
        this.props.navigate(cburl);
    };

    pay_abort() {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex" style={ this.content_style() }>
            <div className="mx-close-btn" onClick={() => this.wnd_close() }>X</div>

            <div className="flex-cl py-3">
            <div className="flex-row px-5">
                <div className="txt_b18">BETALNING PÅGÅR</div>
                <div className="flex"></div>
            </div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row px-5 py-3">
                <div className="txt_b18">ATT BETALA</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
            </div>

            <div className="mx-body px-5 flex-center" style={ this.info_style() }>
                <div className="txt_22 text-white">BETALNING KLAR</div>
            </div>

            <div className="mx-body flex px-5" style={ this.body_style() }>

            <div className="mpg flex py-5" onClick={() => this.pay_swish() }>
                    <div className="flex-col flex-center">
                        <div className="txt_b16">ORDERID: { utils.toNum( sbo.cart.orderid ) }</div>
                        <div className="txt_b16">AVVAKTAR SWISH BETALNING</div>
                        <div className="txt_b16">TRYCK FÖR BETALA</div>
                    </div>
                </div>

                <div className="mpg flex py-5 hide" onClick={() => this.pay_cc() }>
                    <div className="flex-col flex-center">
                        <div className="txt_b16">BETAL KOPPLING</div>
                        <div className="txt_b16">TRYCK FÖR ATT BETALA</div>
                    </div>
                </div>

            <div className="flex-col px-5 py-5 hide" >
                <div className="flex-row">
                    <div className="txt_b16">Test betalningar</div>
                </div>
                <div className="flex-row">
                <div className="btn btn-hw-pay" onClick={() => this.pay_cc() }>KORT</div>
                <div className="btn btn-hw-pay" onClick={() => this.pay_swish() }>SWISH</div>
                </div>
                <div className="flex-row">
                <div className="btn btn-hw-pay" onClick={() => this.pay_vip() }>LOJALITET</div>
                <div className="btn btn-hw-pay" onClick={() => this.pay_pres() }>PRESENTKORT</div>
            </div>
            </div>

            </div>
        </div>
        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-row">
                <div className="mfull-btn" onClick={() => this.pay_abort() }>AVBRYT</div>
            </div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default withRouter(Pay_Order);