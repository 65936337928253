import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';

import * as net from "../../lib/net";

//import PdfDlg from "./dlg/pdf_dlg";
//import LoginDlg from "./dlg/login_dlg";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class HkaGst extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.webStore = props.store;
        this.state = {};
        this.state.gaa = [];
        this.state.loading = 0;
        this.state.sdo = {};
    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }

    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
    row_css(row) {
        var css = {};
        if(this.state.row) {
            if(this.state.row.ank == row.ank) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 225;
        return css;
    }
    hka_gst() {
        var self = this;
        var prm = {};
     	prm.req = "pms.op.my_inhouse";

        //gda.wndLoading("Hämtar");
        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({gaa: ret.rco });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    hka_upd() {
        var self = this;
        var prm = {};
     	prm.req = "pos.pos_rp.mx_rp";
   		prm.fdat = "20200701";
   		prm.tdat = "20200701";
   		prm.favd = "01";
   		prm.tavd = "01";
   		prm.fkn = "kred";
        //alert(JSON.stringify(prm));

        net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    render() {
/*
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
*/

        return (
            <div className="web-content flex-column">


            <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-hw-blue w150 ripple" onClick={(event) => this.hka_gst(event) } >
                    GÄSTER
                </button>
                <button className="btn btn-hw-blue w150 ripple" onClick={(event) => this.hka_upd(event) } >
                    UPPDATERA
                </button>
            </div>
             <div className="flex">
                <table className="table table-bordered table-hover tb fixed_header">
                    <thead>
                    <tr>
                        <th>Rum</th>
                        <th>Text</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody style={ this.tb_style() }>
                    { this.state.gaa.map((row, key) =>
                    <tr key={"gst_" + key} id={ row.plu00.p0katnr } onDoubleClick={() => this.plu_open(row) } draggable={true} onDragStart={ (event) => this.drag_start(event, row)} onDrag={ (event) => this.drag(event)}>
                        <td>{ row.plu00.p0katnr }</td>
                        <td>{ row.plu00.p0namn.toswe() }</td>
                        <td className="text-right">{ row.plu00.p0pris.formatpris() }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div className="">
                <div className="txt_14">Gäster : { this.state.gaa.length }</div>
            </div>


         </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default HkaGst;
