import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import {withRouter} from "../lib/react/withRouter";

import Dd_Sel from "../lib/ui/dd_sel";
import Mnu_M0 from "./v5/m0";
import Mnu_M1 from "./v5/m1";
import Mnu_M2 from "./v5/m2";
import Mnu_M3 from "./v5/m3";
import Prd_Scan from "./v5/prd_scan";
import sbo from "./sbo";
import Shop_ScanQR from "./fkn/scan_qr";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Shop_V5 extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.stat = [];
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;
        this.state.sdo = {};
        this.state.wnds = {};

        //this.state.sdo = context;
        //context.posrend = this;

        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.op = "home";
        this.state.mna = [];
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.mnuitems = [
            {fkn: 'refresh', text: 'UPPDATERA'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'exit', text: 'AVBRYT KÖP'}
        ];
    }
    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    componentDidMount(){
        var url = "/shop/v5";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v5";
        sbo.cfg.vurl = url;
        sbo.cart.ordertyp = "SHOP";

        this.init_sale();
    }
    init_sale() {
        var mnus = ["home", "m1", "m2", "m3"];
        var path = window.location.pathname;
        //var op = path.split("/").pop();
        var op = "home";
        var pa = path.split("/");
        if(pa.length > 1) op = pa[3];
        //alert(JSON.stringify(op));
        if( !mnus.includes(op) ) op = "home";

        this.setState({op: op});
    }

   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "web-top";
		if(this.state.top) {
			//cls = cls + " web-head_1";
		}
		//else cls = cls +  " web-header";

		return cls;
    }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({ sdo: sdo })
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.navigate("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        //css.height = window.innerHeight;
        //css['minHeight'] = window.innerHeight;
		return css;
    }
    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }
    row_css(row) {
        var css = {};
        if(this.state.row) {
            if(this.state.row.ank == row.ank) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    row_icon_css(id) {
  	    var css = {};
        css.paddingTop = "5px";
        css.fontSize = "20px";
        if(id == this.state.op) {
            css.color = "#fff";
        }
		return css;
    }

    fknGo(url) {
        var op = this.state.op;
        if(url.length > 1) op = url;
        /*
        var pa = url.split("/");
        if(pa.length > 1) op = pa[3];
        if( op == "" ) op = "home";
        if( !op ) op = "home";
        */
        this.setState({ op: op });

        this.props.navigate(url);
    };
    mnu_style(id) {
      var css = {};
      if(id == this.state.op) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
    }
    sel_fkn(sel) {
        //alert(JSON.stringify(sel.fkn));
      if(sel.fkn == "exit") {
        var url = "/shop";
        this.props.navigate(url);
      }
      if(sel.fkn == "refresh") {
            window.location.reload(true);
      }
    }
    login() {
        var sdo = {};
        sdo.text = "UUIIDD";
        var xdo = JSON.stringify(sdo);
        //alert(JSON.stringify(xdo));
        sessionStorage.setItem("sxp", xdo);
    };
    logout() {
        sessionStorage.removeItem("sxp");
        //this.props.history.push("/");
    };
    show_cart() {
        var txt;

        txt = "INGET BESTÄLLT ÄN";
        if( sbo.cart.rows && sbo.cart.rows.length ) {
            txt = "BESTÄLLT: " + sbo.cart.rows.length + " PRODUKTER TILL BELOPPET: " + sbo.cart.belopp.cdipris();
        }

        return txt;
    };
    shop_exit() {
        var url = "/" + sbo.cfg.uid;
        this.props.navigate(url);
    };

    scan_qr() {
        var self = this;
        var wnds = {};
        wnds.scanqr = true;
        //wnds.row = row;
        this.setState({wnds: wnds});
    }
    cart_pay() {
        var url = "/shop/checkout";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/checkout";
        this.fknGo(url);
    };
    cart_nrof() {
        var nrof = 0;
        if(sbo.cart && sbo.cart.rows) nrof = sbo.cart.rows.length;
        return nrof;
    };

    render() {

        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }

        return (
            <div className="web-app">
            <div className={ this.header_class() }>
            <div className="flex-row px-2">
                <div className="">
                    <Dd_Sel items={ this.state.mnuitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
                <div className="flex-row flex-center" style={{lineHeight: "40px"}} onClick={() => this.fknGo("/shop/cart")}>
                    { this.show_cart() }
                </div>
                <div className="flex"></div>
            </div>
            </div>


            <div className="flex-col flex" style={{ overflow : "auto" }}>

                <Routes>
                    <Route path="m1/*" element={ <Mnu_M1 store={this.webStore} />} />
                    <Route path="m2/*" element={ <Mnu_M2 store={this.webStore} />} />
                    <Route path="m3/*" element={ <Mnu_M3 store={this.webStore} />} />
                    <Route path="*" element={ <Mnu_M0 store={this.webStore} />} />
                </Routes>

            </div>

             <div className="web-foot">
             <div className="flex flex-row">
                <div className="foot-btn flex flex-column" style={ this.mnu_style("m0") } onClick={() => this.fknGo("m0")}>
                    <FontAwesomeIcon icon="home" size="lg" color="grey" style={ this.row_icon_css("m0") } />
                    <div style={{fontSize: "12px"}}>HEM</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("m1") } onClick={() => this.fknGo("m1")}>
                    <FontAwesomeIcon icon="gears" size="lg" color="grey" style={ this.row_icon_css("m1") } />
                    <div style={{fontSize: "12px"}}>PRODUKTER</div>
                </div>
                 <div className="foot-btn flex flex-column" style={ this.mnu_style("pscan") } onClick={() => this.scan_qr()}>
                     <FontAwesomeIcon icon="puzzle-piece" size="lg" color="grey" style={ this.row_icon_css("pscan") } />
                     <div style={{fontSize: "12px"}}>SKANNA</div>
                 </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("co") } onClick={() => this.cart_pay()}>
                    <FontAwesomeIcon icon="shopping-cart" size="lg" color="grey" style={ this.row_icon_css("co") } />
                    <div style={{fontSize: "12px"}}>VARUKORG</div>
                    <span className="badge">{ this.cart_nrof() }</span>
                </div>
            </div>
            </div>
            { this.state.wnds.scanqr ? <Prd_Scan wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default withRouter(observer(Shop_V5));
