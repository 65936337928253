import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import sbo from "../sbo";
import Wq_Nr from "./wq_nr";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_Dish extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.nrof = "1";
        this.state.nrglas = "1";
        this.state.sum = "";
        this.state.comment = "";

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.dish = {};
        if(props.wnds.dishrow) {
            this.state.dish = props.wnds.dishrow;
            this.state.sum = this.state.dish.pbo.plu00.p0pris;
        }
        this.state.step = 1;
        this.state.mna = [];
        this.state.tba = [];
        this.state.tba.push({ "plu": "4013", "text": "Pepsi", "pris": "0" });
        this.state.tba.push({ "plu": "4010", "text": "Pepsi Max", "pris": "0" });
        this.state.tba.push({ "plu": "4012", "text": "Zingo", "pris": "0" });
        this.state.tba.push({ "plu": "4015", "text": "7-Up", "pris": "0" });
        this.state.tba.push({ "plu": "4006", "text": "Juice Apelsin", "pris": "0" });
        this.state.tba.push({ "plu": "4009", "text": "Sodavatten", "pris": "0" });
        this.state.tba.push({ "plu": "0000", "text": "Ingen Dryck", "pris": "0" });
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        //alert(JSON.stringify(this.state.dish));
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }
    tb_style() {
        var css = {};
        //css.height = window.innerHeight - 125;
        css.overflow = "auto";
        return css;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    prd_comment(e) {
        var comment = e.target.value;
        this.setState({ comment: comment });
    };
    prd_nrglas(e) {
        var nrglas = e.target.value;
        this.setState({ nrglas: nrglas });
    };
    glas_sub() {
        var nrglas = this.state.nrglas;
        nrglas = (nrglas * 1) - 1;
        if(nrglas < 0) nrglas = 0;

        nrglas = nrglas.toString();
        this.setState({ nrglas: nrglas });
    };
    glas_add() {
        var nrglas = this.state.nrglas;
        nrglas = (nrglas * 1) + 1;
        nrglas = nrglas.toString();
        this.setState({ nrglas: nrglas });
    };
    prd_nrof(e) {
        var nrof = e.target.value;
        this.setState({ nrof: nrof });
    };
    prd_sub() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) - 1;
        if(nrof < 0) nrof = 0;

        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);

        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };
    prd_add() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) + 1;
        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);
        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    cart_add() {
        //alert(JSON.stringify(this.state.dish));
        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
        }
        var dish = this.state.dish;
        var antal = this.state.nrof;
        var pris = this.state.dish.pbo.plu00.p0pris.replaceAll("-", "");
        var belopp = (pris * 1) * (antal * 1);
        var comment = this.state.comment;
        dish.antal = antal.toString();
        dish.pris = pris.toString();
        dish.belopp = belopp.toString();
        dish.comment = comment.toString().trim();
        dish.txo = {};
/*
        if(this.state.tbo && this.state.tbo.plu) {
            var tbo = {};
            tbo.m0katnr = this.state.tbo.plu;
            tbo.m0text = this.state.tbo.text;
            tbo.m0nrof = "1";
            tbo.m0pris = this.state.tbo.pris;
            tbo.taxproc = "";
            dish.txo[this.state.tbo.plu] = tbo;
        }
*/
        //alert(JSON.stringify(dish.txo));

        sbo.cart.rows.push( dish );

        if(this.state.tbo && this.state.tbo.plu) {
            var tbo = {};
            tbo.plu = this.state.tbo.plu;
            tbo.text = this.state.tbo.text;
            tbo.antal = "1";
            tbo.price = this.state.tbo.pris;
            tbo.taxproc = "";
            sbo.cart.rows.push( tbo );
        }
        //var pris = this.state.dish.price.replaceAll("-", "");
        sbo.cart.belopp = sbo.cart.belopp + belopp;
        this.props.ctx.setState({ wnds: {} });
    };
    sel_glas() {
        var css = {};
        css.display = "none";
        if(this.state.dish.pbo.plu02.p2storhet == "01") css.display = "block";
        return css;
    }
    sel_dryck() {
        var css = {};
        css.display = "none";
        if(this.state.dish.pbo.plu00.p0katnr == "0113") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1902") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1904") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1916") css.display = "block";
        return css;
    }
    wq_sel() {
        var self = this;
        var wnds = {};
        wnds.wqnr = true;
        wnds.cb = this.cb_wqnr;
        this.setState({wnds: wnds});
    }
    cb_wqnr(ctx, rsp) {
        if(rsp.ok == "000") {
            //alert(JSON.stringify(rsp.val));
            ctx.setState({nrof: rsp.val, wnds: {}});
        }
        else ctx.setState({wnds: {}});
    }
    plu_pris(row) {
        var pris = "";
        if(row.pris) {
            var pris = row.pris;
            pris = utils.toPris( pris );
        }
        return pris;
    }
    tbo_sel(row) {
        this.setState({tbo: row});
    }
    tbo_style(row) {
        var css = {};
        if(this.state.tbo) {
            if(this.state.tbo == row) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>LÄGG TILL PRODUKT</div>
            </div>
        <div className="mx_content flex">

            <div className="flex-center hide">
                <img width="250px" height="250px" src={ this.img_url(this.state.dish.pbo.img) } style={{ "objectFit": "cover" }}/>
            </div>
            <div className="flex-col flex px-5">
                <div className="flex-row">
                <div className="txt_18">{ utils.toLang(this.state.dish.text) }</div>
                <div className="flex"></div>
                <div className="txt_18">{ utils.toPris(this.state.dish.pbo.plu00.p0pris) }</div>
                </div>
                <div className="divider_blue"></div>
                <div className="txt_14">Beskrivning</div>
                <div className="txt_14 kursiv">{ this.state.dish.ing }</div>
                <div className="txt_14">{ this.state.dish.desc }</div>
                <div className="flex"></div>
                <div className="flex-cc txt_18">Antal</div>
                <div className="flex-row">
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_sub() }>-</div>
                    <input className="web-inp-21 w75" value={ this.state.nrof } onChange={(e) => this.prd_nrof(e) } />
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_add() }>+</div>
                </div>
                <div className="flex-col" style={ this.sel_glas() }>
                <div className="flex-cc txt_18">Antal Glas</div>
                <div className="flex-row">
                    <div className="web-btn-11 flex txt_32" onClick={() => this.glas_sub() }>-</div>
                    <input className="web-inp-11 w75" value={ this.state.nrglas } onChange={(e) => this.prd_nrglas(e) } />
                    <div className="web-btn-11 flex txt_32" onClick={() => this.glas_add() }>+</div>
                </div>
                </div>

                <div className="flex-col flex" style={ this.sel_dryck() }>
                    <div className="txt_18" onClick={() => this.wq_sel() }>Välj dryck</div>
                    <div style={ this.tb_style() }>
                        { this.state.tba.map((row, key) =>
                            <div className="mnu_item flex-row" key={key} onClick={() => this.tbo_sel(row) } style={ this.tbo_style(row) }>
                                <div className="flex flex-col">
                                    <div className="flex-row">
                                        <div className="mnu_item_head txt_16 flex">{ utils.toLang( row.text ) }</div>
                                        <div className="txt_16">{ this.plu_pris(row) }</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex"></div>
                <div className="flex-row">
                    <div className="flex-cc txt_22">Pris: { utils.toPris(this.state.dish.pbo.plu00.p0pris) }</div>
                    <div className="flex"></div>
                    <div className="flex-cc txt_22">Summa: { utils.toPris(this.state.sum) }</div>
                </div>
                <div className="">
                <div className="divider_green"></div>
                <div className="txt_18" onClick={() => this.wq_sel() }>Kommentar</div>
                <div className="flex-row">
                    <textarea className="web-comment-11 flex" maxLength="512" rows="6" value={ this.state.comment } onChange={(e) => this.prd_comment(e) } ></textarea>
                </div>
                </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="txt_18 web-btn-01 flex" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="txt_18 web-btn-02 flex" onClick={() => this.cart_add() }>LÄGG TILL</div>
            </div>

        </div>
    </div>
        { this.state.wnds.wqnr ? <Wq_Nr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
        );
    }
}
export default observer(Mnu_Dish);