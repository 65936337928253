import React from 'react';
import {action, makeObservable, observable} from "mobx";

const sbo = {};
sbo.cfg = {};
sbo.cdo = {};
sbo.cart = {};
sbo.wso = {};

makeObservable(sbo, {
    cfg: observable,
    cdo: observable,
    cart: observable,
    wso: observable
});

export default sbo;