import React from 'react';
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';

import './css/index.css';
import './css/web.css';
import './css/colours.css';
import './css/btns.css';
import './css/tb.css';
import './css/flex.css';
import './css/mdlg.css';
import './css/theme.css';

import MsaApp from './msa';
import * as serviceWorker from './serviceWorker';
import './assets/string.js';

import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'
//import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab);

/*
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<MsaApp tab="home" />);
*/
ReactDOM.render(
  <React.StrictMode>
    <MsaApp />
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();