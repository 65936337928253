import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";

import Sale_Home from "./home";
import Sale_Grp from "./grp";
import Sale_Kitchens from "./kitchens";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Ta_Sale extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
  };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

  render() {

    return (
        <Routes>
            <Route path="grp" element={ <Sale_Grp store={this.store} />} />
            <Route path="kitchens" element={ <Sale_Kitchens store={this.store} />} />
            <Route path="home" element={ <Sale_Home store={this.store} />} />
            <Route path="*" element={ <Sale_Kitchens store={this.store} />} />
        </Routes>
    );
  }
}
//Sys.contextType = ADOContext;
export default observer(Ta_Sale);
