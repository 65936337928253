// Utils

export function toLang(s) {
      if(!s) s = "";
      s = s.toString().trim().toswe();
      return s;
};

export function toPris(s) {
      if(!s) s = "";
      s = s.toString().cdipris();
      return s;
};

export function toNum(val) {
      var ret = "";
      if(val) ret = val.toString().ztrim();
      return ret;
}

export function toDate(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
}

export function toTid(val) {
      var ret = "";
      if(val) ret = val.toString().cditid();
      return ret;
}

export function toMinTid(val) {
      var ret = "";
      if(val) ret = val.toString().cdimin2tid();
      return ret;
}

export function toRumsnr(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
}
