import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as srs from "../../lib/srs";
import sbo from '../sbo.js'
import * as utils from "../../assets/utils";

import {withRouter} from "../../lib/react/withRouter";

class Mcs_Kiosk extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

}
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        sbo.cfg.nocart = 1;
    }

     back() {
        if(false) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        sessionStorage.removeItem("sbo");
        var url = "/flow";
        this.props.navigate(url);
    }
    wnd_close() {
        if(false) {
            sbo.cdo = {};
            sbo.cart = {};
            sbo.cart.belopp = "0";
            sbo.cart.rows = [];
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        //sessionStorage.removeItem("sbo");
        //var url = "/flow";
        //this.props.navigate(url);
    }

  render() {

    return (
    <div className="web-app flex-col">

    <div className="divider_orange my-1"></div>

    <div className="flex"></div>

        <div className="">
        <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    TAKE AWAY
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    VÄNLIGEN
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    FÖRSÖK IGEN
                </div>
            </div>
            <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    TACK!
                </div>
            </div>
        </div>¨

        
        <div className="flex"></div>

        <div>{this.state.result}</div>
       <div className="txt_18 web-btn-01 hide" onClick={() => this.wnd_close() }>STÄNG</div>
    </div>
  );
}
}

export default withRouter(observer(Mcs_Kiosk));
