import React from 'react';
import {observable} from "mobx"

class Ado {
    fid = "12341234";
    fkn = {};
    lang = "sv";
    cfg = {};
    sdo = {};
    ddo = {};
    gdo = {};

    pos = {};
    pms = {};
    ta = {};
    //@observable text = "Start 00";
    //setfid = (val) => {this.fid = val};
    //setBread = (val) => {this.bread = val};
}
/*
decorate(Ado, {
  fid: observable,
  fkn: observable,
  lang: observable,
  cfg: observable,
  seo: observable,
  gdo: observable,
  pos: observable,
  pms: observable,
  ta: observable
  //addReview: action,
  //averageScore: computed,
  //reviewCount: computed
});
*/
//const store = new Ado()

export default Ado;
//const ADOContext = React.createContext(ado);

//export default ADOContext;