import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from "react-router-dom";

import Ecom_Home from "./home";
import Ecom_Land from "./land";
import Ecom_Sale from "./sale";

import sbo from "./sbo";
import {withRouter} from "../lib/react/withRouter";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Ecom extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.valid = 1;
        this.state.step = 1;
        this.state.shoptheme = "shop-04";
    }

    componentWillUnmount() {
        this.setState({info: "UUU" });
    }
    componentDidMount() {
        //this.validate_unit();
    }

    validate_unit() {
        let { id } = this.props.params;
        //alert(JSON.stringify(id));

        if(id == "demo") {
            sbo.cfg.uid = id;
            sbo.cfg.sid = "S0000091";
            sbo.cfg.styp = "demo";
            sbo.cfg.chan = {};
            sbo.cfg.chan["eatin"] = { "channel": "", "ftid": "", "ttid": "" };
            sbo.cfg.chan["eatout"] = { "channel": "TA", "ftid": "", "ttid": "" };
            sbo.cfg.chan["shop"] = { "channel": "SH", "ftid": "", "ttid": "" };
            sbo.cfg.text = "SVERIGEKASSAN";
            sbo.cfg.city = "STOCKHOLM";
            sbo.cfg.tele = "010-7505000";
            sbo.cfg.logo = "logo-sk.png";
            sbo.cfg.bord = "11";
            sbo.cfg.swish = "2";
            sbo.cfg.shoptheme = "shop-02";
            this.setState({valid: 1});
        }
        else {
            //this.props.navigate("/");
            this.setState({valid: 0});
        }

        //sbo.cfg.bord = "";
        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            sbo.cfg.bord = bord;
            sbo.cfg.ot = 1;
        }
    }

    header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
        var cls = "flex_col";
        if(this.state.top) {
            cls = cls + " web-head-1";
        }
        else cls = cls +  " web-header";

        return cls;
    }
    theme_sel() {
        var cls = "web-app";
        if(sbo.cfg.shoptheme && (sbo.cfg.shoptheme.length > 0)) {
            cls = cls + " " + sbo.cfg.shoptheme;
        }
        else cls = cls +  " shoptheme";

        return cls;
    }
    xtheme_sel() {
        var cls = "web-app";
        if(this.state.shoptheme && (this.state.shoptheme.length > 0)) {
            cls = cls + " " + this.state.shoptheme;
        }
        else cls = cls +  " shoptheme";

        return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/ecom/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };

    render() {

        return (
    <div className={ this.theme_sel() }>

        {this.state.valid ?
            <Routes>
                <Route path="sale/*" element={<Ecom_Sale store={this.store}/>}/>
                <Route path="*" element={<Ecom_Home store={this.store}/>}/>
            </Routes>

            :
            <Routes>
                <Route path="*" element={<Ecom_Land store={this.store}/>}/>
            </Routes>
        }
    </div>
        );
    }
}
export default withRouter(withParams(Ecom));