import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import { QrReader } from 'react-qr-reader'

import * as net from "../../../lib/net";
import {withRouter} from "../../../lib/react/withRouter";

class Shop_Scan extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.qr = "";
        this.state.result = "";

        this.state.step = 1;
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        //this.scan_qr();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    pickup_qr() {
        var self = this;

        var prm = {};
        prm.req = "vcm.ps.qr";
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                //var qr = 'data:image/png;base64,'+ ret.qr;
                self.setState({qr: ret.qr});
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    scan_qr() {
        var self = this;
        navigator.mediaDevices.getUserMedia({video: true});

        if (!navigator.mediaDevices) {
            throw new Error("mediaDevices API unavailable.");
        }

        navigator.mediaDevices.enumerateDevices()
        .then(function(devices){
            var cameras = devices.filter(d => (d.kind === "videoinput"));
            const video = document.getElementById("video");
            var stream = cameras[0];
            alert(JSON.stringify(cameras));
            video.srcObject = stream;
            video.play();
        });

    };
    handleScan = data => {
        if (data) {
            this.setState({
                result: data
            })
        }
    }
    handleError = err => {
        console.error(err)
    }

    render() {

    return (
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>LÄS KOD</div>
            </div>
            <div className="mx_content flex">
                <div className="flex-center">
                    <div className="txt_22">QR KOD</div>
                </div>
                <div className="divider_blue"></div>

                <div className="flex px-5">
                    <div className="flex-row">
                        <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            style={{ width: '100%' }}
                        />
                    </div>
                <div className="flex-row">
                    { this.state.qr ?
                        <img src={ this.state.qr } width="300px" height="300px" />
                        : null}
                </div>
                </div>

                <div>{this.state.result}</div>
                <div className="divider_blue"></div>
                <div className="txt_22 web-btn" onClick={() => this.wnd_close() }>STÄNG</div>

            </div>
        </div>
        </div>
    );
    }
}
export default withRouter(observer(Shop_Scan));