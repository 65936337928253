import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as utils from "../../assets/utils";
import * as srs from "../../lib/srs";
import * as q from "../../lib/defer";
import sbo from "../sbo";
import Wq_Nr from "./wq_nr";

class Mnu_Dish extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.nrof = "1";
        this.state.nrglas = "1";
        this.state.pris = "";
        this.state.sum = "";
        this.state.comment = "";
        this.state.gid = "";

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.dish = {};
        this.state.mdo = {};
        if(props.wnds.dishrow) {
            this.state.dish = props.wnds.dishrow;
            this.state.pris = this.state.dish.pbo.plu00.p0pris;
            this.state.sum = this.state.dish.pbo.plu00.p0pris;
            this.state.gid = props.wnds.gid;
            
            var ts = moment();
            var hhmm = ts.format("HHmm");
            if((hhmm > "1030") && (hhmm < "1400")) {
                var happy = this.state.dish.pbo.plu00.p0happy.replaceAll("-", "");
                if(happy.ztrim().length > 0) {
                    this.state.pris = happy;
                    this.state.sum = happy;
                }
            }
       }

       this.state.pro = {};
       this.state.pro.plu = "";
       this.state.pro.text = "";
       this.state.pro.price = "";
       this.state.pro.desc = "";
       this.state.pro.belopp = this.state.sum;
   
       this.state.pro.ino = {};
       this.state.pro.ixo = {};
       this.state.pro.txo = {};
       this.state.pro.sxo = {};
       this.state.pro.dxo = {};

       this.state.ixabox = 0;
       this.state.txabox = 0;
       this.state.sxabox = 0;
       this.state.dxabox = 0;
       //this.state.ina = this.state.pbo.pbo.iga;
 
        this.state.step = 1;
        this.state.mna = [];
        this.state.tba = [];
        this.state.tba.push({ "plu": "4013", "text": "Pepsi", "pris": "0" });
        this.state.tba.push({ "plu": "4010", "text": "Pepsi Max", "pris": "0" });
        this.state.tba.push({ "plu": "4012", "text": "Zingo", "pris": "0" });
        this.state.tba.push({ "plu": "4015", "text": "7-Up", "pris": "0" });
        this.state.tba.push({ "plu": "4006", "text": "Juice Apelsin", "pris": "0" });
        this.state.tba.push({ "plu": "4009", "text": "Sodavatten", "pris": "0" });
        this.state.tba.push({ "plu": "0000", "text": "Ingen Dryck", "pris": "0" });
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        //alert(JSON.stringify(this.state.dish));
        this.mnu_md();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        css.maxWidth = "768px";
        css.minWidth = "275px";
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }
    tb_style() {
        var css = {};
        //css.height = window.innerHeight - 125;
        css.overflow = "auto";
        return css;
    }



    /*******/

    ico_style() {
        var css = {};
        css.color = "#ddd";
        return css;
    }
    var_style() {
        var css = {};
        //if(this.state.pbo.pbo.plu02.p2storhet != "20") {
        if(this.state.varsel != 1) {
            css.display = "none";
        }
        return css;
    }
    nrof_style(row) {
        var css = {};
        if(!row.m0nrof || (row.m0nrof < 1)) {
            css.display = "none";
        }
        return css;
    }

    varico_style(row) {
        var css = {};
        css.background = "#fff";
        css.color = "#ddd";
        if(this.state.pbsel == row.plu00.p0katnr) {
            css.color = "#fee260";
        }
        return css;
    }
    var_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pbsel == row.plu00.p0katnr) {
            css.color = "#000";
        }
        return css;
    }

    ina_style(row) {
        var css = {};
        css.background = "#fee260";
        if(row.p0fast == "1") {
            css.background = "#080";
            css.color = "#fff";
            css.display = "none";
        }
        else {
            if(this.state.pro.ino[row.p0lopnr]) {
                css.background = "#ddd";
            }
        }
        return css;
    }
    ina1_style(row) {
        var css = {};
        css.display = "none";
        if(row.p0fast == "1") {
            css.background = "#080";
            css.color = "#fff";
            css.display = "block";
        }
        return css;
    }
    ina3_style(row) {
        var css = {};
        css.display = "none";
        if(row.p0typ == "INFO") {
            css.background = "#008";
            css.color = "#fff";
            css.display = "block";
        }
        return css;
    }
    ixa_toggle() {
        var toggle = this.state.ixabox;
        toggle = !toggle;
        this.setState({ ixabox: toggle });
    }
    txa_toggle() {
        var toggle = this.state.txabox;
        toggle = !toggle;
        this.setState({ txabox: toggle });
    }
    sxa_toggle() {
        var toggle = this.state.sxabox;
        toggle = !toggle;
        this.setState({ sxabox: toggle });
    }
    dxa_toggle() {
        var toggle = this.state.dxabox;
        toggle = !toggle;
        this.setState({ dxabox: toggle });
    }
    ixa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["E"] || (this.state.mdo["E"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.ixabox) {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    txa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["T"] || (this.state.mdo["T"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.txabox) {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    sxa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["S"] || (this.state.mdo["S"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.sxabox) {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    dxa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["D"] || (this.state.mdo["D"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.dxabox) {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    ixa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.ixo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    ixa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.ixo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    txa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.txo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    txa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.txo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    sxa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.sxo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    sxa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.sxo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    dxa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.dxo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    dxa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.dxo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }

    var_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pbsel == row.plu00.p0katnr) {
            ico = "circle-check";
        }
        return ico;
    }
    ixa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.ixo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    txa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.txo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    sxa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.sxo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    dxa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.dxo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }


    /***********/

    var_set(row) {
        var plusel = row.plu00.p0katnr
        this.setState({ pbsel: plusel });
    }
    ino_set(row) {
        var pro = this.state.pro;
        if(row.p0fast == "1") {
            return;
        }
        if(pro.ino[row.p0lopnr]) {
            delete( pro.ino[row.p0lopnr] );
        }
        else pro.ino[row.p0lopnr] = row;
        this.setState({ pro: pro });
    }
    ixo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.ixo[row.m0lopnr]) {
            delete( pro.ixo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.ixo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    txo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        //alert(JSON.stringify(row));
        if(pro.txo[row.m0lopnr]) {
            delete( pro.txo[row.m0lopnr] );
            if(this.state.gid == "008") {
                belopp = belopp - ((row.m0pris * 1) * ((row.m0nrof * 1) - 1));
            }
            else belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            var keys = Object.keys(pro.txo);
            pro.txo[row.m0lopnr] = row;
            row.m0nrof = 1;

            if(this.state.gid == "008") {
                if(keys.length > 0) belopp = belopp + (row.m0pris * 1);
            }
            else belopp = belopp + (row.m0pris * 1);

        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    sxo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.sxo[row.m0lopnr]) {
            delete( pro.sxo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.sxo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    dxo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.dxo[row.m0lopnr]) {
            delete( pro.dxo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.dxo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    txa_price(row) {
      var pris = "0";
        var pro = this.state.pro;

        if(this.state.gid == "008") {
            var keys = Object.keys(pro.txo);
            if(keys.length < 1) {
                pris = "0";
            }
            else pris = row.m0pris;
        }
        else pris = row.m0pris;
        pris = utils.toPris(pris);
        return pris;
    }
    tbo_add(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(!row.m0nrof) row.m0nrof = 0;
        row.m0nrof = row.m0nrof + 1;
        //alert(row.m0nrof);
        belopp = belopp + (row.m0pris * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    tbo_sub(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;

        if(!row.m0nrof) row.m0nrof = 0;
        else row.m0nrof = row.m0nrof - 1;
        //alert(row.m0nrof);

        if(row.m0nrof < 1) {
            if(typ == "E") delete( pro.ixo[row.m0lopnr] );
            if(typ == "T") delete( pro.txo[row.m0lopnr] );
            if(typ == "S") delete( pro.sxo[row.m0lopnr] );
            if(typ == "D") delete( pro.dxo[row.m0lopnr] );
            row.m0nrof = 0;
        }
        if(this.state.gid == "008") {
            var keys = Object.keys(pro.txo);
            if((keys.length < 1) && (row.m0nrof < 1)) {
                if(typ !== "T") belopp = belopp - (row.m0pris * 1);
            }
            else belopp = belopp - (row.m0pris * 1);
        }
        else belopp = belopp - (row.m0pris * 1);
        //belopp = belopp - (row.m0pris * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }




    /**************/




    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    prd_comment(e) {
        var comment = e.target.value;
        this.setState({ comment: comment });
    };
    prd_nrglas(e) {
        var nrglas = e.target.value;
        this.setState({ nrglas: nrglas });
    };
    glas_sub() {
        var nrglas = this.state.nrglas;
        nrglas = (nrglas * 1) - 1;
        if(nrglas < 0) nrglas = 0;

        nrglas = nrglas.toString();
        this.setState({ nrglas: nrglas });
    };
    glas_add() {
        var nrglas = this.state.nrglas;
        nrglas = (nrglas * 1) + 1;
        nrglas = nrglas.toString();
        this.setState({ nrglas: nrglas });
    };
    prd_nrof(e) {
        var nrof = e.target.value;
        this.setState({ nrof: nrof });
    };
    prd_sub() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) - 1;
        if(nrof < 0) nrof = 0;

        var sum = this.state.sum;
        //sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);
        sum = nrof * (this.state.pris * 1);

        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };
    prd_add() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) + 1;
        var sum = this.state.sum;
        //sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);
        sum = nrof * (this.state.pris * 1);
        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    cart_add() {
        var self = this;
        //alert(JSON.stringify(this.state.dish));
        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
        }
        var dish = this.state.dish;
        var antal = this.state.nrof;
        var pris = this.state.dish.pbo.plu00.p0pris.replaceAll("-", "");
        var ts = moment();
        var hhmm = ts.format("HHmm");
        if((hhmm > "1030") && (hhmm < "1400")) {
            var happy = this.state.dish.pbo.plu00.p0happy.replaceAll("-", "");
            if(happy.ztrim().length > 0) {
                pris = happy;
            }
        }

        var belopp = (pris * 1) * (antal * 1);
        var comment = this.state.comment;
        dish.antal = antal.toString();
        dish.pris = pris.toString();
        dish.belopp = belopp.toString();
        dish.comment = comment.toString().trim();
        dish.txo = {};
/*
        if(this.state.tbo && this.state.tbo.plu) {
            var tbo = {};
            tbo.m0katnr = this.state.tbo.plu;
            tbo.m0text = this.state.tbo.text;
            tbo.m0nrof = "1";
            tbo.m0pris = this.state.tbo.pris;
            tbo.taxproc = "";
            dish.txo[this.state.tbo.plu] = tbo;
        }
*/
        //alert(JSON.stringify(dish.txo));

        sbo.cart.rows.push( dish );

        /*
        var suprab = 0;
        if( sbo.cart.suprab )  suprab = sbo.cart.suprab * 1;
        var plu = this.state.dish.pbo.plu00.p0katnr * 1;
        if((plu < 2090) || (plu > 6000)) {
            var rabbel = Math.round((pris * 1) * 0.15);
            suprab = suprab + rabbel;
        }
        sbo.cart.suprab = suprab.toString();
        */

       if(this.state.tbo && this.state.tbo.plu) {

            var tbo = {};
            tbo.plu = this.state.tbo.plu;
            tbo.text = this.state.tbo.text;
            tbo.antal = "1";
            tbo.price = this.state.tbo.pris;
            tbo.taxproc = "";
            sbo.cart.rows.push( tbo );
        }
        //var pris = this.state.dish.price.replaceAll("-", "");
        //sbo.cart.belopp = sbo.cart.belopp + belopp;
        var belopp = 0;
        var suprab = 0;
        for(var o of sbo.cart.rows) {
            belopp = belopp + (( o.pris * 1 ) * ( o.antal * 1 ));

            //if(sbo.cfg.sid == "S0003401") {
            if(sbo.cfg.campaign == "SUP") {
                var plu = o.plu * 1;
                if((plu < 2090) || (plu > 6000)) {
                    var rabbel = Math.round((( o.pris * 1 ) * ( o.antal * 1 )) * 0.15);
                    suprab = suprab + rabbel;
                }
            }
        }
        sbo.cart.belopp = belopp;
        sbo.cart.suprab = suprab.toString();

        if(self.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            self.props.wnds.cb(self.props.ctx, rsp);
        }
        else self.props.ctx.setState({ wnds: {} });

    };
    sel_glas() {
        var css = {};
        css.display = "none";
        if(this.state.dish.pbo.plu02.p2storhet == "01") css.display = "block";
        return css;
    }
    sel_dryck() {
        var css = {};
        css.display = "none";
        if(this.state.dish.pbo.plu00.p0katnr == "0113") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1902") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1904") css.display = "block";
        if(this.state.dish.pbo.plu00.p0katnr == "1916") css.display = "block";
        return css;
    }
    wq_sel() {
        var self = this;
        var wnds = {};
        wnds.wqnr = true;
        wnds.cb = this.cb_wqnr;
        this.setState({wnds: wnds});
    }
    cb_wqnr(ctx, rsp) {
        if(rsp.ok == "000") {
            //alert(JSON.stringify(rsp.val));
            ctx.setState({nrof: rsp.val, wnds: {}});
        }
        else ctx.setState({wnds: {}});
    }
    plu_pris(row) {
        var pris = "";
        if(row.pris) {
            var pris = row.pris;
            pris = utils.toPris( pris );
        }
        return pris;
    }
    tbo_sel(row) {
        this.setState({tbo: row});
    }
    tbo_style(row) {
        var css = {};
        if(this.state.tbo) {
            if(this.state.tbo == row) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }
    mnu_md() {
        var self = this;

        var mnuid = this.state.gid;

        var defer = q.defer();
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.req = "pos.pos_mnudata.md_list";
        prm.mnuid = mnuid;
        //alert(JSON.stringify(prm));

        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            self.setState({ mdo: ret.rco });
            var rsp = {};
            rsp.ok = "000";
            defer.resolve(rsp);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            var rsp = {};
            rsp.ok = "999";
            defer.resolve(rsp);
        });
        return defer.promise;
    }
    get_md(typ) {
        var a = [];
        //if(typ == "E") a = this.state.mdo[typ];
        a = this.state.mdo[typ];
        if(!a) a = [];
        return a;
    }

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>LÄGG TILL PRODUKT</div>
            </div>
        <div className="mx_content flex">

            <div className="flex-center hide">
                <img width="250px" height="250px" src={ this.img_url(this.state.dish.pbo.img) } style={{ "objectFit": "cover" }}/>
            </div>
            <div className="flex-col flex px-5">
                <div className="flex-row">
                <div className="txt_18">{ utils.toLang(this.state.dish.text) }</div>
                <div className="flex"></div>
                <div className="txt_18">{ utils.toPris(this.state.pris) }</div>
                </div>
                <div className="divider_blue"></div>
                <div className="txt_14">Beskrivning</div>
                <div className="txt_12 kursiv">{ this.state.dish.ing }</div>
                <div className="txt_12 kursiv">{ this.state.dish.desc }</div>


                { this.get_md("E").length ?
            <div className="mpg">
                <div className="flex-row">
                    <div className="txt_b16 text-black">Lägg till ingredienser</div>
                </div>
                <div className="flex-col pxrow" style={ this.ixa_box() }>
                    { this.get_md("E").map((tbo, key) =>
                    <div key={key} className="flex-row pxmrow" style={ this.ixa_row_style(tbo) }>
                        <FontAwesomeIcon icon={ this.ixa_icon(tbo) } size="lg" style={ this.ixa_style(tbo) } onClick={() => this.ixo_set(tbo) } />
                        <div className="txt_16 px-3" onClick={() => this.ixo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                        <div className="flex" onClick={() => this.ixo_set(tbo) }></div>
                        <div className="flex-row" style={ this.nrof_style(tbo) }>
                            <FontAwesomeIcon icon="circle-minus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_sub(tbo, "E") } />
                            <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                            <FontAwesomeIcon icon="circle-plus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_add(tbo, "E") } />
                        </div>
                        <div className="txt_16 w100 text-right" onClick={() => this.ixo_set(tbo) }>{ utils.toPris(tbo.m0pris) } kr</div>
                    </div>
                    )}
                </div>
                <div className="flex-row flex-center" >
                    <div className="txt_14" onClick={() => this.ixa_toggle() }>VISA / DÖLJ</div>
                </div>
            </div>
            :  null }

                { this.get_md("T").length ?
                    <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Lägg till tillbehör { this.state.gid == "008" ? <span>(1 gratis)</span>:null}</div>
                    </div>
                    <div className="flex-col pxrow" style={ this.txa_box() }>
                        { this.get_md("T").map((tbo, key) =>
                            <div key={key} className="flex-row pxmrow" style={ this.txa_row_style(tbo) }>
                                <FontAwesomeIcon icon={ this.txa_icon(tbo) } size="lg" style={ this.txa_style(tbo) } onClick={() => this.txo_set(tbo) } />
                                <div className="mp-btn hide" onClick={() => this.tb_add(tbo) }>XX</div>
                                <div className="txt_16 px-3" onClick={() => this.txo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                                <div className="flex" onClick={() => this.txo_set(tbo) }></div>
                                <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                    <FontAwesomeIcon icon="circle-minus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_sub(tbo, "T") } />
                                    <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                    <FontAwesomeIcon icon="circle-plus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_add(tbo, "T") } />
                                </div>
                                <div className="txt_16 w100 text-right" onClick={() => this.txo_set(tbo) }>{ this.txa_price(tbo) } kr</div>
                            </div>
                        )}
                    </div>
                    <div className="flex-row flex-center" >
                        <div className="txt_12" onClick={() => this.txa_toggle() }>VISA / DÖLJ</div>
                    </div>
                </div>
                    :  null }

                { this.get_md("S").length ?
                <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Lägg till sideorder</div>
                    </div>
                    <div className="flex-col pxrow" style={ this.sxa_box() }>
                        { this.get_md("S").map((tbo, key) =>
                            <div key={key} className="flex-row pxmrow" style={ this.sxa_row_style(tbo) }>
                                <FontAwesomeIcon icon={ this.sxa_icon(tbo) } size="lg" style={ this.sxa_style(tbo) } onClick={() => this.sxo_set(tbo) } />
                                <div className="mp-btn hide" onClick={() => this.tb_add(tbo) }>XX</div>
                                <div className="txt_16 px-3" onClick={() => this.sxo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                                <div className="flex" onClick={() => this.sxo_set(tbo) }></div>
                                <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                    <FontAwesomeIcon icon="circle-minus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "S") } />
                                    <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                    <FontAwesomeIcon icon="circle-plus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_add(tbo, "S") } />
                                </div>
                                <div className="txt_16 w100 text-right" onClick={() => this.sxo_set(tbo) }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                    </div>
                    <div className="flex-row flex-center" >
                        <div className="txt_12" onClick={() => this.sxa_toggle() }>VISA / DÖLJ</div>
                    </div>
                </div>
                :  null }

                <div className="flex-col flex" style={ this.sel_dryck() }>
                    <div className="txt_18" onClick={() => this.wq_sel() }>Välj dryck</div>
                    <div style={ this.tb_style() }>
                        { this.state.tba.map((row, key) =>
                            <div className="mnu_item flex-row" key={key} onClick={() => this.tbo_sel(row) } style={ this.tbo_style(row) }>
                                <div className="flex flex-col">
                                    <div className="flex-row">
                                        <div className="mnu_item_head txt_16 flex">{ utils.toLang( row.text ) }</div>
                                        <div className="txt_16">{ this.plu_pris(row) }</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex-col flex" style={ this.sel_dryck() }>
                    <div className="txt_18" onClick={() => this.wq_sel() }>Välj dryck</div>
                    <div style={ this.tb_style() }>
                        { this.state.tba.map((row, key) =>
                            <div className="mnu_item flex-row" key={key} onClick={() => this.tbo_sel(row) } style={ this.tbo_style(row) }>
                                <div className="flex flex-col">
                                    <div className="flex-row">
                                        <div className="mnu_item_head txt_16 flex">{ utils.toLang( row.text ) }</div>
                                        <div className="txt_16">{ this.plu_pris(row) }</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex"></div>
                <div className="flex-cc txt_18">Antal</div>
                <div className="flex-row">
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_sub() }>-</div>
                    <input className="web-inp-21 w75" value={ this.state.nrof } onChange={(e) => this.prd_nrof(e) } />
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_add() }>+</div>
                </div>

                <div className="flex-col" style={ this.sel_glas() }>
                <div className="flex-cc txt_18">Antal Glas</div>
                <div className="flex-row">
                    <div className="web-btn-11 flex txt_32" onClick={() => this.glas_sub() }>-</div>
                    <input className="web-inp-11 w75" value={ this.state.nrglas } onChange={(e) => this.prd_nrglas(e) } />
                    <div className="web-btn-11 flex txt_32" onClick={() => this.glas_add() }>+</div>
                </div>
                </div>

                <div className="flex-row">
                    <div className="flex-cc txt_22">Pris: { utils.toPris(this.state.pris) }</div>
                    <div className="flex"></div>
                    <div className="flex-cc txt_22">Summa: { utils.toPris(this.state.pro.belopp) }</div>
                </div>
                <div className="">
                <div className="divider_green"></div>
                <div className="txt_18 hide" onClick={() => this.wq_sel() }>Kommentar</div>
                <div className="flex-row hide">
                    <textarea className="web-comment-11 flex" maxLength="512" rows="6" value={ this.state.comment } onChange={(e) => this.prd_comment(e) } ></textarea>
                </div>
                </div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="txt_18 web-btn-01 flex" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="txt_18 web-btn-02 flex" onClick={() => this.cart_add() }>LÄGG TILL</div>
            </div>

        </div>
    </div>
        { this.state.wnds.wqnr ? <Wq_Nr wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
        );
    }
}
export default observer(Mnu_Dish);