import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";

import Shop_Home from "./home";
import Shop_Land from "./land";
import Shop_V1 from "./v1";
import Shop_V2 from "./v2";
import Shop_V3 from "./v3";
import Shop_V5 from "./v5";
import Shop_V9 from "./v9";
import Shop_V11 from "./v11";
import Shop_Cart from "./cart/cart";
import Shop_Checkout from "./checkout/checkout";
import Shop_Order from "./checkout/order";
import Shop_Bye from "./checkout/bye";
import Shop_Closed from "./closed";

import Shop_W1 from "./w1";
import Shop_W3 from "./w3";

import sbo from "./sbo";
import {withRouter} from "../lib/react/withRouter";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Shop extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.step = 1;
        this.state.shoptheme = "shop-04";
    }

    componentWillUnmount() {
        this.setState({info: "UUU" });
    }
    componentDidMount() {
        //this.validate_unit();
        let { id } = this.props.params;
        //let { chid } = this.props.params;
        //alert(JSON.stringify(this.props.params));
        this.get_cfg(id);
    }

    validate_unit() {
        var self = this;
        let { id } = this.props.params;
        //alert(JSON.stringify(id));

        //sbo.cfg.bord = "";
        if(sbo.cfg.styp == "ta") {
            //sbo.cfg.bord = "9999";
            sbo.cfg.ot = 5;
        }
        if(sbo.cfg.styp == "dev") {
            //sbo.cfg.bord = "9999";
            sbo.cfg.ot = 1;
        }
        
        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            if(bord == "SS") {
                sbo.cfg.ot = 9;
            }
            else {
                sbo.cfg.bord = bord;
                sbo.cfg.ot = 1;
            }
        }

        //sbo.cfg.closed = 0;
                
        if(sbo.cfg.hours) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            if(hhmm < "0600") {
                wday = wday - 1;
                if(wday == 0) wday = 7;
            }
            //alert(JSON.stringify(wday));

            if((sbo.cfg.hours.wday) && (sbo.cfg.hours.wday[wday])) {
                var wo = sbo.cfg.hours.wday[wday];
                ftid = wo.ftid;
                ttid = wo.ttid;
            }

            if(hhmm < "0600") {
                if(ftid < "0600") {
                    if(hhmm < ftid) {
                        sbo.cfg.closed = 1;
                    }
                }
            }
            else {
                if(hhmm < ftid) {
                    sbo.cfg.closed = 1;
                }
            }

            if(hhmm > ttid) {
                sbo.cfg.closed = 1;
            }
            sbo.cfg.ftid = ftid;
            sbo.cfg.ttid = ttid;
        }

        if(sbo.cfg.campdts) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var dts = ts.format("YYMMDD");
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            //alert(JSON.stringify(wday));
            if(sbo.cfg.campdts[dts]) {
                var wo = sbo.cfg.campdts[dts];
                var ftid = wo.ftid;
                var ttid = wo.ttid;
                if((hhmm >= ftid) && (hhmm <= ttid)) {
                    sbo.cfg.campaign = wo.campaign;
                }
            }
        }
        
        if(sbo.cfg.closed == 1) {
            var self = this;
            var url = "/" + sbo.cfg.uid + "/closed";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }

        if(sbo.cfg.styp == "flow") {
            var self = this;
            var url = "/flow#" + sbo.cfg.bord;
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }

        //alert(JSON.stringify(sbo.cfg.ot));
        self.setState({ valid: 1 });
    }

    get_cfg(uid) {
        var self = this;

        sbo.cfg = {};
        sbo.cfg.text = "INAKTIV";

        //var token = this.state.token;
        var req = {};
        req.fkn = "MDB";
        req.uid = uid;
        //alert(JSON.stringify(req));

        axios.post("/mdb", req)
        //axios.post("http://127.0.0.1:9690/mdb", req)
        //axios.post("https:/shop.svepos.se/mdb", req)
            //axios.post("/gsid", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res.data));
                var sdo = res.data;
                if(sdo.ok == "000") {
                    sbo.cfg = sdo.rco;
                    //self.setState({ valid: 1 });
                    self.validate_unit();
                }
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
            });
        //this.setState({ phase: 1, pau: {} });
    }

    header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
        var cls = "flex_col";
        if(this.state.top) {
            cls = cls + " web-head-1";
        }
        else cls = cls +  " web-header";

        return cls;
    }
    theme_sel() {
        var cls = "web-app";
        if(sbo.cfg.shoptheme && (sbo.cfg.shoptheme.length > 0)) {
            cls = cls + " " + sbo.cfg.shoptheme;
        }
        else cls = cls +  " shoptheme";

        return cls;
    }
    xtheme_sel() {
        var cls = "web-app";
        if(this.state.shoptheme && (this.state.shoptheme.length > 0)) {
            cls = cls + " " + this.state.shoptheme;
        }
        else cls = cls +  " shoptheme";

        return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
   
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/shop/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };

    render() {

        return (
    <div className={ this.theme_sel() }>

        {this.state.valid ?
            <Routes>
                <Route path="day" element={<Shop_Home store={this.webStore}/>}/>
                { sbo.cfg.chan["eatout"] ? <Route path="ta/*" element={<Shop_V3 store={this.webStore}/>}/> : null }
                <Route path="v1/*" element={<Shop_V1 store={this.webStore}/>}/>
                <Route path="v2/*" element={<Shop_V2 store={this.webStore}/>}/>
                <Route path="v3/*" element={<Shop_V3 store={this.webStore}/>}/>
                <Route path="v5/*" element={<Shop_V5 store={this.webStore}/>}/>
                <Route path="v9/*" element={<Shop_V9 store={this.webStore}/>}/>
                <Route path="v11/*" element={<Shop_V11 store={this.webStore}/>}/>
                <Route path="cart" element={<Shop_Cart store={this.webStore}/>}/>
                <Route path="checkout" element={<Shop_Checkout store={this.webStore}/>}/>
                <Route path="order" element={<Shop_Order store={this.webStore}/>}/>
                <Route path="bye" element={<Shop_Bye store={this.webStore}/>}/>
                <Route path="w1/*" element={<Shop_W1 store={this.webStore}/>}/>
                <Route path="w3/*" element={<Shop_W3 store={this.webStore}/>}/>
                <Route path="closed/*" element={<Shop_Closed store={this.webStore}/>}/>
                <Route path="*" element={<Shop_Home store={this.webStore}/>}/>
            </Routes>

            :
            <Routes>
                <Route path="*" element={<Shop_Land store={this.webStore}/>}/>
            </Routes>
        }
    </div>
        );
    }
}
export default withRouter(withParams(Shop));