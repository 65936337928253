import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as utils from "../../../assets/utils";
import * as net from "../../../lib/net";
import sbo from "../../sbo";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Wq_Belopp extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.nrof = "1";
        this.state.nrglas = "1";
        this.state.sum = "";
        this.state.val = "";

        this.state.user = {};
        this.state.sdo = {};

        this.state.dish = {};
        if(props.wnds.dishrow) {
            this.state.dish = props.wnds.dishrow;
            this.state.sum = this.state.dish.pbo.plu00.p0pris;
        }
        this.state.step = 1;
        this.state.mna = [];
    }

    componentWillUnmount(){
    }
    componentDidMount(){
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    nr_cl() {
        var val = this.state.val;
        var len = val.length;
        if(len > 0) {
            val = val.substring(0, len - 1);
            this.setState({ val: val });
        }
    };
    nr_komma() {
        var val = this.state.val;
        val = val + ".";
        this.setState({ val: val });
    };
    nr_set(nr) {
        var val = this.state.val;
        val = val + nr;
        this.setState({ val: val });
    };
    prd_nrof(e) {
        var nrof = e.target.value;
        this.setState({ nrof: nrof });
    };
    prd_sub() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) - 1;
        if(nrof < 0) nrof = 0;

        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);

        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };
    prd_add() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) + 1;
        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);
        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    wnd_done() {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.val = this.state.val;
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    };

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>BELOPP</div>
            </div>
        <div className="mx_content flex">

            <div className="flex-col flex px-5">
                <div className="flex">
                <div className="flex-cc txt_22">Ange belopp</div>
                <div className="flex-cc txt_30">{ this.state.val }</div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("1") }>1</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("2") }>2</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("3") }>3</div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("4") }>4</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("5") }>5</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("6") }>6</div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("7") }>7</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("8") }>8</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("9") }>9</div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_komma("") }>.</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_set("0") }>0</div>
                    <div className="web-hw-21 flex txt_32" onClick={() => this.nr_cl() }>CL</div>
                </div>
                <div className="flex"></div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="txt_18 web-hw-21 flex" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="txt_18 web-hw-21 web-hw-green flex" onClick={() => this.wnd_done() }>KLAR</div>
            </div>

        </div>
    </div>
    </div>
        );
    }
}
export default observer(Wq_Belopp);