import React from 'react';
import {action, makeObservable, observable} from "mobx";

const sbo = {};
sbo.cfg = {};
sbo.unit = {};
sbo.cdo = {};
sbo.ddo = {};
sbo.cart = {};
sbo.sio = null;

makeObservable(sbo, {
    cfg: observable,
    unit: observable,
    cdo: observable,
    ddo: observable,
    cart: observable
});

export default sbo;