import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react'
import { withRouter } from "../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShoppingFast} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import axios from "axios";

import Tiden from "../lib/ui/tiden";
import sbo from './sbo.js'
import * as srs from "../lib/srs";
import {format, getHours, subDays} from "date-fns";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Mcs_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

    this.state.today = new Date();
    var hh = getHours(this.state.today);

      this.state.mga = [];

      //sbo.cfg.uid = "tolvtest";
      //sbo.cfg.sid = "S0003499";
      //sbo.cfg.sid = "S0003495";
      //sbo.cfg.sid = "S0003401";
      //sbo.cfg.swish = "2";
   }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //let { id } = this.props.params;
        //sbo.cfg.uid = id;
        //alert(JSON.stringify(sbo.cfg.uid));

        //sessionStorage.removeItem("sbo");

        sbo.cfg.nocart = 0;
        sbo.ddo.fas = "s1";
        //this.validate_unit();
        //alert(JSON.stringify(sbo));
        //this.get_cfg(id);
    }

    env_init() {
        var env = sessionStorage.getItem("sbo");
        sbo.cfg = env.cfg;
        
    }

    get_cfg(id) {
        var self = this;

        sbo.cfg = {};
        sbo.cfg.text = "INAKTIV";
        sbo.cfg.uid = id;

        //var token = this.state.token;
        var req = {};
        req.fkn = "MDB";
        req.uid = id;
        //alert(JSON.stringify(req));

        //axios.post("/mdb", req)
        //axios.post("http://127.0.0.1:9690/mdb", req)
        axios.post("https:/shop.svepos.se/mdb", req)
        .then(res => {
            //alert("RSP: " + JSON.stringify(res.data));
            var sdo = res.data;
            if(sdo.ok == "000") {
                sbo.cfg = JSON.parse(JSON.stringify(sdo.rco));
                //self.setState({ valid: 1 });
                self.validate_unit();
            }
            else {
                self.no_auth();
            }
        })
        .catch((error) => {
            //alert("ERROR: " + JSON.stringify(error));
            //console.log(error.config);
            self.no_auth();
        });
        //this.setState({ phase: 1, pau: {} });
    }

    validate_unit() {
        var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
        if(params) {
            if(params.bord) sbo.cfg.bord = params.bord;
        }

        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            if(bord == "SS") {
                //sbo.cfg.ot = 9;
            }
            else {
                sbo.cfg.bord = bord;
                //sbo.cfg.ot = 1;
            }
        }

        sbo.cfg.flow = "eatin";

        //if((!sbo.cfg.bord) || (sbo.cfg.bord == undefined) || isNaN(sbo.cfg.bord) || (sbo.cfg.bord.length < 1)) {
        if((!sbo.cfg.bord) || (sbo.cfg.bord == undefined) || (sbo.cfg.bord.length < 1)) {
                var self = this;
            var url = "/" + sbo.cfg.uid + "/tsel";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }

        sbo.cart.bord = sbo.cfg.bord;
        sbo.cart.zone = "";
        if(sbo.cfg.uid == "tolv") {
            if( (sbo.cart.bord >= "0000") && (sbo.cart.bord <= "0099") ) { sbo.cart.zone = "0011"; } // Event
            if( (sbo.cart.bord >= "0100") && (sbo.cart.bord <= "0199") ) { sbo.cart.zone = "0001"; } // Zon 1
            if( (sbo.cart.bord >= "0200") && (sbo.cart.bord <= "0299") ) { sbo.cart.zone = "0002"; } // Zon 2
            if( (sbo.cart.bord >= "0300") && (sbo.cart.bord <= "0399") ) { sbo.cart.zone = "0003"; } // Zon 3
            if( (sbo.cart.bord >= "0400") && (sbo.cart.bord <= "0499") ) { sbo.cart.zone = "0004"; } // Zon 4
            if( (sbo.cart.bord >= "0700") && (sbo.cart.bord <= "0799") ) { sbo.cart.zone = "0007"; } // Zon 7
            if( (sbo.cart.bord >= "0800") && (sbo.cart.bord <= "0899") ) { sbo.cart.zone = "0012"; } // Balkong
            if( (sbo.cart.bord >= "0900") && (sbo.cart.bord <= "0999") ) { sbo.cart.zone = "0013"; } // Runda Baren
            if( (sbo.cart.bord >= "1050") && (sbo.cart.bord <= "1059") ) { sbo.cart.zone = "0014"; } // Golfbaren
        }

        if(sbo.cfg.hours) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            if(hhmm < "0600") {
                wday = wday - 1;
                if(wday == 0) wday = 7;
            }
            //alert(JSON.stringify(wday));

            if((sbo.cfg.hours.wday) && (sbo.cfg.hours.wday[wday])) {
                var wo = sbo.cfg.hours.wday[wday];
                ftid = wo.ftid;
                ttid = wo.ttid;
            }

            if(hhmm < "0600") {
                if(ftid < "0600") {
                    if(hhmm < ftid) {
                        sbo.cfg.closed = 1;
                    }
                }
            }
            else {
                if(hhmm < ftid) {
                    sbo.cfg.closed = 1;
                }
            }

            if(hhmm > ttid) {
                sbo.cfg.closed = 1;
            }
            sbo.cfg.ftid = ftid;
            sbo.cfg.ttid = ttid;
        }

        if(sbo.cfg.campdts) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var dts = ts.format("YYMMDD");
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            //alert(JSON.stringify(wday));
            if(sbo.cfg.campdts[dts]) {
                var wo = sbo.cfg.campdts[dts];
                var ftid = wo.ftid;
                var ttid = wo.ttid;
                if((hhmm >= ftid) && (hhmm <= ttid)) {
                    sbo.cfg.campaign = wo.campaign;
                }
            }
        }
        
        if(sbo.cfg.closed == 1) {
            var self = this;
            var url = "/" + sbo.cfg.uid + "/closed";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }

        //sessionStorage.setItem("sbo", JSON.stringify(sbo));
        localStorage.setItem("sbo", JSON.stringify(sbo));
        //alert(JSON.stringify(sbo.cfg));

        //sbo.unit.mode = sbo.cfg.styp;

        if(true) {
            var self = this;
            var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }
    }

    no_auth() {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/noauth";
        //var url = "/mcom/noauth";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
    }

  queryStringToJSON(queryString) {
    if(queryString.indexOf('?') > -1){
        queryString = queryString.split('?')[1];
    }
    var pairs = queryString.split('&');
    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
}

    mg_cls(btn) {
        var cls = "flex col mg-pasta";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //css.margin = "5px 12px";
        if(window.innerWidth < 500) {
            css.width = window.innerWidth - 10;
        }
        else {
            css.margin = "5px 12px";
        }
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      if(fil.length < 1) return "";

      var url = "";
        url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

  
  render() {

    return (
    <div className="web-app">

        <div className="mgv">
            <div className="flex-row py-1 hide">
                <div className="txt_14 flex">
                    Vi har rutiner för att hantera allergibeställningar och minska risken för kontaminering av allergener. Det kan förekomma spår av allergener då vi hanterar många råvaror i våra kök. Vi kan inte garantera att vår mat är helt fri från allergener trots att produkterna inte innehåller något av våra allergener.
                    Kontakta vår restaurangpersonal så hjälper de dig med din allergibeställning. Välkommen!
                </div>
            </div>
        </div>
        
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Mcs_Home);
