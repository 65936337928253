import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import { withRouter } from "../../lib/react/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShoppingFast} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import axios from "axios";

import Tiden from "../../lib/ui/tiden";
import sbo from '../sbo'
import * as srs from "../../lib/srs";
import {format, getHours, subDays} from "date-fns";

class Ta_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

    this.state.today = new Date();
    var hh = getHours(this.state.today);

      this.state.mga = [];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    sbo.cfg.nocart = 0;

    this.validate_unit();
    //this.get_cfg(sbo.cfg.uid);
  }

    env_init() {
        var env = sessionStorage.getItem("sbo");
        sbo.cfg = env.cfg;
        
    }

  validate_unit() {
    var params = this.queryStringToJSON(window.location.search);
    //alert(JSON.stringify(params));
    if(params) {
        //if(params.bord) sbo.cfg.bord = params.bord;
    }

    sbo.cfg.bord = "9999";
    sbo.cart.bord = sbo.cfg.bord;

    var hash = window.location.hash;
    if(hash) {
        //alert(JSON.stringify(hash));
        //var bord = hash.substring(1);
    }

    /*
    if((!sbo.cfg.bord) || (sbo.cfg.bord == undefined) || (sbo.cfg.bord.length < 1)) {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/tsel";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
        return;
    }
    */

    if(sbo.cfg.hours) {
        //alert(JSON.stringify(hash));
        var ts = moment();
        var hhmm = ts.format("HHmm");
        var wday = ts.format("E");
        var ftid = sbo.cfg.hours.ftid;
        var ttid = sbo.cfg.hours.ttid;
        if(hhmm < "0600") {
            wday = wday - 1;
            if(wday == 0) wday = 7;
        }
        //alert(JSON.stringify(wday));

        if((sbo.cfg.hours.wday) && (sbo.cfg.hours.wday[wday])) {
            var wo = sbo.cfg.hours.wday[wday];
            ftid = wo.ftid;
            ttid = wo.ttid;
        }

        if(hhmm < "0600") {
            if(ftid < "0600") {
                if(hhmm < ftid) {
                    sbo.cfg.closed = 1;
                }
            }
        }
        else {
            if(hhmm < ftid) {
                sbo.cfg.closed = 1;
            }
        }

        if(hhmm > ttid) {
            sbo.cfg.closed = 1;
        }
        sbo.cfg.ftid = ftid;
        sbo.cfg.ttid = ttid;
    }

    if(sbo.cfg.campdts) {
        //alert(JSON.stringify(hash));
        var ts = moment();
        var dts = ts.format("YYMMDD");
        var hhmm = ts.format("HHmm");
        var wday = ts.format("E");
        var ftid = sbo.cfg.hours.ftid;
        var ttid = sbo.cfg.hours.ttid;
        //alert(JSON.stringify(wday));
        if(sbo.cfg.campdts[dts]) {
            var wo = sbo.cfg.campdts[dts];
            var ftid = wo.ftid;
            var ttid = wo.ttid;
            if((hhmm >= ftid) && (hhmm <= ttid)) {
                sbo.cfg.campaign = wo.campaign;
            }
        }
    }
    
    if(sbo.cfg.closed == 1) {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/closed";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
        return;
      }
    
    if(true) {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/sale";
        if(sbo.cfg.start == "GRP") url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/sale/grp";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
    }
  }

  get_cfg(uid) {
    var self = this;

    sbo.cfg = {};
    sbo.cfg.text = "INAKTIV";

    //var token = this.state.token;
    var req = {};
    req.fkn = "MDB";
    req.uid = uid;
    //alert(JSON.stringify(req));

    //axios.post("/mdb", req)
    //axios.post("http://127.0.0.1:9690/mdb", req)
    axios.post("https:/shop.svepos.se/mdb", req)
        //axios.post("/gsid", req)
        .then(res => {
            //alert("RSP: " + JSON.stringify(res.data));
            var sdo = res.data;
            if(sdo.ok == "000") {
                sbo.cfg = sdo.rco;
                //self.setState({ valid: 1 });
                self.validate_unit();
            }
            else {
                self.no_auth();
            }
        })
        .catch((error) => {
            //alert("ERROR: " + JSON.stringify(error));
            //console.log(error.config);
            self.no_auth();
        });
    //this.setState({ phase: 1, pau: {} });
}

  queryStringToJSON(queryString) {
    if(queryString.indexOf('?') > -1){
        queryString = queryString.split('?')[1];
    }
    var pairs = queryString.split('&');
    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
}

    mg_cls(btn) {
        var cls = "flex col mg-pasta";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //css.margin = "5px 12px";
        if(window.innerWidth < 500) {
            css.width = window.innerWidth - 10;
        }
        else {
            css.margin = "5px 12px";
        }
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      if(fil.length < 1) return "";

      var url = "";
        url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }
    row_icon_css(id) {
        var css = {};
      css.paddingTop = "5px";
      css.fontSize = "20px";
      if(id == this.state.op) {
          css.color = "#fff";
      }
      return css;
  }
  fknGo(url) {
    var op = this.state.op;
    if(url.length > 1) op = url;
    /*
    var pa = url.split("/");
    if(pa.length > 1) op = pa[3];
    if( op == "" ) op = "home";
    if( !op ) op = "home";
    */
    this.setState({ op: op });

    this.props.navigate(url);
};
mnu_style(id) {
  var css = {};
  if(id == this.state.op) {
      css.background = "#080";
      css.color = "#fff";
  }
  return css;
}

sale_open() {
    //sbo.cfg.bord = "154";
    //sbo.cart.bord = "154";
    //var jsonstr = localStorage.getItem("sbo");
    //var env = JSON.parse(jsonstr);
    localStorage.setItem("sbo", JSON.stringify(sbo));
    //alert(JSON.stringify(sbo));

    if(true) {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/kitchens";
        //alert(JSON.stringify(url));
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
    }

};

  
  render() {

    return (
        <div className="web-app flex-col">

        <div className="flex"></div>

        <div className="flex-row flex-center py-1">
            <div className="txt_18 flex">
                    VÄLKOMMEN !
                </div>
            </div>

        <div className="flex"></div>
        <div className="flex-row flex-center">
            <div className="mpay-btn w300" onClick={() => this.sale_open() }>ÖPPNA</div>
        </div>

        <div className="web-foot hide">
             <div className="flex flex-row">
                <div className="foot-btn flex flex-column" style={ this.mnu_style("m0") } onClick={() => this.fknGo("m0")}>
                    <FontAwesomeIcon icon="home" size="lg" color="grey" style={ this.row_icon_css("m0") } />
                    <div style={{fontSize: "12px"}}>HEM</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("m1") } onClick={() => this.fknGo("m1")}>
                    <FontAwesomeIcon icon="utensils" size="lg" color="grey" style={ this.row_icon_css("m1") } />
                    <div style={{fontSize: "12px"}}>MENY</div>
                </div>
                 <div className="foot-btn flex flex-column" style={ this.mnu_style("pscan") } onClick={() => this.scan_qr()}>
                     <FontAwesomeIcon icon="puzzle-piece" size="lg" color="grey" style={ this.row_icon_css("pscan") } />
                     <div style={{fontSize: "12px"}}>SKANNA</div>
                 </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("co") } onClick={() => this.cart_pay()}>
                    <FontAwesomeIcon icon="shopping-cart" size="lg" color="grey" style={ this.row_icon_css("co") } />
                    <div style={{fontSize: "12px"}}>VARUKORG</div>
                    <span className="badge">{ 2 }</span>
                </div>
            </div>
            </div>
       
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(withRouter(Ta_Home));
