import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';

//import './css/animate.css';
//import './css/carousel.css';

//import PdfDlg from "./dlg/pdf_dlg";
//import LoginDlg from "./dlg/login_dlg";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Eko_Home extends React.Component {

    constructor(props, context) {
        super(props);


        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.sdo = {};

        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
    }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    w00_css() {
  	    var css = {};
        //css.height = window.innerHeight;
        //css['minHeight'] = window.innerHeight;
		return css;
    }
    w01_css() {
  	    var css = {};
        css.height = "75px";
        css.background = "#ff9900";
        css.color = "#fff";
        css.border = "1px #ccc solid";
        //css['minHeight'] = window.innerHeight;
		return css;
    }
    row_icon_css() {
  	    var css = {};
        css.paddingTop = "5px";
        css.fontSize = "20px";
		return css;
    }
    fknGo(url) {
        this.props.history.push(url);
   };

    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-content">

           <div id="m00" className="flex-cc" style={ this.w00_css() } onClick={() => this.fknGo("/pos/eko")}>
                <div className="txt_22">EKONOMI</div>
            </div>
                <div className="divider_orange"></div>
                <div className="flex"></div>
           <div id="m00" className="flex-center" style={ this.w01_css() } onClick={() => this.fknGo("/pos/eko/day")}>
                <div className="txt_22">DAG</div>
            </div>
           <div id="m00" className="flex-center" style={ this.w01_css() } onClick={() => this.fknGo("/pos/eko/hh")}>
                <div className="txt_22">TIM FÖRSÄLJNING</div>
            </div>
           <div id="m00" className="flex-center" style={ this.w01_css() } onClick={() => this.fknGo("/pos/eko/vgrp")}>
                <div className="txt_22">VARUGRUPPS FÖRSÄLJNING</div>
            </div>
           <div id="m00" className="flex-center" style={ this.w01_css() } onClick={() => this.fknGo("/pos/eko/period")}>
                <div className="txt_22">PERIOD</div>
            </div>
                <div className="flex"></div>


         </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Eko_Home;
