import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as srs from "../../lib/srs";
import sbo from "../sbo";
import * as utils from "../../assets/utils";

import Mnu_Dish from "./dish";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_Grp extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.store = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.gid = "";
        this.state.grp = {};
        if(props.wnds.grprow) {
            this.state.grp = props.wnds.grprow;
            //this.state.gid = this.state.grp.gid;
            this.state.gid = this.state.grp.mnuid;
        }
        this.state.step = 1;
        this.state.mna = [];
    }

    componentWillUnmount(){
    }
    componentDidMount(){
        this.dsi_mp();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 125;
        css.overflow = "auto";
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close() {
        this.props.ctx.setState({ wnds: {} });
    };
    dish_show(row) {
        var self = this;
        var wnds = {};
        wnds.dish = true;
        wnds.dishrow = row;
        this.setState({wnds: wnds});
    }

    dsi_mp() {
        var self = this;

        var prm = {};
        prm.sid = "";
        prm.req = "dsi.dsi_mp.mp_web";
        prm.sid = sbo.cfg.sid;
        prm.typ = "P";
        prm.mnuid = this.state.gid;
        //alert(JSON.stringify(this.state.grp));

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({ mna: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        url = "Tintin_72_dpi_Web_037.jpg";
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    cart_add() {
        //alert(JSON.stringify(this.state.dish));
        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
        }
        sbo.cart.rows.push( this.state.dish );
        //var pris = this.state.dish.price.replaceAll("-", "");
        var pris = this.state.dish.pbo.plu00.p0pris.replaceAll("-", "");
        pris = (pris * 1);
        sbo.cart.belopp = sbo.cart.belopp + pris;
    };
    plu_pris(row) {
        var pris = "";
        if(row.pbo && row.pbo.plu00) {
            var pris = row.pbo.plu00.p0pris;
            pris = utils.toPris( pris );
        }
        return pris;
    }
    plu_anm(row) {
        var anm = "";
        if(row.pbo) {
            //anm = row.pbo.anm;
            anm = row.desc;
            anm = utils.toLang( anm );
        }
        return anm;
    }
    plu_ing(row) {
        var ing = "";
        if(row.pbo) {
            //anm = row.pbo.anm;
            ing = row.ing;
            ing = utils.toLang( ing );
        }
        return ing;
    }

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }
        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>GRUPP: { this.state.grp.text }</div>
            </div>
            <div className="mx_content flex">

            <div className="flex-center hide">
                <div className="mnu-btn txt_18 text-center flex" onClick={() => this.ch_grp() }>BYT GRUPP</div>
            </div>

            <div className="flex-col flex">
                <div style={ this.tb_style() }>
                    { this.state.mna.map((row, key) =>
                        <div className="mnu_item flex-row" key={key} onClick={() => this.dish_show(row) }>
                            <div className="f20">
                                <img width="60px" height="60px" src={ this.img_url(row.pbo.img) } style={{ "objectFit": "cover" }}/>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex-row">
                                    <div className="mnu_item_head txt_16 flex">{ utils.toLang( row.text ) }</div>
                                    <div className="txt_16">{ this.plu_pris(row) }</div>
                                </div>
                                <div className="flex-row kursiv yellow">
                                    <div className="txt_12 flex">{ this.plu_ing(row) }</div>
                                    <div className="txt_12"></div>
                                </div>
                                <div className="flex-row">
                                    <div className="txt_12 flex">{ this.plu_anm(row) }</div>
                                    <div className="txt_12"></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="divider_green"></div>
            <div className="flex-row px-2">
                <div className="txt_18 web-btn-01 flex" onClick={() => this.wnd_close() }>STÄNG</div>
            </div>

        </div>
    </div>
        { this.state.wnds.dish ? <Mnu_Dish wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
        );
    }
}
export default observer(Mnu_Grp);