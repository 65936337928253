import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {observer} from "mobx-react";

import * as srs from "../../../lib/srs";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";

import sbo from '../../sbo.js'

import FoodleCheck from "../img/checkmark.svg";
import * as q from "../../../lib/defer";

class Plu_Open extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.vaa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = "";

    this.state.pro = {};
    this.state.pro.plu = "";
    this.state.pro.text = "";
    this.state.pro.price = "";
    this.state.pro.desc = "";
    this.state.pro.belopp = "0";

    this.state.pro.ino = {};
    this.state.pro.ixo = {};
    this.state.pro.txo = {};
    this.state.pro.sxo = {};
    this.state.pro.dxo = {};

      this.state.mdo = {};

      this.state.nrof = 1;
      this.state.sum = 0;
      this.state.varsel = 0;
      this.state.gid = "";
      this.state.pbsel = "";
      this.state.pbo = {};
    if(props.wnds.pbo) {
        this.state.gid = props.wnds.gid;
        this.state.pbo = props.wnds.pbo;
        this.state.pro.plu = this.state.pbo.plu.trim();
        this.state.pro.text = this.state.pbo.text.trim();
        this.state.pro.price = this.state.pbo.price.trim();
        this.state.pro.desc = this.state.pbo.desc.trim();
        this.state.pro.belopp = this.state.pbo.price.trim();

        this.state.pbsel = this.state.pbo.plu.trim();
        if(this.state.pbo.pbo.plu02.p2storhet == "20") {
            this.state.varsel = 1;
        }
    }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
      this.state.ina = [];
/*
      var ina = this.state.pro.desc.split(",");
      var i = 0;
      for(var txt of ina) {
          this.state.ina.push({id: i, text: txt, price: ''});
          i = i + 1;
      }
*/
      this.state.ixabox = 0;
      this.state.txabox = 0;
      this.state.sxabox = 0;
      this.state.dxabox = 0;
      this.state.ina = this.state.pbo.pbo.iga;

      this.state.ixa = [
          {id: '0', text: 'Extra tomatsås', price: '1000'},
          {id: '1', text: 'Extra Majo', price: '1000'}
      ];
    this.state.txa = [
      {id: '0', text: 'Dippsås Vitlök', price: '1000'},
      {id: '1', text: 'Dippsås Majo', price: '1000'}
    ];
    this.state.sxa = [
      {id: '0', text: 'Lökringar', price: '1900'},
      {id: '1', text: 'Pommes', price: '2500'}
    ];
    this.state.dxa = [
      {id: '0', text: 'MER Appelsin', price: '1500'},
      {id: '1', text: 'Coca Cola', price: '2000'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
      this.component_init();
  }

    component_init() {
        var self = this;
        this.mnu_md()
        .then(function(ret) {
            if(self.state.pbo.pbo.plu02.p2storhet == "20") {
                self.plu_variants();
            }
        })
    }

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    ico_style() {
        var css = {};
        css.color = "#ddd";
        return css;
    }
    var_style() {
        var css = {};
        //if(this.state.pbo.pbo.plu02.p2storhet != "20") {
        if(this.state.varsel != 1) {
            css.display = "none";
        }
        return css;
    }
    nrof_style(row) {
        var css = {};
        if(!row.m0nrof || (row.m0nrof < 1)) {
            css.display = "none";
        }
        return css;
    }

    varico_style(row) {
        var css = {};
        css.background = "#fff";
        css.color = "#ddd";
        if(this.state.pbsel == row.plu00.p0katnr) {
            css.color = "#fee260";
        }
        return css;
    }
    var_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pbsel == row.plu00.p0katnr) {
            css.color = "#000";
        }
        return css;
    }
    ina_style(row) {
        var css = {};
        css.background = "#fee260";
        if(row.p0fast == "1") {
            css.background = "#080";
            css.color = "#fff";
            css.display = "none";
        }
        else {
            if(this.state.pro.ino[row.p0lopnr]) {
                css.background = "#ddd";
            }
        }
        return css;
    }
    ina1_style(row) {
        var css = {};
        css.display = "none";
        if(row.p0fast == "1") {
            css.background = "#080";
            css.color = "#fff";
            css.display = "block";
        }
        return css;
    }
    ina3_style(row) {
        var css = {};
        css.display = "none";
        if(row.p0typ == "INFO") {
            css.background = "#008";
            css.color = "#fff";
            css.display = "block";
        }
        return css;
    }
    ixa_toggle() {
        var toggle = this.state.ixabox;
        toggle = !toggle;
        this.setState({ ixabox: toggle });
    }
    txa_toggle() {
        var toggle = this.state.txabox;
        toggle = !toggle;
        this.setState({ txabox: toggle });
    }
    sxa_toggle() {
        var toggle = this.state.sxabox;
        toggle = !toggle;
        this.setState({ sxabox: toggle });
    }
    dxa_toggle() {
        var toggle = this.state.dxabox;
        toggle = !toggle;
        this.setState({ dxabox: toggle });
    }
    ixa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["E"] || (this.state.mdo["E"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.ixabox) {
            css.height = "";
            css.overflow = "none";
        }
        if(sbo.cfg.xnoscroll == "1") {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    txa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["T"] || (this.state.mdo["T"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.txabox) {
            css.height = "";
            css.overflow = "none";
        }
        if(sbo.cfg.xnoscroll == "1") {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    sxa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["S"] || (this.state.mdo["S"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.sxabox) {
            css.height = "";
            css.overflow = "none";
        }
        if(sbo.cfg.xnoscroll == "1") {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    dxa_box() {
        var css = {};
        css.height = "75px";
        css.overflow = "hidden";
        if(!this.state.mdo || !this.state.mdo["D"] || (this.state.mdo["D"].length < 1)) {
            css.height = "0px";
        }
        if(this.state.dxabox) {
            css.height = "";
            css.overflow = "none";
        }
        if(sbo.cfg.xnoscroll == "1") {
            css.height = "";
            css.overflow = "none";
        }
        return css;
    }
    ixa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.ixo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    ixa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.ixo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    txa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.txo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    txa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.txo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    sxa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.sxo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    sxa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.sxo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }
    dxa_style(row) {
        var css = {};
        css.color = "#ddd";
        css.margin = "0px 3px";
        css.fontSize = "18px";
        if(this.state.pro.dxo[row.m0lopnr]) {
            css.color = "#fee260";
        }
        return css;
    }
    dxa_row_style(row) {
        var css = {};
        //css.color = "#ddd";
        if(this.state.pro.dxo[row.m0lopnr]) {
            css.color = "#000";
        }
        return css;
    }

    var_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pbsel == row.plu00.p0katnr) {
            ico = "circle-check";
        }
        return ico;
    }
    ixa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.ixo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    txa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.txo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    sxa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.sxo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    dxa_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro.dxo[row.m0lopnr]) {
            ico = "circle-check";
        }
        return ico;
    }
    var_set(row) {
        var plusel = row.plu00.p0katnr
        this.setState({ pbsel: plusel });
    }
    ino_set(row) {
        var pro = this.state.pro;
        if(row.p0fast == "1") {
            return;
        }
        if(pro.ino[row.p0lopnr]) {
            delete( pro.ino[row.p0lopnr] );
        }
        else pro.ino[row.p0lopnr] = row;
        this.setState({ pro: pro });
    }
    ixo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.ixo[row.m0lopnr]) {
            delete( pro.ixo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.ixo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    txo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        //alert(JSON.stringify(row));
        if(pro.txo[row.m0lopnr]) {
            delete( pro.txo[row.m0lopnr] );
            if(this.state.gid == "008") {
                belopp = belopp - ((row.m0pris * 1) * ((row.m0nrof * 1) - 1));
            }
            else belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            var keys = Object.keys(pro.txo);
            pro.txo[row.m0lopnr] = row;
            row.m0nrof = 1;

            if(this.state.gid == "008") {
                if(keys.length > 0) belopp = belopp + (row.m0pris * 1);
            }
            else belopp = belopp + (row.m0pris * 1);

        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    sxo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.sxo[row.m0lopnr]) {
            delete( pro.sxo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.sxo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    dxo_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro.dxo[row.m0lopnr]) {
            delete( pro.dxo[row.m0lopnr] );
            belopp = belopp - ((row.m0pris * 1) * (row.m0nrof * 1));
            row.m0nrof = 0;
        }
        else {
            pro.dxo[row.m0lopnr] = row;
            row.m0nrof = 1;
            belopp = belopp + (row.m0pris * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    txa_price(row) {
      var pris = "0";
        var pro = this.state.pro;

        if(this.state.gid == "008") {
            var keys = Object.keys(pro.txo);
            if(keys.length < 1) {
                pris = "0";
            }
            else pris = row.m0pris;
        }
        else pris = row.m0pris;
        pris = utils.toPris(pris);
        return pris;
    }
    tbo_add(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(!row.m0nrof) row.m0nrof = 0;
        row.m0nrof = row.m0nrof + 1;
        //alert(row.m0nrof);
        belopp = belopp + (row.m0pris * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    tbo_sub(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;

        if(!row.m0nrof) row.m0nrof = 0;
        else row.m0nrof = row.m0nrof - 1;
        //alert(row.m0nrof);

        if(row.m0nrof < 1) {
            if(typ == "E") delete( pro.ixo[row.m0lopnr] );
            if(typ == "T") delete( pro.txo[row.m0lopnr] );
            if(typ == "S") delete( pro.sxo[row.m0lopnr] );
            if(typ == "D") delete( pro.dxo[row.m0lopnr] );
            row.m0nrof = 0;
        }
        if(this.state.gid == "008") {
            var keys = Object.keys(pro.txo);
            if((keys.length < 1) && (row.m0nrof < 1)) {
                if(typ !== "T") belopp = belopp - (row.m0pris * 1);
            }
            else belopp = belopp - (row.m0pris * 1);
        }
        else belopp = belopp - (row.m0pris * 1);
        //belopp = belopp - (row.m0pris * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  val_anm_save( id, val ) {
    var state = this.state;
    state[id] = val.currentTarget.value;
    this.setState(state);
    }

  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_add() {
        var self = this;

        var pro = this.state.pro;

        //var rows = [];
        //rows.push( { plu: "0302", text: "Test Kebab", antal: "1", belopp: "2500", taxproc: "2000", } );
        //rows.push( { plu: "0322", text: "Test Pasta", antal: "1", belopp: "3200", taxproc: "2000", } );
        var row = {};
        row.gid = this.state.gid;
        row.plu = pro.plu;
        //row.plu = pro.plu.substr(2,4);
        row.text = pro.text;
        //row.antal = "1";
        row.antal = this.state.nrof.toString();
        row.pris = pro.price;
        row.belopp = pro.belopp;
        row.taxproc = pro.taxproc;
        //row.taxproc = "2000";
        row.pro = pro;

        if(!sbo.cart.belopp) sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];
        sbo.cart.rows.push(row);

        //if(!sbo.cart.pro) sbo.cart.pro = {};
        //sbo.cart.pro[row.plu] = row;

        var belopp = sbo.cart.belopp * 1;
        belopp = belopp + (pro.belopp * 1);
        sbo.cart.belopp = belopp.toString();
        
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
/*
        var gte = {};
        gte.add = {};
        gte.add.actionField = {};
        gte.add.actionField.list = "Produkt";
        gte.add.products = [];
        if(true) {
            var o = {};
            o.id = row.plu;
            o.name = row.text;
            o.price = row.belopp;
            o.brand = "";
            o.category = "";
            o.variant = "";
            o.quantity = row.antal;
            o.coupon = "";
            gte.add.products.push(o);
        }
*/
/*
        var gte = {};
        gte.items = [];
        if(true) {
            var price = (row.belopp * 1) / 100;
            var quantity = (row.antal * 1);

            var o = {};
            o.item_id = row.plu;
            o.item_name = row.text;
            o.affiliation = "";
            o.coupon = "";
            o.currency = "SEK";
            o.discount = 0;
            o.item_brand = "";
            o.affiliation = "";
            o.affiliation = "";
            o.price = price;
            o.quantity = quantity;
            gte.items.push(o);
        }

/*
        dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                items: [
                    {
                        item_id: "SKU_12345",
                        item_name: "Stan and Friends Tee",
                        affiliation: "Google Merchandise Store",
                        coupon: "SUMMER_FUN",
                        currency: "USD",
                        discount: 2.22,
                        index: 0,
                        item_brand: "Google",
                        item_category: "Apparel",
                        item_category2: "Adult",
                        item_category3: "Shirts",
                        item_category4: "Crew",
                        item_category5: "Short sleeve",
                        item_list_id: "related_products",
                        item_list_name: "Related Products",
                        item_variant: "green",
                        location_id: "L_12345",
                        price: 9.99,
                        quantity: 1
                    }
                ]
            }
        });
        */
/*
        //alert(JSON.stringify(gte));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({ event : "add_to_cart", ecommerce: gte });
*/
        this.props.ctx.setState({ wnds: {} });
    }

    cart_sell() {
    var self = this;

    var rows = [];
        rows.push( { plu: "0302", text: "Test Kebab", antal: "1", belopp: "2500", taxproc: "2000", } );
        rows.push( { plu: "0322", text: "Test Pasta", antal: "1", belopp: "3200", taxproc: "2000", } );

    var belopp = "5700";

    var prm = {};
    prm.req = "vcm.ps.ps_web_sell";
    prm.avd = "01";
    prm.tablenr = "9001";
    prm.cashier = "00";
    prm.betkod = "card";
    prm.bettxt = "CARD PAYMENT";
    prm.belopp = belopp;
    prm.rows = rows;

    //net.sio_req(prm)
    srs.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }

    mnu_md() {
        var self = this;

        var mnuid = this.state.gid;

        var defer = q.defer();
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.req = "pos.pos_mnudata.md_list";
        prm.mnuid = mnuid;
        //alert(JSON.stringify(prm));

        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rco));

            self.setState({ mdo: ret.rco });
            var rsp = {};
            rsp.ok = "000";
            defer.resolve(rsp);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            var rsp = {};
            rsp.ok = "999";
            defer.resolve(rsp);
        });
        return defer.promise;
    }
    plu_variants() {
        var self = this;

        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.req = "pos.pos_plu.plu_variants";
        prm.avd = "01";
        prm.pbo = this.state.pbo.pbo;

        //net.sio_req(prm)
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.setState({ vaa: ret.rca }, function() {
                    self.plu_variant_init();
                })
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    plu_variant_init() {
        var self = this;
        var row = this.state.vaa[0];
        var plusel = row.plu00.p0katnr
        //this.setState({ pbsel: plusel });

        //var pbo = row;
        var pbo = this.state.pbo;
        pbo.pbo = row;

        //var pro = {};
        var pro = this.state.pro;
        pro.plu = row.plu00.p0katnr.trim();
        pro.text = row.plu00.p0namn.trim();
        pro.price = row.plu00.p0pris.trim();
        //pro.desc = row.plu00.p0namn.trim();
        pro.belopp = row.plu00.p0pris.trim();
        self.setState({ pbo: pbo, pro: pro, pbsel: plusel });
    }
    plu_variant_set(row) {
        var self = this;
        var plusel = row.plu00.p0katnr
        //this.setState({ pbsel: plusel });

        //var pbo = row;
        var pbo = this.state.pbo;
        pbo.pbo = row;

        //var pro = {};
        var pro = this.state.pro;
        var belopp = pro.belopp;
        //alert(JSON.stringify(belopp));
        belopp = belopp - (pro.price * 1);
        belopp = belopp + (row.plu00.p0pris * 1);
        //alert(JSON.stringify(belopp));
        pro.plu = row.plu00.p0katnr.trim();
        pro.text = row.plu00.p0namn.trim();
        pro.price = row.plu00.p0pris.trim();
        //pro.desc = row.plu00.p0namn.trim();
        pro.belopp = belopp.toString().trim();
        self.setState({ pbo: pbo, pro: pro, pbsel: plusel });
    }
    get_md(typ) {
        var a = [];
        //if(typ == "E") a = this.state.mdo[typ];
        a = this.state.mdo[typ];
        if(!a) a = [];
        return a;
    }

    prd_nrof(e) {
        var nrof = e.target.value;
        this.setState({ nrof: nrof });
    };
    prd_sub() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) - 1;
        if(nrof < 0) nrof = 0;

        var pro = this.state.pro;
        var belopp = pro.belopp;
        belopp = nrof * (pro.price * 1);
        pro.belopp = belopp.toString();

        nrof = nrof.toString();
        this.setState({ nrof: nrof, pro: pro });
    };
    prd_add() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) + 1;

        var pro = this.state.pro;
        var belopp = pro.belopp;
        belopp = nrof * (pro.price * 1);
        pro.belopp = belopp.toString();
        nrof = nrof.toString();
        this.setState({ nrof: nrof, pro: pro });
    };

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base bg-white black">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_50hh flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">
            <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

            <div className="flex-col py-3 px-3">
                <div className="flex-row">
                    <div className="txt_22 font-bold">{ utils.toLang(this.state.pro.text) }</div>
                </div>
                <div className="flex-row">
                    <div className="txt_14">{ utils.toLang(this.state.pro.desc) }</div>
                </div>
            </div>
            <div className="divider_orange"></div>

            <div className="mx-body">

                <div className="mpg" style={ this.var_style() }>
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Välj variant</div>
                    </div>
                    <div className="flex-col pxrow">
                        { this.state.vaa.map((pbo, key) =>
                            <div key={key} className="flex-row" onClick={() => this.plu_variant_set(pbo) }  style={ this.var_row_style(pbo) }>
                                <FontAwesomeIcon icon={ this.var_icon(pbo) } size="lg" style={ this.varico_style(pbo) } />
                                <div className="txt_16 px-3">{ utils.toLang(pbo.plu00.p0namn) }</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(pbo.plu00.p0pris) } kr</div>
                            </div>
                        )}
                    </div>
                </div>

                { this.state.ina.length > 0 ?
                <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16">Fasta ingredienser</div>
                    </div>
                    <div className="flex-row px-5">
                        { this.state.ina.map((tbo, key) =>
                            <div key={key} className="mp-btn" style={ this.ina1_style(tbo) } onClick={() => this.ino_set(tbo) }>{ utils.toLang(tbo.p0text ) }</div>
                        )}
                    </div>

                    <div className="flex-row">
                        <div className="txt_b16 text-black">Valbara ingredienser</div>
                    </div>
                    <div className="flex-row pxrow mpg-grid">
                        { this.state.ina.map((tbo, key) =>
                            <div key={key} className="mp-btn" style={ this.ina_style(tbo) } onClick={() => this.ino_set(tbo) }>{ utils.toLang(tbo.p0text ) }</div>
                        )}
                    </div>

                </div>
            : null }

            <div className="mpg hide">
                <div className="flex-row">
                    <div className="txt_b16">Ingrediens information</div>
                </div>
                <div className="flex-row px-5">
                    { this.state.ina.map((tbo, key) =>
                        <div key={key} className="mp-btn" style={ this.ina3_style(tbo) } onClick={() => this.ino_set(tbo) }>{ utils.toLang(tbo.p0text ) }</div>
                    )}
                </div>
            </div>

            { this.get_md("E").length ?
            <div className="mpg">
                <div className="flex-row">
                    <div className="txt_b16 text-black">Lägg till ingredienser</div>
                </div>
                <div className="flex-col pxrow" style={ this.ixa_box() }>
                    { this.get_md("E").map((tbo, key) =>
                    <div key={key} className="flex-row pxmrow" style={ this.ixa_row_style(tbo) }>
                        <FontAwesomeIcon icon={ this.ixa_icon(tbo) } size="lg" style={ this.ixa_style(tbo) } onClick={() => this.ixo_set(tbo) } />
                        <div className="txt_16 px-3" onClick={() => this.ixo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                        <div className="flex" onClick={() => this.ixo_set(tbo) }></div>
                        <div className="flex-row" style={ this.nrof_style(tbo) }>
                            <FontAwesomeIcon icon="circle-minus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_sub(tbo, "E") } />
                            <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                            <FontAwesomeIcon icon="circle-plus" style={ this.ixa_style(tbo) } onClick={() => this.tbo_add(tbo, "E") } />
                        </div>
                        <div className="txt_16 w100 text-right" onClick={() => this.ixo_set(tbo) }>{ utils.toPris(tbo.m0pris) } kr</div>
                    </div>
                    )}
                </div>
                <div className="flex-row flex-center" >
                    { sbo.cfg.xnoscroll == "1" ?
                        <div className="divider_grey"></div>
                        :
                        <div className="txt_12" onClick={() => this.dxa_toggle() }>VISA / DÖLJ</div>
                    }
                </div>
            </div>
            :  null }

                { this.get_md("T").length ?
                    <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Lägg till tillbehör { this.state.gid == "008" ? <span>(1 gratis)</span>:null}</div>
                    </div>
                    <div className="flex-col pxrow" style={ this.txa_box() }>
                        { this.get_md("T").map((tbo, key) =>
                            <div key={key} className="flex-row pxmrow" style={ this.txa_row_style(tbo) }>
                                <FontAwesomeIcon icon={ this.txa_icon(tbo) } size="lg" style={ this.txa_style(tbo) } onClick={() => this.txo_set(tbo) } />
                                <div className="mp-btn hide" onClick={() => this.tb_add(tbo) }>XX</div>
                                <div className="txt_16 px-3" onClick={() => this.txo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                                <div className="flex" onClick={() => this.txo_set(tbo) }></div>
                                <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                    <FontAwesomeIcon icon="circle-minus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_sub(tbo, "T") } />
                                    <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                    <FontAwesomeIcon icon="circle-plus"  style={ this.txa_style(tbo) } onClick={() => this.tbo_add(tbo, "T") } />
                                </div>
                                <div className="txt_16 w100 text-right" onClick={() => this.txo_set(tbo) }>{ this.txa_price(tbo) } kr</div>
                            </div>
                        )}
                    </div>
                    <div className="flex-row flex-center" >
                        { sbo.cfg.xnoscroll == "1" ?
                        <div className="divider_grey"></div>
                        :
                        <div className="txt_12" onClick={() => this.dxa_toggle() }>VISA / DÖLJ</div>
                        }
                    </div>
                </div>
                    :  null }

                { this.get_md("S").length ?
                <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Lägg till sideorder</div>
                    </div>
                    <div className="flex-col pxrow" style={ this.sxa_box() }>
                        { this.get_md("S").map((tbo, key) =>
                            <div key={key} className="flex-row pxmrow" style={ this.sxa_row_style(tbo) }>
                                <FontAwesomeIcon icon={ this.sxa_icon(tbo) } size="lg" style={ this.sxa_style(tbo) } onClick={() => this.sxo_set(tbo) } />
                                <div className="mp-btn hide" onClick={() => this.tb_add(tbo) }>XX</div>
                                <div className="txt_16 px-3" onClick={() => this.sxo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                                <div className="flex" onClick={() => this.sxo_set(tbo) }></div>
                                <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                    <FontAwesomeIcon icon="circle-minus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "S") } />
                                    <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                    <FontAwesomeIcon icon="circle-plus" style={ this.sxa_style(tbo) } onClick={() => this.tbo_add(tbo, "S") } />
                                </div>
                                <div className="txt_16 w100 text-right" onClick={() => this.sxo_set(tbo) }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                    </div>
                    <div className="flex-row flex-center" >
                        { sbo.cfg.xnoscroll == "1" ?
                        <div className="divider_grey"></div>
                        :
                        <div className="txt_12" onClick={() => this.dxa_toggle() }>VISA / DÖLJ</div>
                        }
                    </div>
                </div>
                :  null }

                { this.get_md("D").length ?
                <div className="mpg">
                    <div className="flex-row">
                        <div className="txt_b16 text-black">Lägg till dryck</div>
                    </div>
                    <div className="flex-col pxrow" style={ this.dxa_box() }>
                        { this.get_md("D").map((tbo, key) =>
                            <div key={key} className="flex-row pxmrow" style={ this.dxa_row_style(tbo) }>
                                <FontAwesomeIcon icon={ this.dxa_icon(tbo) } size="lg" style={ this.dxa_style(tbo) } onClick={() => this.dxo_set(tbo) } />
                                <div className="mp-btn hide" onClick={() => this.tb_add(tbo) }>XX</div>
                                <div className="txt_16 px-3" onClick={() => this.dxo_set(tbo) }>{ utils.toLang(tbo.m0text) }</div>
                                <div className="flex" onClick={() => this.dxo_set(tbo) }></div>
                                <div className="flex-row px-2" style={ this.nrof_style(tbo) }>
                                    <FontAwesomeIcon icon="circle-minus" style={ this.dxa_style(tbo) } onClick={() => this.tbo_sub(tbo, "D") } />
                                    <div className="txt_16 px-1">{ utils.toNum( tbo.m0nrof ) }</div>
                                    <FontAwesomeIcon icon="circle-plus" style={ this.dxa_style(tbo) } onClick={() => this.tbo_add(tbo, "D") } />
                                </div>
                                <div className="txt_16 w100 text-right" onClick={() => this.dxo_set(tbo) }>{ utils.toPris(tbo.m0pris) } kr</div>
                            </div>
                        )}
                    </div>
                    <div className="flex-row flex-center" >
                        { sbo.cfg.xnoscroll == "1" ?
                        <div className="divider_grey"></div>
                        :
                        <div className="txt_12" onClick={() => this.dxa_toggle() }>VISA / DÖLJ</div>
                        }
                    </div>
                </div>
                :  null }

            </div>
        </div>


        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-col py-3 px-3 hide">
                        <span className="header">Meddelande (Valfritt)</span>
                        <textarea className="text-left" maxLength="30" rows="5" value={this.state.msg} onChange={(e) => this.val_anm_save("msg", e)}></textarea>
            </div>
            <div className="flex-row">
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_sub() }>-</div>
                    <input className="web-inp-21 w75" value={ this.state.nrof } onChange={(e) => this.prd_nrof(e) } />
                    <div className="web-hw-11 flex txt_32" onClick={() => this.prd_add() }>+</div>
            </div>
            <div className="divider_orange"></div>

            <div className="flex-row py-2">
                <div className="txt_b18">Summa</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( this.state.pro.belopp ) } kr</div>
           </div>
            <div className="flex-row">
                <div className="mfull-btn flex-cc" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="mfull-btn flex-cc" onClick={() => this.cart_add() }>LÄGG I VARUKORG</div>
            </div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default observer(Plu_Open);