import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Pos_Home from './home.js';
import Eko_Day from './eko/day.js';
import Pos_Eko from './eko.js';
import Pos_Th from './th/th.js';
import Pos_Msg from './msg.js';

import Dd_Sel from "../lib/ui/dd_sel";
import HkaRr from "../hka/rr/rr";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Sale extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.stat = [];
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;
        this.state.sdo = {};

        //this.state.sdo = context;
        //context.posrend = this;

        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.op = "home";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.mnuitems = [
            {fkn: 'refresh', text: 'UPPDATERA'},
            {fkn: 'user_ses', text: 'MIN SESSION'},
            {fkn: 'logout', text: 'LOGGA UT'}
        ];
    }
    componentWillUnmount(){
        //this.setState({info: "UUU" });
    }
    componentDidMount(){
        this.init_sale();
    }
    init_sale() {
        var mnus = ["day", "eko", "th", "menu"];
        var path = window.location.pathname;
        //var op = path.split("/").pop();
        var op = "home";
        var pa = path.split("/");
        if(pa.length > 1) op = pa[2];
        if( !mnus.includes(op) ) op = "home";

        this.setState({op: op});
    }

    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "web-top";
		if(this.state.top) {
			//cls = cls + " web-head_1";
		}
		//else cls = cls +  " web-header";

		return cls;
    }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({ sdo: sdo })
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.history.push("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        //css.height = window.innerHeight;
        //css['minHeight'] = window.innerHeight;
		return css;
    }
    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }
    row_css(row) {
        var css = {};
        if(this.state.row) {
            if(this.state.row.ank == row.ank) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    row_icon_css(id) {
  	    var css = {};
        css.paddingTop = "5px";
        css.fontSize = "20px";
        if(id == this.state.op) {
            css.color = "#fff";
        }
		return css;
    }

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
   };
    logout() {
        this.props.history.push("/");
   };
  mnu_style(id) {
      var css = {};
      if(id == this.state.op) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
  }
  sel_fkn(sel) {
        //alert(JSON.stringify(sel.fkn));
      if(sel.fkn == "logout") {
        var url = "/logout";
        this.props.history.push(url);
      }
      if(sel.fkn == "refresh") {
            window.location.reload(true);
      }
  }

    render() {

        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">
            <div className={ this.header_class() }>
            <div className="flex-row px-2">
                <div className="web-mnu-bars flex-col flex-center" style={{padding: "0px 2px"}}>
                    <FontAwesomeIcon icon="bars" size="lg" color="white" />
                </div>
                <div className="flex-row txt_18" style={{lineHeight: "40px"}}>
                    SVERIGEKASSAN
                </div>
                <div className="flex"></div>
                <div className="">
                    <Dd_Sel items={ this.state.mnuitems } cls="web-top-icon" getValue={(e)=> this.sel_fkn(e)} />
                </div>
            </div>
            </div>

            <div className="flex-col flex">

                <Routes>
                    <Route path="day" element={ <Eko_Day store={this.webStore} />} />
                    <Route path="eko" element={ <Pos_Eko store={this.webStore} />} />
                    <Route path="th" element={ <Pos_Th store={this.webStore} />} />
                    <Route path="menu" element={ <Pos_Msg store={this.webStore} />} />
                    <Route path="*" element={ <Pos_Home store={this.webStore} />} />
                </Routes>

            </div>

             <div className="web-foot">
             <div className="flex flex-row">
                <div className="foot-btn flex flex-column" style={ this.mnu_style("home") } onClick={() => this.fknGo("/pos/")}>
                    <FontAwesomeIcon icon="home" size="lg" color="grey" style={ this.row_icon_css("home") } />
                    <div style={{fontSize: "12px"}}>HEM</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("day") } onClick={() => this.fknGo("/pos/day")}>
                    <FontAwesomeIcon icon="bed" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>DAG</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("eko") } onClick={() => this.fknGo("/pos/eko")}>
                    <FontAwesomeIcon icon="users" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>EKONOMI</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("th") } onClick={() => this.fknGo("/pos/th")}>
                    <FontAwesomeIcon icon="broom" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>TIDER</div>
                </div>
                <div className="foot-btn flex flex-column" style={ this.mnu_style("menu") } onClick={() => this.fknGo("/pos/menu")}>
                    <FontAwesomeIcon icon="bars" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>MENY</div>
                </div>
            </div>
            </div>
         </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Sale;
