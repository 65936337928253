import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import { configure } from "mobx"

//import logo from './logo.svg';
import './css/mws.css';

import WebHome from './web/home.js';
import WebShop from './shop/shop.js';
import WebOrder from './order/order.js';

import WebMcom from './mcom/mcom.js';
import WebMcs from './mcs/mcs.js';

import WebHka from './hka/hka.js';
import WebPos from './sale/sale.js';
import WebEcom from './ecom/ecom.js';
import WebSwish from './ecom/swish.js';

import Store, { StoreProvider } from "./data/sdo";
import WebStore from "./data/ado";

const webStore = new WebStore();
const store = new Store();

function MsaApp () {
    //var sdo = useContext(SDO);
    configure({
        enforceActions: "never",
    })

    const[txt, setTxt] = useState("SS00");

    var sdo = {};
    sdo.fid = "11";
    sdo.text = txt;
    sdo.setText = setTxt;
    //alert(JSON.stringify(sdo));
    var xdo = sessionStorage.getItem("svp");
    //alert(xdo);

    if(!xdo) {
        webStore.seo = {};
        webStore.seo.loggedin = false;
        var html =
        <BrowserRouter forceRefresh={true}>
            <Routes>
                <Route path="/mcs/*" element={ <WebMcs store={webStore} />} />
                <Route path="/order/*" element={ <WebOrder store={webStore} />} />
                <Route path="/swish/*" element={ <WebSwish store={webStore} />} />
                <Route path="/hka/*" element={ <WebHka store={webStore} />} />
                <Route path="/:id/*" element={ <WebMcom store={webStore} />} />
                <Route path="*" forceRefresh={true} element={ <WebHome store={webStore} />} />
            </Routes>
        </BrowserRouter>
        return html;
    }

    if(xdo) {
        var seo = JSON.parse(xdo);
        webStore.seo = seo;
        //return routefkn(sdo);
    }

    var pos = {};
    webStore.pos = pos;
    var ta = {};
    webStore.ta = ta;

    var html =
    <StoreProvider store={store}>
    <BrowserRouter forceRefresh={false}>
        <Routes>
            <Route path="/mcs/*" element={ <WebMcs store={webStore} />} />
            <Route path="/order/*" element={ <WebOrder store={webStore} />} />
            <Route path="/ecom/*" element={ <WebEcom store={webStore} />} />
            <Route path="/swish/*" element={ <WebSwish store={webStore} />} />

            <Route path="/shop/*" element={ <WebShop store={webStore} />} />
            <Route path="/hka/*" element={ <WebHka store={webStore} />} />
            <Route path="/pos/*" element={ <WebPos store={webStore} />} />
            <Route path="/std/*" element={ <WebPos store={webStore} />} />
            <Route path="/:id/*" element={ <WebMcom store={webStore} />} />
            <Route path="*" forceRefresh={true} element={ <WebHome store={webStore} />} />
        </Routes>
    </BrowserRouter>
    </StoreProvider>
    return html;
}

export default MsaApp;
