import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import {Route, Routes} from "react-router-dom";

//import './css/animate.css';
//import './css/carousel.css';

import HkaHome from './home.js';
import HkaRr from './rr/rr.js';
import HkaGst from './gst/gst.js';
import HkaStad from './stad/stad.js';
import HkaMenu from './menu.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import PdfDlg from "./dlg/pdf_dlg";
//import LoginDlg from "./dlg/login_dlg";

class Hka extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;
        this.state.sdo = {};
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        //this.cdi_step();
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
   ham_mnu_css() {
  	    var css = {};
		if(this.state.top) {
			css.display = "flex";
			css.display = "none";
		}
		else {
			css.display = "none";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    sdo_upd() {
        var sdo = this.state.sdo;
        if(!sdo.fid) sdo.fid = "0";
        sdo.fid = ((sdo.fid * 1) + 1).toString();
        this.setState({sdo: sdo})
        sessionStorage.setItem("svp", JSON.stringify(this.state.sdo));
        //this.props.history.push("cas");
    }
    sdo_ctx() {
        sessionStorage.removeItem('svp');
        //sessionStorage.clear();
    }
    sdo_cas() {
        this.context.fid = "45";
        this.props.history.push("cas");
    }
    ct_show(id) {
        //alert(id);
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
        //const tesNode = ReactDOM.findDOMNode(id);
        //window.scrollTo(0, tesNode.offsetTop);
    }
    w00_css() {
  	    var css = {};
        //css.height = window.innerHeight;
        //css['minHeight'] = window.innerHeight;
		return css;
    }
    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }
    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    login() {
        this.setState({login: true });
        //this.props.history.push("pos");
    }
    row_icon_css() {
  	    var css = {};
        css.paddingTop = "5px";
        css.fontSize = "20px";
		return css;
    }

    fknGo(url) {
        this.props.history.push(url);
   };
    logout() {
        this.props.history.push("/");
   };
  mnu_style(id, op) {
      var css = {};
      if(id == op) {
          css.background = "#080";
          css.color = "#fff";
      }
      return css;
  }

    render() {
      var mnus = ["rr", "gst", "stad", "menu"];
      var path = window.location.pathname;
      var op = path.split("/").pop();
      if( !mnus.includes(op) ) op = "home";

        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="web-app">
            <div id="header" className={ this.header_class() }>
            <div className="web-top-content d-flex flex-row">
                <div className="web-mnu-bars hide">
                    <i className="fa fa-bars flex" aria-hidden="true" style={{padding: "10px"}}></i>
                </div>
                <div className="flex-row txt_18">
                    Housekeeping
                </div>
                <div className="flex-fill">
                </div>
                <div className="">
                </div>
            </div>
            </div>


            <div className="web-body" id="web-body">

                <Routes>
                    <Route path="rr" element={ <HkaRr store={this.webStore} />} />
                    <Route path="gst" element={ <HkaGst store={this.webStore} />} />
                    <Route path="stad" element={ <HkaStad store={this.webStore} />} />
                    <Route path="menu" element={ <HkaMenu store={this.webStore} />} />
                    <Route path="*" element={ <HkaHome store={this.webStore} />} />
                </Routes>

            </div>

             <div id="m99" className="web-foot">
             <div className="d-flex flex-row">
                <div className="foot-btn d-flex flex-column ripple" style={ this.mnu_style("home", op) } onClick={() => this.fknGo("/hka/")}>
                    <FontAwesomeIcon icon="home" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>HEM</div>
                </div>
                <div className="foot-btn d-flex flex-column ripple" style={ this.mnu_style("rr", op) } onClick={() => this.fknGo("/hka/rr")}>
                    <FontAwesomeIcon icon="bed" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>RUM</div>
                </div>
                <div className="foot-btn d-flex flex-column ripple" style={ this.mnu_style("gst", op) } onClick={() => this.fknGo("/hka/gst")}>
                    <FontAwesomeIcon icon="users" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>GÄSTER</div>
                </div>
                <div className="foot-btn d-flex flex-column ripple" style={ this.mnu_style("stad", op) } onClick={() => this.fknGo("/hka/stad")}>
                    <FontAwesomeIcon icon="broom" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>STÄD</div>
                </div>
                <div className="foot-btn d-flex flex-column ripple" style={ this.mnu_style("menu", op) } onClick={() => this.fknGo("/hka/menu")}>
                    <FontAwesomeIcon icon="bars" size="lg" color="grey" style={ this.row_icon_css() } />
                    <div style={{fontSize: "12px"}}>MENY</div>
                </div>
            </div>
            </div>
         </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Hka;
