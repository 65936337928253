import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "../../lib/react/withRouter";
import moment from "moment";
import axios from "axios";

import './css/flow.css';
import './css/shop.css';
import './css/mdlg.css';

import {faCartShoppingFast} from "@fortawesome/pro-regular-svg-icons";

import Tiden from "../../lib/ui/tiden";
import * as utils from "../../assets/utils";
import sbo from "../sbo";

import Ta_Sale from "./sale/sale";
import Ta_Pay from "./pay/pay";
import Ta_Order from "./order/order";
import Ta_Deliver from "./order/deliver";
import Ta_Cart from "./cart/cart";

import Ta_NoTable from "./land/notable";
import Ta_Closed from "./land/closed";
import Ta_NoAuth from "./land/noauth";
import Ta_NoSwish from "./land/noswish";
import Ta_Bye from "./land/bye";

import Ta_Home from "./home";

import Ta_About from "./content/about";
import Ta_Contact from "./content/contact";

class Ta extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.infotxt = "";
      this.state.wnds = {};
      this.state.mwnd = 0;

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }

  componentDidMount() {
         //var infotxt = "Bord:" + sbo.cfg.bord;
        //this.setState({ infotxt: infotxt });
        //this.get_cfg(sbo.cfg.uid);
        //this.env_init();
        //alert(JSON.stringify(sbo.cart));
        sbo.cfg.bord = "9999";
        sbo.cart.bord = sbo.cfg.bord;
        //alert(JSON.stringify(sbo.cfg.bord));
    }

    env_init() {
        var env = sessionStorage.getItem("sbo");
        sbo.cfg = env.cfg;
        sbo.unit = env.unit;
        sbo.cdo = env.cdo;
        sbo.cart = env.cart;
    }

    queryStringToJSON(queryString) {
        if(queryString.indexOf('?') > -1){
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }

    validate_unit() {
        var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
        if(params) {
            if(params.bord) sbo.cfg.bord = params.bord;
        }
    
        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            if(bord == "SS") {
                //sbo.cfg.ot = 9;
            }
            else {
                sbo.cfg.bord = bord;
                //sbo.cfg.ot = 1;
            }
        }
        
        sbo.cart.bord = sbo.cfg.bord;
    
        if(sbo.cfg.hours) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            if(hhmm < "0600") {
                wday = wday - 1;
                if(wday == 0) wday = 7;
            }
            //alert(JSON.stringify(wday));
    
            if((sbo.cfg.hours.wday) && (sbo.cfg.hours.wday[wday])) {
                var wo = sbo.cfg.hours.wday[wday];
                ftid = wo.ftid;
                ttid = wo.ttid;
            }
    
            if(hhmm < "0600") {
                if(ftid < "0600") {
                    if(hhmm < ftid) {
                        sbo.cfg.closed = 1;
                    }
                }
            }
            else {
                if(hhmm < ftid) {
                    sbo.cfg.closed = 1;
                }
            }
    
            if(hhmm > ttid) {
                sbo.cfg.closed = 1;
            }
            sbo.cfg.ftid = ftid;
            sbo.cfg.ttid = ttid;
        }
    
        if(sbo.cfg.campdts) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var dts = ts.format("YYMMDD");
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            //alert(JSON.stringify(wday));
            if(sbo.cfg.campdts[dts]) {
                var wo = sbo.cfg.campdts[dts];
                var ftid = wo.ftid;
                var ttid = wo.ttid;
                if((hhmm >= ftid) && (hhmm <= ttid)) {
                    sbo.cfg.campaign = wo.campaign;
                }
            }
        }
        
        if(sbo.cfg.closed == 1) {
            var self = this;
            //var url = "/" + sbo.cfg.uid + "/flow/closed";
            var url = "/flow/closed";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }
    
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    
    no_auth() {
        var self = this;
        var url = "/flow/noauth";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
    }

    get_cfg(uid) {
        var self = this;

        sbo.cfg = {};
        sbo.cfg.text = "INAKTIV";

        //var token = this.state.token;
        var req = {};
        req.fkn = "MDB";
        req.uid = uid;
        //alert(JSON.stringify(req));

        //axios.post("/mdb", req)
        //axios.post("http://127.0.0.1:9690/mdb", req)
        axios.post("https:/shop.svepos.se/mdb", req)
            //axios.post("/gsid", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res.data));
                var sdo = res.data;
                if(sdo.ok == "000") {
                    sbo.cfg = sdo.rco;
                    //self.setState({ valid: 1 });
                    self.validate_unit();
                }
                else {
                    self.no_auth();
                }
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
                self.no_auth();
            });
        //this.setState({ phase: 1, pau: {} });
    }

    topr_class() {
        var cls = "";
        cls = "fmv0";
        if(this.state.top == 0) {
            cls = cls + " fmv1";
        }
        if(window.scrollY > 100) {
            //cls = cls + " fmv1";
        }
        return cls;
    }

    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }
    topr_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        if(this.state.top == 0) {
            //css.display = "none";
        }
        css.display = "none";
        return css;
    }

    foot_icon_style() {
        var css = {};
        css.color = "#fff";
        return css;
    }
    
  fknGo(url) {
    //props.history.push(row.url);
    this.props.navigate(url);
   };

    cart_open() {
        if(false) {
            var url = "delivery";
            this.props.navigate(url);
            return;
        }
        var self = this;
        var wnds = {};
        wnds.cartopen = true;
        wnds.cb = self.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            ctx.setState({ wnds: {} });
            var url = "pay";
            //var url = "delivery";
            ctx.props.navigate(url);
            return;
        }
        ctx.setState({ wnds: {} });
    }

    mobile_mopen() {
        var mwnd = this.state.mwnd;
        if(mwnd == 0) mwnd = 1;
        else mwnd = 0;
        this.setState({ mwnd: mwnd });
    }
    mnu_open(fkn) {
        //alert(JSON.stringify(this.props));
        this.setState({ mwnd: 0 });
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/" + fkn;
        this.props.navigate(url);
    }
    bg_full_style() {
        var css = {};
        //css.height = window.innerHeight;
        css.overflow = "auto";
        return css;
    }
    mobile_wnd_style() {
        var css = {};
        css.top = 44;
        css.height = window.innerHeight - 44;
        //css.overflow = "auto";
        if (this.state.top == 1) {
            css.top = 94;
            css.height = window.innerHeight - 94;
        }
        if (this.state.mwnd == 0) css.display = "none";
        return css;
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 80) {
            this.setState({top: 0})
            this.setState({info: 'small'})
        } else {
            if (body.scrollTop < 1) {
                this.setState({top: 1})
                this.setState({info: 'big:'+body.scrollTop})
            }
        }

        if (false) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;
        }
    }

    get_badge() {
        var nrr = 0;
        if(sbo.cart.rows) nrr = nrr + sbo.cart.rows.length;
        if(sbo.cart.pro) nrr = nrr + Object.keys(sbo.cart.pro).length;
        return nrr;
    }
    cart_btn() {
        var css = {};
            if(sbo.cfg.nocart == 1) css.display = "none";
        return css;
    }

    flow_home() {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
    }
    flow_logo() {
        var logo = null;

        //var url = "../../img/";
        //url = url + sbo.cfg.logo;
        //logo = require(url);
        //logo = require("../../img/" + sbo.cfg.logo);
        //logo = require("https://fx.svepos.se/img/logo/" + sbo.cfg.logo);
        logo = "https://fx.svepos.se/img/logo/" + sbo.cfg.logo;
        //alert(logo);
        return logo;
    }
    logo_css() {
        var css = {};
        css.width = "75%";
        css.maxHeight = "55px";
        if(sbo.cfg.logo_width) css.width = sbo.cfg.logo_width;
        return css;
    }

  render() {

    return (
        <div className="web-app">
        <div className="web-top mcom-top" style={ this.top_style() }>
            <div className="wp">
                <div className={ this.topr_class() } style={ this.topr_style() }>
                    <div className="text-center text-white">Våra öppettider är mån-fre 11.30 - 21.00 & lör-sön 12.00 - 21.00 (Ni kan alltid lägga er order i förväg)</div>
                    <div className="flex-row py-3"></div>
                </div>
                <div className="flex-row mcom-mmenu">
                    <div className=""><img className="cursor-pointer" style={ this.logo_css() } src={ this.flow_logo() } onClick={() => this.flow_home() } /></div>
                    <div className="flex text-left txt_16 px-3">TAKE AWAY</div>
                    <ul className="foodle-horiz-ul px-2">
                        <li className="py-1 cursor-pointer" onClick={() => this.mnu_open("kitchens")}>Meny</li>
                        <li className="py-1 cursor-pointer" onClick={() => this.mnu_open("about")}>Om</li>
                        <li className="py-1 cursor-pointer" onClick={() => this.mnu_open("contact")}>Kontakt</li>
                    </ul>

                    <div className="cart-btn flex-row" onClick={() => this.cart_open() }>
                        <div className="flex"></div>
                        <div className="flex-row text-center">
                            <div className="txt_b16 px-1">{ utils.toPris(sbo.cart.belopp) } kr</div>
                            <FontAwesomeIcon className="cart-btn-icon" icon="briefcase" size="1x" color="black" />
                        </div>
                        <div className="flex"></div>
                        <span className="cart-badge">{ this.get_badge() }</span>
                    </div>
                </div>
                <div className="flex-row mcom-mobile">
                    <div className=""><img className="cursor-pointer" style={ this.logo_css() } src={ this.flow_logo() } onClick={() => this.flow_home() } /></div>
                    <div className="flex"></div>
                    <div className="flex txt_18 px-3">TAKE AWAY</div>
                    <div className="flex"></div>
                    <FontAwesomeIcon className="" icon="bars" size="2x" onClick={() => this.mobile_mopen() } />
                </div>
            </div>
        </div>

            <div className="mcom-mobile-mwnd" style={ this.mobile_wnd_style() }>
                <div className="foodle-mobile-btn" onClick={() => this.mobile_mopen() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

                <div className="flex-col flex" style={{ height: "100%", fontSize: "24px", padding: "50px 0px", position: "relative" }}>

                    <div className="flex-row flex-center py-3 hide">
                        <div className="cursor-pointer" onClick={() => this.mnu_open("")}>Hem</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="cursor-pointer" onClick={() => this.mnu_open("kitchens")}>Meny</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="cursor-pointer" onClick={() => this.mnu_open("about")}>Om</div>
                    </div>
                    <div className="flex-row flex-center py-3">
                        <div className="cursor-pointer" onClick={() => this.mnu_open("contact")}>Kontakt</div>
                    </div>

                </div>

            </div>
            <div className="fab-btn fab-btn_shadow" style={ this.cart_btn() } onClick={() => this.cart_open() }>
                <FontAwesomeIcon icon={faCartShoppingFast} size="1x" />
                <span className="cart-badge">{ this.get_badge() }</span>
            </div>

        <div className="web-body flex" id="web-body" style={ this.bg_full_style() } onScroll={ (event) => this.onScroll(event) }>
        <Routes>
            <Route path="about" element={ <Ta_About store={this.store} />} />
            <Route path="contact" element={ <Ta_Contact store={this.store} />} />
            <Route path="sale/*" element={ <Ta_Sale store={this.store} />} />
            <Route path="pay/*" element={ <Ta_Pay store={this.store} />} />
            <Route path="order/*" element={ <Ta_Order store={this.store} />} />
            <Route path="deliver" element={ <Ta_Deliver store={this.store} />} />
            
            <Route path="notable" element={ <Ta_NoTable store={this.store} />} />
            <Route path="closed" element={ <Ta_Closed store={this.store} />} />
            <Route path="noauth" element={ <Ta_NoAuth store={this.store} />} />
            <Route path="noswish" element={ <Ta_NoSwish store={this.store} />} />
            <Route path="bye" element={ <Ta_Bye store={this.store} />} />

            <Route path="*" element={ <Ta_Home store={this.store} />} />
        </Routes>
        </div>


        { this.state.wnds.cartopen ? <Ta_Cart wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
  </div>
  );
}
}
export default withRouter(observer(Ta));
