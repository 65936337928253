import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import sbo from "../sbo";
import Wq_Belopp from "./wq_belopp";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Wq_tips extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.total = "";
        this.state.tips = "";
        this.state.proc = "";
        this.state.val = "";

        this.state.wnds = {};
        this.state.msg = {};
        this.state.user = {};
        this.state.sdo = {};

        if(sbo.cart.belopp) this.state.total = sbo.cart.belopp.toString();

        this.state.step = 1;
        this.state.mna = [];
    }

    componentWillUnmount(){
    }
    componentDidMount(){
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    nr_cl() {
        var val = this.state.val;
        var len = val.length;
        if(len > 0) {
            val = val.substring(0, len - 1);
            this.setState({ val: val });
        }
    };
    tips_set(proc) {
        var total = this.state.total;
        var tips = Math.round((total * proc) / 100);
        this.setState({ tips: tips, proc: proc });
    };
    prd_nrof(e) {
        var nrof = e.target.value;
        this.setState({ nrof: nrof });
    };
    prd_sub() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) - 1;
        if(nrof < 0) nrof = 0;

        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);

        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };
    prd_add() {
        var nrof = this.state.nrof;
        nrof = (nrof * 1) + 1;
        var sum = this.state.sum;
        sum = nrof * (this.state.dish.pbo.plu00.p0pris * 1);
        nrof = nrof.toString();
        this.setState({ nrof: nrof, sum: sum });
    };

    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.history.push(url);
    };
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    wnd_done() {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            rsp.tips = this.state.tips;
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    };
    tips_show(proc){
        var tips = 0;
        var belopp = this.state.total;
        tips = Math.round( (belopp * (proc * 1)) / 100 );
        tips = utils.toPris(tips) + " SEK";
        return tips;
    }
    tips_free() {
        var self = this;
        var wnds = {};
        wnds.wqbelopp = true;
        wnds.cb = this.cb_free;
        this.setState({wnds: wnds});
    }
    cb_free(ctx, rsp) {
        if(rsp.ok == "000") {
            //alert(JSON.stringify(rsp.val));
            var tips = rsp.val;
            if(tips.indexOf(".") == -1) tips = tips + "00";
            tips = tips.replace(".", "");
            ctx.setState({tips: tips, wnds: {}});
        }
        else ctx.setState({wnds: {}});
    }

    render() {

        return (
    <div className="mx_base">
        { this.props.backdrop ? <div className="m2_backdrop" /> : null }
        <div className="m2_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>TIPS</div>
            </div>
        <div className="mx_content flex">

            <div className="flex-col flex px-5">
                <div className="flex-cc txt_22">TOTALT: { utils.toPris(this.state.total) }</div>
                <div className="flex-cc txt_32">TIPS: { utils.toPris(this.state.tips) }</div>
                <div className="divider_green"></div>
                <div className="flex"></div>
                <div className="flex-cc txt_18">TIPS VAL</div>
                <div className="flex-row">
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("5") }>
                        <div className="txt_30">5%</div>
                        <div className="txt_22">{ this.tips_show("5") }</div>
                    </div>
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("10") }>
                        <div className="txt_30">10%</div>
                        <div className="txt_22">{ this.tips_show("10") }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("15") }>
                        <div className="txt_30">15%</div>
                        <div className="txt_22">{ this.tips_show("15") }</div>
                    </div>
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("20") }>
                        <div className="txt_30">20%</div>
                        <div className="txt_22">{ this.tips_show("20") }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("25") }>
                        <div className="txt_30">25%</div>
                        <div className="txt_22">{ this.tips_show("25") }</div>
                    </div>
                    <div className="web-hw-31 flex flex-col" onClick={() => this.tips_set("30") }>
                        <div className="txt_30">30%</div>
                        <div className="txt_22">{ this.tips_show("30") }</div>
                    </div>
                </div>
                <div className="flex-row">
                    <div className="web-hw-31 flex txt_30" onClick={() => this.tips_free() }>VÄLJ BELOPP</div>
                </div>
                <div className="flex"></div>
            </div>
            <div className="divider_green"></div>
            <div className="flex-row">
                <div className="txt_18 web-hw-21 flex" onClick={() => this.wnd_close() }>AVBRYT</div>
                <div className="txt_18 web-hw-21 web-hw-green flex" onClick={() => this.wnd_done() }>KLAR</div>
            </div>

        </div>
    </div>
        { this.state.wnds.wqbelopp ? <Wq_Belopp wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
        );
    }
}
export default observer(Wq_tips);