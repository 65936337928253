import React, { useState, useEffect, useContext } from 'react';

import * as net from "../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Home extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};

        this.state.step = 1;
    }
    componentDidMount(){
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }

   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        this.props.history.push(url);
   };

    render() {

        return (
<div className="web-app">

    <div id="header" className={ this.header_class() }>
        <div className="web-top-content d-flex flex-row">
            <div className="hide px-3 grey-text" onClick={() => this.mnu_show() }>
                <FontAwesomeIcon icon="bars" size="lg" color="grey"/>
            </div>
            <h5 className="px-2 py-2">SVERIGEKASSAN</h5>
            <div className="flex-fill"></div>
            <div className="px-3 grey-text" onClick={() => this.usr_info() }>
                <FontAwesomeIcon icon="user" size="lg" color="grey"/>
            </div>
        </div>
    </div>

    <div className="web-body d-flex flex-column">
        <div className="flex d-flex justify-content-center">
            <img className="cdi-logo" src={require('./img/logo-sk.png')} />
        </div>

        <div className="d-flex flex-column align-items-center justify-content-center">
        </div>

        <div className="flex d-flex justify-content-center">
        </div>
    </div>
</div>
        );
    }
}
export default Home;