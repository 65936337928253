import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import {withRouter} from "../../../lib/react/withRouter";

import * as srs from "../../../lib/srs";
import sbo from "../../sbo";
import * as utils from "../../../assets/utils";

import Shop_Scan from "../fkn/scan";
import Shop_Confirm from "./confirm";
import Shop_Swish from "./swish";
import Shop_Swish_SS from "./swish_ss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Wq_Tips from "./wq_tips";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Shop_Checkout extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.rabkod = "";
        this.state.msg = "";

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
        this.setState({info: "UUU" });
    }
    componentDidMount(){
    }

    main_css() {
        var css = {};
        return css;
    }

    header_css() {
            var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
        var url = "/";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
        if(sbo.cfg.vurl) url = sbo.cfg.vurl;
        this.fknGo(url);
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    cart_supr() {
        var suprab = 0;
        if(sbo.cart.suprab) suprab = sbo.cart.suprab.cdipris();
        return suprab;
    };
    cart_belopp() {
        var belopp = 0;
        var suprab = 0;
        if(sbo.cart.belopp) belopp = sbo.cart.belopp;
        if(sbo.cart.suprab) suprab = sbo.cart.suprab;
        belopp = belopp - suprab;
        belopp = belopp.cdipris();
        return belopp;
    };
    cart_rows() {
        var rows = [];
        if(sbo.cart.rows) rows = sbo.cart.rows;
        return rows;
    };
    cart_row_del(row) {
        if(sbo.cart.rows) {
            //sbo.cart.rows = sbo.cart.rows.filter(function(item) {
            //    return item !== row
            //})
            sbo.cart.rows = sbo.cart.rows.filter(item => item !== row);
            var belopp = 0;
            var suprab = 0;
            for(var o of sbo.cart.rows) {
                belopp = belopp + ( o.price * 1 );
                var plu = o.plu * 1;
                if((plu < 2090) || (plu > 6000)) {
                    var rabbel = Math.round((o.price * 1) * 0.15);
                    suprab = suprab + rabbel;
                }
            }
            sbo.cart.belopp = belopp;
            sbo.cart.suprab = suprab.toString();
        }
    };
    scan_show() {
        var self = this;
        var wnds = {};
        wnds.scan = true;
        //wnds.row = row;
        this.setState({wnds: wnds});
    }
    confirm_show() {
        var self = this;
        var wnds = {};
        wnds.confirm = true;
        //wnds.row = row;
        wnds.cb = self.cb_confirm;
        this.setState({wnds: wnds});
    }
    cb_confirm(ctx) {
        ctx.setState({ wnds: {} });

        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        //var url = "/shop/"
        //if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
        //ctx.fknGo(url);
        var url = "/";
        //if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v1";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v9";
        //if(sbo.cfg.vurl) url = sbo.cfg.vurl;
        ctx.fknGo(url);
    }
    cart_pay(betkod) {
        var self = this;
        //alert(JSON.stringify(this.state.dish));
        if(!betkod || (betkod.length < 1)) return;

        if(!sbo.cart.rows) {
            sbo.cart.rows = [];
            sbo.cart.belopp = 0;
            var url = "/shop/"
            if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
            this.fknGo(url);
            return;
        }

        /*
        var belopp = 0;
        var suprab = 0;
        if(sbo.cart.belopp) belopp = sbo.cart.belopp;
        if(sbo.cart.suprab) suprab = sbo.cart.suprab;
        sbo.cart.belopp = belopp - suprab;
        //belopp = belopp.cdipris();
        */
        var belopp = 0;
        var suprab = 0;

        for(var o of sbo.cart.rows) {
            belopp = belopp + (( o.pris * 1 ) * ( o.antal * 1 ));
            
            if(sbo.cfg.campaign == "SUP") {
                var plu = o.plu * 1;
                if((plu < 2090) || (plu > 6000)) {
                    var rabbel = Math.round((( o.pris * 1 ) * ( o.antal * 1 )) * 0.15);
                    suprab = suprab + rabbel;
                }
            }
        }
        sbo.cart.belopp = belopp - suprab;
        sbo.cart.suprab = suprab.toString();
   
        //alert(JSON.stringify(sbo.cart.rows));
        if(!sbo.cart.belopp) sbo.cart.belopp = "";
        sbo.cart.slot = "";
        sbo.cart.levtyp = "";
        sbo.cart.notanr = "";
        sbo.cart.bord = "";
        sbo.cdo.fnamn = "";
        sbo.cdo.enamn = "";
        sbo.cdo.tele = "";
        sbo.cdo.email = "";
        sbo.cdo.zip = "";
        sbo.cdo.adress = "";
        //sbo.cdo.msg = "";
        sbo.cart.bord = sbo.cfg.bord;
        
        var msg = this.state.msg;
        sbo.cdo.msg = msg.toString().trim();

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        //prm.sid = sbo.cdo.unit.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        //alert(JSON.stringify(sbo));
        //return;

        srs.sio_req(prm)
        .then(function(ret) {
            if(ret.ok == "000") {
                sbo.cart.orderid = ret.orderid;
                sbo.cart.bord = sbo.cfg.bord;

                //var url = "/shop/payment";
                //self.props.navigate(url);
                if(betkod == "card") self.cart_swish_pay();
                //if(betkod == "swish") self.cart_swish_pay();
                if(betkod == "swish") {
                    if(sbo.cfg.swish == "2") self.cart_swish_pay();
                    else if(sbo.cfg.swish == "5") self.cart_swish_ss();
                    else self.cart_swish_open();
                }
                return;
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            }

        })
        /*
        */
    };

    cart_swish_open() {
        var self = this;
        var wnds = {};
        wnds.swish = true;
        //wnds.row = row;
        wnds.cb = self.cb_swish;
        this.setState({wnds: wnds});
    };
    cart_swish_ss() {
        var self = this;
        var wnds = {};
        wnds.swish_ss = true;
        //wnds.row = row;
        wnds.cb = self.cb_swish_ss;
        this.setState({wnds: wnds});
    };
    cb_swish_ss(ctx, ret) {
        //alert(JSON.stringify(ret));
        if(ret && (ret.ok == "999")) {
            ctx.setState({ wnds: {} });
            return;
        }
        ctx.setState({ wnds: {} });
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        //var url = "/shop/"
        //if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/order?orderid" + sbo.cart.orderid;
        //ctx.fknGo(url);
        ctx.confirm_show();

        /*
        var url = "/";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid;
        //if(sbo.cfg.vurl) url = sbo.cfg.vurl;
        ctx.fknGo(url);
        */
    }
    cb_swish(ctx, ret) {
        //alert(JSON.stringify(ret));
        if(ret && (ret.ok == "999")) {
            ctx.setState({ wnds: {} });
            return;
        }
        ctx.setState({ wnds: {} });
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        var url = "/shop/"
        //if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/order?orderid" + sbo.cart.orderid;
        ctx.fknGo(url);
    }

    xcart_swish_open() {
        var self = this;
        var prm = {};
        prm.req = "vcm.swish.mcom";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        //prm.notanr = "123457E";
        prm.notanr = sbo.cart.orderid;
        prm.topay = sbo.cart.belopp;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                var token = ret.token;
                //var cburl = "merchant%253A%252F%252F";
                var cburl = "https://shop.svepos.se/" + sbo.cfg.uid;
                var url = "swish://paymentrequest?token=" + token + "&callbackurl=" + cburl;
                //alert(JSON.stringify(url));
                window.location = url;

                var prm = {};
                prm.req = "vcm.swish.mwait";
                prm.sid = sbo.cfg.sid;
                //prm.token = ret.token;
                prm.token = sbo.cart.orderid.ztrim();
                return srs.cb_req(prm);
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa Swish.", errormsg: "Vänligen försök igen" });
                throw "999 Error";
            }
        })
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                self.cart_swish_pay();
            }
            else {
                self.setState({ info: "", error: "Swish misslyckades", errormsg: "Vänligen försök igen" });
            }
        })
        .catch(function(e) {
        })
    };

    cart_swish_pay() {
        var self = this;

        if(!sbo.cart.belopp) sbo.cart.belopp = "";
        sbo.cart.topay = sbo.cart.belopp;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        //prm.sid = sbo.cdo.unit.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_app_pay";
        prm.req = "vcm.ecom.ecom_mcom_pay";
        prm.sid = sbo.cfg.sid;
        prm.sbo = sbo;
        prm.bord = sbo.cfg.bord;
        prm.betkod = "swis";
        prm.bettxt = "SWISH";
        prm.orderid = sbo.cart.orderid;
        prm.tele = "46705916261";

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            if(ret.ok == "000") {
                //sbo.cart.orderid = ret.orderid;
                //sbo.cart.notanr = ret.notanr;
                //var url = "/shop/payment";
                //self.props.navigate(url);
                //self.confirm_show();

                //var cburl = "https://shop.svepos.se/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                //var cburl = "http://127.0.0.1:3000/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                var cburl = "/" + sbo.cfg.uid + "/order?orderid=" + sbo.cart.orderid + "&bord=" + sbo.cfg.bord + "&sid=" + sbo.cfg.sid;
                //alert(JSON.stringify(cburl));
                window.location = cburl;
               return;
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            }

        })
    }
    cart_clear() {
        var self = this;
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        var url = "/shop"
        this.fknGo(url);
    };
    change_rabkod(e) {
        var rabkod = e.target.value;
        this.setState({ rabkod: rabkod });
    };
    co_msg(e) {
        var msg = e.target.value;
        this.setState({ msg: msg });
    };
    tips_sel() {
        var self = this;
        var wnds = {};
        wnds.wqtips = true;
        //wnds.dishrow = row;
        this.setState({wnds: wnds});
    }

    render() {

        return (
<div className="web-app" style={{maxWidth: "768px", margin: "0px auto"}}>

    <div className="web-body flex-col">

        <div className="flex-center">
            <div className="txt_22">CHECKA UT</div>
        </div>
        <div className="divider_orange"></div>
        <div className="flex-center">
            <div className="txt_16">{ sbo.cfg.text }</div>
            <div className="txt_16">Bord: { sbo.cfg.bord }</div>
        </div>
        <div className="divider_blue py-2"></div>
        <div className="flex px-5">
        { this.cart_rows().map((row, key) =>
            <div key={key} className="flex-col txt_18 py-1">
                <div className="flex-row">
                <div className="">{ utils.toLang( row.text ) }</div>
                <div className="flex"></div>
                <div className="px-1" onClick={() => this.cart_row_del( row ) }>
                    <FontAwesomeIcon icon="trash" color="red" />
                </div>
                </div>
                <div className="flex-row">
                    <div className="hide">{ utils.toNum( row.antal ) } * { utils.toPris( row.pris ) }</div>
                    <div className="px-1">{ utils.toNum( row.antal ) } st</div>
                    <div className="flex"></div>
                    <div className="">{ utils.toPris( row.belopp ) }</div>
                </div>
                { row.comment ? <div className="flex-row">
                    <div className="">{ utils.toLang( row.comment ) }</div>
                </div> : null }
                <div className="divider_green"></div>
            </div>
        )}
        { sbo.cfg.sid == "S0003401" ?
        <div className="flex-row txt_22">
            <div className="">Supporter rabatt</div>
            <div className="flex"></div>
            <div className="">{ this.cart_supr() }</div>
        </div>
        : null }
        <div className="flex-row txt_22">
            <div className="">Summa</div>
            <div className="flex"></div>
            <div className="">{ this.cart_belopp() }</div>
        </div>
        </div>

        <div className="flex-col px-5">
        <div className="divider_green"></div>
        <div className="flex-col hide">
            <label>Rabatt kod</label>
            <input className="web-inp-11" value={ this.state.rabkod } onChange={(e) => this.change_rabkod(e) } />
        </div>
        <div className="txt_18 hide" onClick={() => this.tips_sel() }>Meddelande</div>
        <div className="flex-row hide">
            <textarea className="web-comment-11 flex" maxLength="30" rows="6" value={ this.state.msg } onChange={(e) => this.co_msg(e) } ></textarea>
        </div>
        </div>

        <div className="divider_orange"></div>
        <div className="flex-row hide">
            <div className="txt_22 web-btn flex" onClick={() => this.scan_show() }>LÄS QR</div>
            <div className="txt_22 web-btn flex" onClick={() => this.confirm_show() }>KVITTENS</div>
        </div>
        <div className="flex-row hide">
            <div className="txt_22 web-btn flex" onClick={() => this.cart_swish_open() }>SWISH DIREKT</div>
        </div>
        <div className="flex-row hide">
            <div className="txt_22 web-btn-x0 flex" onClick={() => this.cart_pay("") }></div>
            <div className="txt_22 web-btn flex" onClick={() => this.cart_pay("card") }>KORT</div>
        </div>
        <div className="flex-row">
            <div className="txt_22 web-btn-x1 flex" onClick={() => this.wnd_close() }>STÄNG</div>
            <div className="txt_22 web-btn flex" onClick={() => this.cart_pay("swish") }>SWISH</div>
        </div>
        <div className="divider_orange"></div>
        <div className="txt_22 web-btn-01 hide" onClick={() => this.wnd_close() }>STÄNG</div>

    </div>
    { this.state.wnds.swish ? <Shop_Swish wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.swish_ss ? <Shop_Swish_SS wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.confirm ? <Shop_Confirm wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.scan ? <Shop_Scan wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    { this.state.wnds.wqtips ? <Wq_Tips wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
</div>
        );
    }
}
export default withRouter(observer(Shop_Checkout));