import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import { withRouter } from "../../../lib/react/withRouter";
import moment from "moment";

import Tiden from "../../../lib/ui/tiden";
import sbo from '../../sbo'
import * as srs from "../../../lib/srs";
import {format, getHours, subDays} from "date-fns";

class Sale_Kitchens extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

    this.state.today = new Date();
    var hh = getHours(this.state.today);

      this.state.mga = [];

      //var jsonstr = localStorage.getItem("sbo");
      //var env = JSON.parse(jsonstr);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.env_init();

   sbo.cfg.nocart = 0;

   //alert(JSON.stringify(sbo.cfg));
    if((!sbo.cfg.bord) || (sbo.cfg.bord == undefined) || (sbo.cfg.bord.length < 1)) {
        //alert(JSON.stringify(sbo.cfg.bord));
        //alert(JSON.stringify("NOTABLE"));
        sbo.cfg.bord = "9999";

        if(false) {
            var self = this;
            var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/notable";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
            return;
        }
    }

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;

    this.dsi_mg();
  }

    env_init() {
        var jsonstr = localStorage.getItem("sbo");
        var env = JSON.parse(jsonstr);
        //alert(JSON.stringify(env));
        if(env) {
            if(env.cfg) sbo.cfg = env.cfg;
            if(env.unit) sbo.unit = env.unit;
            if(env.cdo) sbo.cdo = env.cdo;
            if(env.cart) sbo.cart = env.cart;
        }
    }

    mg_cls(btn) {
        var cls = "flex col";

        var val = btn.gid % 4;
        if(val == 0) cls = cls + " mg-kebab";
        else if(val == 1) cls = cls + " mg-pasta";
        else if(val == 2) cls = cls + " mg-pizza";
        else if(val == 3) cls = cls + " mg-burger";
        else cls = cls + " mg-sallad";

        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //css.margin = "5px 12px";
        if(window.innerWidth < 500) {
            css.width = window.innerWidth - 10;
        }
        else {
            css.margin = "5px 12px";
        }
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      if(fil.length < 1) return "";

      var url = "";
        url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    dsi_mg() {
        var self = this;

        this.setState({ txt:"Laddar grupp", mga: [] });

        if(!sbo.cfg.sid) {
            var url = "/";
            this.props.navigate(url);
            return;
        }

        var sdo = {};
        sdo.zoneid = sbo.cart.zoneid;
        sdo.tid = moment().format("HHmm");

        var prm = {};
        prm.sid = sbo.cfg.sid;
        //prm.req = "dsi.dsi_ecom.ecom_menus";
        prm.req = "dsi.dsi_mnu.mnu_list";
        //prm.mnuid = gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                //var mga = ret.rca;
                //alert(JSON.stringify(mga));
                var mga = [];
                for(var row of ret.rca) {
                    //alert(JSON.stringify(row));
                    if(row.zones && (row.zones.length > 0)) {
                        var brc = false;
                        for(var zone of row.zones) {
                            //alert(JSON.stringify(row));
                            if(zone.zoneid == sdo.zoneid) {
                                //alert(JSON.stringify(sdo));
                                if((sdo.tid * 1) < (zone.ftid * 1)) brc = true;
                                if((sdo.tid * 1) > (zone.ttid * 1)) brc = true;
                            };
                        }
                        if(brc) continue;
                    };
                    mga.push( row );
                }
                self.setState({ mga: mga });
                //self.syncTb("mnaTable");
                //self.mg_open(mgo);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    mg_open(mgo) {
        sbo.cdo.gid = mgo.gid;
        //var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        //url = url + "/flow/menu/grp";
        var url = "/" + sbo.cfg.uid + "/" + sbo.cfg.styp + "/sale/grp";
        this.props.navigate(url);
    };
    get_badge() {
        var nrr = 0;
          if(sbo.cart.rows) nrr = nrr + sbo.cart.rows.length;
          if(sbo.cart.pro) nrr = nrr + Object.keys(sbo.cart.pro).length;
        return nrr;
      }
      cart_open() {
        if(false) {
            var url = "delivery";
            this.props.navigate(url);
            return;
        }
        var self = this;
        var wnds = {};
        wnds.cartopen = true;
        wnds.cb = self.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            ctx.setState({ wnds: {} });
            var url = "direct";
            //var url = "delivery";
            ctx.props.navigate(url);
            return;
        }
        ctx.setState({ wnds: {} });
    }
  
  render() {

    return (
    <div className="">

        <div className="mgv">
            <div className="flex-row py-1 hide">
                <div className="txt_14 flex">
                    Vi har rutiner för att hantera allergibeställningar och minska risken för kontaminering av allergener. Det kan förekomma spår av allergener då vi hanterar många råvaror i våra kök. Vi kan inte garantera att vår mat är helt fri från allergener trots att produkterna inte innehåller något av våra allergener.
                    Kontakta vår restaurangpersonal så hjälper de dig med din allergibeställning. Välkommen!
                </div>
            </div>
            <div className="mgv-grid bg-white black">
                { this.state.mga.map((mgo, key) =>
                    <div className={ this.mg_cls(mgo) } key={key}
                         onClick={() => this.mg_open(mgo) }
                         style={ this.mg_style(mgo) }
                    >
                        <div className="mg-txt-body">
                        <div className="flex-col">
                        <div className="text-center font-bold font-colby f50 py-3">
                        { mgo.text }
                        </div>
                        </div>
                        <div className="flex-row px-5 py-3" >
                            <div>{ mgo.desc }</div>
                        </div>
                        <div className="flex-row px-5 py-1" >
                            <div className="flex"></div>
                            <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
                            <div className="flex"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Sale_Kitchens));

/*
                            <div className={mgo.class}>
                            { mgo.img ?
                                <img src={this.imgUrl(mgo.img)} className="mg-img" alt="Foodle" />
                                :  <div></div> }
                            </div>
                            <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />



                        <div className="text-center font-bold font-colby f80 py-3">
                        { mgo.text }
                        </div>

                        */