import React, { useState, useEffect, useContext } from 'react';
import {format, subDays, startOfMonth} from "date-fns";

import * as net from "../../lib/net";

//import PdfDlg from "./dlg/pdf_dlg";
//import LoginDlg from "./dlg/login_dlg";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class PosTh extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = {};
        this.state.gaa = [];
        this.state.loading = 0;
        this.state.sdo = {};
        this.state.tha = [];

        this.state.today = new Date();
        //var fdatum = startOfMonth(this.state.today);
        var fdat = format(this.state.today, "yyyy-MM-dd");
        var tdat = format(this.state.today, "yyyy-MM-dd");
        this.state.fdat = fdat;
        this.state.tdat = tdat;
    }
    componentDidMount(){
        this.st_th();
    }

    componentWillUnmount(){
    }

    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
    row_css(row) {
        var css = {};
        if(this.state.row) {
            if(this.state.row.ank == row.ank) {
                css.background = "#080";
                css.color = "#fff";
            }
        }
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 225;
        return css;
    }
  st_th() {
    var self = this;

    var prm = {};
    prm.req = "ta.ta_th.act_list";
    prm.avd = this.state.avd;
    prm.fdat = this.state.fdat;
    prm.tdat = this.state.tdat;
    //alert(JSON.stringify(prm));

    if(this.store.seo.kid.ztrim() == "353") {
        prm.sid = "S0002269";
        net.sid_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret.rca));

            self.setState({ tha: ret.rca });
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
        return;
    }

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        self.setState({ tha: ret.rca });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }

    render() {
/*
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
*/

        return (
            <div className="web-content flex-column">


            <div className="d-flex flex-row justify-content-between">
                <div className="">INLOGGADE</div>
                <div className="cdiblue" onClick={() => this.st_oms() }>UPPDATERA</div>
            </div>
             <div className="flex">
                <table className="table table-bordered table-hover tb fixed_header">
                    <thead>
                    <tr>
                        <th>Anstnr</th>
                        <th>Namn</th>
                        <th>Datum</th>
                        <th>Start</th>
                    </tr>
                    </thead>
                    <tbody style={ this.tb_style() }>
                    { this.state.tha.map((row, key) =>
                    <tr key={key} onDoubleClick={() => this.row_open(row) } draggable={true} onDragStart={ (event) => this.drag_start(event, row)} onDrag={ (event) => this.drag(event)}>
                        <td>{ row.anstnr.ztrim() }</td>
                        <td>{ row.namn.toswe() }</td>
                        <td>{ row.datum.cdidate() }</td>
                        <td>{ row.starttid.cditid() }</td>
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div className="">
                <div className="txt_14">Antal : { this.state.tha.length }</div>
            </div>


         </div>
        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default PosTh;
