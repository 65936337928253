import React, { useState, useEffect, useContext } from 'react';
import { observer } from "mobx-react";
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode"
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";
import sbo from "../sbo";
import Mnu_Prd from "./prd";

class Prd_ScanQR extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.html5QrCode = null;

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};
        this.state.qr = "";
        this.state.result = "";

        this.state.step = 1;
    }

    componentWillUnmount(){
        if(this.html5QrCode) {
            this.html5QrCode.stop().then((ignore) => {
                // QR Code scanning is stopped.
            }).catch((err) => {
                // Stop failed, handle it.
            });
        }
    }
    componentDidMount(){
        //this.scan_qr();
        /*
        var self = this;
        setTimeout(function () {
            self.qr_prd("P011015");
        }, 1500);
        */
        this.qr_init();
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
    qr_css() {
        var css = {};
        css.width = "100px";
        css.height = "100px";
        css.border = "1px solid #ff9900";
        css.display = "flex";
        css.justifyContent = "center";
        return css;
    }
    wnd_close = () => {
        this.props.ctx.setState({ wnds: {} });
    };

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    pickup_qr() {
        var self = this;

        var prm = {};
        prm.req = "vcm.ps.qr";
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        net.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                //var qr = 'data:image/png;base64,'+ ret.qr;
                self.setState({qr: ret.qr});
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }

    scan_qr() {
        var self = this;
        navigator.mediaDevices.getUserMedia({video: true});

        if (!navigator.mediaDevices) {
            throw new Error("mediaDevices API unavailable.");
        }

        navigator.mediaDevices.enumerateDevices()
        .then(function(devices){
            var cameras = devices.filter(d => (d.kind === "videoinput"));
            const video = document.getElementById("video");
            var stream = cameras[0];
            alert(JSON.stringify(cameras));
            video.srcObject = stream;
            video.play();
        });

    };

    qr_start(cameraId) {
        var self = this;
        const html5QrCode = new Html5Qrcode(/* element id */ "qrreader");
        this.html5QrCode = html5QrCode;

        html5QrCode.start(
            //cameraId,
            { facingMode: "environment" },
            {
                fps: 10,    // Optional, frame per seconds for qr code scanning
                qrbox: { width: 250, height: 250 },  // Optional, if you want bounded box UI
                //formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ]
            },
            (decodedText, decodedResult) => {
                // do something when code is read
                //alert("SCAN: " + JSON.stringify(decodedText));
                //alert("RES: " + JSON.stringify(decodedResult));
                self.setState({result: decodedText});
            },
            (errorMessage) => {
                // parse error, ignore it.
                //alert("ERR: " + JSON.stringify(errorMessage));
            })
            .catch((err) => {
                // Start failed, handle it.
                //alert("FAIL: " + JSON.stringify(err));
            });
    };

    qr_stop(html5QrCode) {
        var self = this;
        html5QrCode.stop().then((ignore) => {
            // QR Code scanning is stopped.
        }).catch((err) => {
            // Stop failed, handle it.
        });
    };

    qr_camera() {
        var self = this;
        if(true) {
            self.qr_start("");
            return;
        }

        Html5Qrcode.getCameras().then(devices => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            //alert("DEVS: " + JSON.stringify(devices));
            if (devices && devices.length) {
                var cameraId = devices[0].id;
                self.qr_start(cameraId);
            }
        }).catch(err => {
            // handle err
        });
    }

    qr_init() {
        this.qr_camera();
    }
    qr_prd(prdid) {
        var self = this;
        var wnds = {};
        wnds.prd = true;
        wnds.prdid = prdid;
        this.setState({wnds: wnds});
    }

    render() {

    return (
        <div className="mx_base">
        { this.props.backdrop ? <div className="mx_backdrop" /> : null }

        <div className="mx_dlg mx_100 flex-col" style={ this.dlg_style() }>
            <div className="mx_h01">
                <div>SKANNA PRODUKT</div>
            </div>
            <div className="mx_content flex">
                <div className="flex-center">
                    <div className="txt_22">QR KOD</div>
                </div>
                <div className="divider_blue"></div>

                <div className="flex-col flex py-5 px-5">
                    <div className="flex-row flex">
                        <div id="qrreader" width="300px" height="300px" style={{ borderRadius: "20px" }}></div>
                    </div>
                    <div className="flex-row hide">
                        { this.state.qr ?
                            <img src={ this.state.qr } alt="" width="300px" height="300px" />
                            : null}
                    </div>
                </div>

                <div>{this.state.result}</div>
                <div className="divider_blue"></div>
                <div className="txt_18 web-btn-01" onClick={() => this.wnd_close() }>STÄNG</div>
                <div className="txt_18 web-btn-01" onClick={() => this.qr_init() }>STARTA</div>

            </div>
        </div>
            { this.state.wnds.prd ? <Mnu_Prd wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        </div>
    );
    }
}
export default withRouter(observer(Prd_ScanQR));