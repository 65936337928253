import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import {observer} from "mobx-react";

//import logo from '../img/logo-sk.png';
//import logobnb from '../img/logo-bnb.jpg';

import sbo from "./sbo";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Ecom_Home extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};

        this.state.step = 1;
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    componentDidMount(){
        //var xdo = localStorage.getItem("sxp");
        var xdo = sessionStorage.getItem("sxp");
        if(xdo) {
            var sdo = JSON.parse(xdo);
            this.setState({ sdo: sdo });
        }
        var self = this;
        if(sbo.cfg.ot == 1) {
            sbo.cfg.ot = 0;
            setTimeout(function() {
                self.eatin();
            }, 100);
        }
    }

    header_css() {
        var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
        var cls = "web-top";
		if(this.state.top) {
			//cls = cls + " web-head-1";
		}
		//else cls = cls +  " web-header";

		return cls;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        if(sbo.cfg.uid == "bnb") logo = require('../img/logo-bnb.jpg');
        else if(sbo.cfg.uid == "brovakt") logo = require('../img/logo-brovakten.gif');
        else if(sbo.cfg.uid == "tgif01") logo = require('../img/logo-tgif.gif');
        else if(sbo.cfg.uid == "foodle") logo = require('../img/logo-foodle.gif');
        else if(sbo.cfg.uid == "megazone") logo = require('../img/logo-megazone.gif');
        else if(sbo.cfg.uid == "babas") logo = require('../img/logo-babas.gif');
        else if(sbo.cfg.uid == "domino") logo = require('../img/logo-domino.gif');
        else logo = require('../img/logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    show_fkn(fkn) {
        var css = {};
        css.display = "none";
        //if(fkn == "eatout") css.display = "";

        if(sbo.cfg.styp == "bas") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "rst") {
            //if(fkn == "eatin") css.display = "";
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "demo") {
            if(sbo.cfg.chan[fkn]) css.display = "";
        }
        if(sbo.cfg.styp == "tst") {
            if(fkn == "eatout") css.display = "";
            if(fkn == "eatin") css.display = "";
            if(fkn == "v1") css.display = "";
            if(fkn == "v2") css.display = "";
        }

        return css;
    }

    mnu_show() {
    };
    usr_info() {
    };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };
    scan_table() {
        var self = this;
        var wnds = {};
        wnds.scantable = true;
        //wnds.row = row;
        this.setState({wnds: wnds});
    }
    scan_qr() {
        var self = this;
        var wnds = {};
        wnds.scanqr = true;
        wnds.cb = self.tb_cb;
        this.setState({wnds: wnds});
    }
    tb_cb(ctx, rsp) {
        if(rsp.ok == "000") {
            sbo.cfg.bord = rsp.bord;
            if(sbo.cfg.bord.length > 0) ctx.run_v1();
            else ctx.scan_qr();
        }
        ctx.setState({ wnds: {} });
    }

    run_v1() {
        var self = this;
        var url = "/shop/v1";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v1";
        sbo.cfg.vurl = url;
        self.props.navigate(url);
    }
    run_v2() {
        var self = this;
        var url = "/shop/v2";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v2";
        sbo.cfg.vurl = url;
        self.props.navigate(url);
    }
    run_v3() {
        var self = this;
        var url = "/shop/v3";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v3";
        sbo.cfg.vurl = url;
        self.props.navigate(url);
    }
    run_shop() {
        var self = this;
        var url = "/shop/v5";
        if(sbo.cfg.uid) url = "/" + sbo.cfg.uid + "/v5";
        sbo.cfg.vurl = url;
        self.props.navigate(url);
    }

    shop() {
        var self = this;
        if(sbo.cfg.bord.length > 0) self.run_shop();
        else self.run_shop();
    }
    eatin() {
        var self = this;
        if(sbo.cfg.bord.length > 0) self.run_v1();
        else self.scan_qr();
    }

    eatout() {
        var self = this;
        sbo.cfg.bord = "99";
        self.run_v3();
    }

    render() {

        return (
<div className="web-app">


    <div className="web-body flex-col">

        <div className="flex-center py-2">
            <img className="svk-logo" src={ this.logo() } />
        </div>
        <div className="divider_grey"></div>
        <div className="flex-center">
            <div className="txt_22">VÄLKOMNA</div>
        </div>
        <div className="divider_grey"></div>
        <div className="flex-center">
            <div className="txt_18">{sbo.cfg.text}</div>
        </div>
        <div className="flex-center">
            <div className="txt_18">{sbo.cfg.tele}</div>
        </div>
        <div className="flex-center hide">
            <div className="txt_18">Enhet: {sbo.cfg.sid}</div>
            <div className="txt_18">Bord: {sbo.cfg.bord}</div>
        </div>
        <div className="divider_orange"></div>

        <div className="flex"></div>

        <div className="">
            <div className="txt_18 web-btn" style={ this.show_fkn("v1") } onClick={() => this.run_v1() }>TST V1</div>
            <div className="txt_18 web-btn" style={ this.show_fkn("v2") } onClick={() => this.run_v2() }>TST V2</div>
        </div>
        <div className="">
            <div className="txt_18 web-btn" style={ this.show_fkn("qr") } onClick={() => this.scan_qr() }>QR</div>
        </div>
        <div className="">
            <div className="txt_18 web-btn" style={ this.show_fkn("shop") } onClick={() => this.shop() }>BUTIK</div>
            <div className="txt_18 web-btn" style={ this.show_fkn("eatin") } onClick={() => this.eatin() }>ÄTA HÄR</div>
            <div className="txt_18 web-btn" style={ this.show_fkn("eatout") } onClick={() => this.eatout() }>TA MED</div>
        </div>

    </div>
</div>
        );
    }
}
export default withRouter(observer(Ecom_Home));
