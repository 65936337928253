import React, { useState, useEffect, useContext } from 'react';

import * as srs from "../../../lib/srs";
import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";

import sbo from '../../sbo.js'

import FoodleCheck from "../img/checkmark.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Foodle_PayFail extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = {};
    this.state.payok = 0;

    this.state.pro = {};
    this.state.pbo = {};
    if(props.wnds.pbo) {
        //this.state.pbo = props.wnds.pbo;
    }
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    //if(!this.props.wnds.pbo) this.pbo_init();
  }

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    content_style() {
        var css = {};
        css.background = "#800";
        css.color = "#fff";
        if(this.state.payok) css.background = "#800";
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    body_style() {
        var css = {};
        //if(this.state.payok) css.display = "none";
        return css;
    }
    info_style() {
        var css = {};
        //if(!this.state.payok) css.display = "none";
        return css;
    }

    wnd_close = () => {
      if(this.props.close) {
          this.props.close(this.props.ctx);
      }
      else this.props.ctx.setState({ wnds: {} });
	};

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_paid() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    cart_pay() {
        var self = this;

        this.cart_sell();
    }

    tst_pay() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "vcm.ps.ps_ecom_sell";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                self.cart_paid();
            }
            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    pay_abort() {
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "999";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="m2_dlg mx_70h flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex" style={ this.content_style() }>
            <div className="mx-close-btn" onClick={() => this.wnd_close() }>X</div>

            <div className="flex-cl py-3">
            <div className="flex-row px-5">
                <div className="txt_b18">BETALNINGS FEL</div>
                <div className="flex"></div>
            </div>
            </div>
            <div className="divider_orange"></div>


            <div className="mx-body px-5" style={ this.body_style() }>

                <div className="mx-body px-5 flex-center" style={ this.info_style() }>
                    <div className="txt_22 text-white">BETALNING MISSLYCKAD</div>
                </div>
            </div>


        </div>
        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-row">
                <div className="mfull-btn" onClick={() => this.pay_abort() }>AVBRYT</div>
            </div>
        </div>
      </div>
    </div>
	;
	return html;
  };
}

export default Foodle_PayFail;