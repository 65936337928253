import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from "react-router-dom";
import { observer } from 'mobx-react'
import moment from "moment";
import axios from "axios";

import Tiden from "../lib/ui/tiden";
import * as utils from "../assets/utils";
import sbo from './sbo.js'

import Mcs_Bas from "./bas/bas";
import Mcs_Flow from "./flow/flow";
import Mcs_Kiosk from "./kiosk/kiosk";
import Mcs_Ta from "./ta/ta";

import Mcs_Closed from "./closed";
import Mcs_NoAuth from "./noauth";
import Mcs_Home from "./home";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Mcs extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    //this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.infotxt = "";
      this.state.wnds = {};
      this.state.mwnd = 0;
      this.state.ddo = sbo.ddo;
      sbo.ddo.fas = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //let { id } = this.props.params;
    //alert(JSON.stringify(id));
    //sbo.cfg.id = id;
    //this.env_init();
    }

    env_init() {
        var env = sessionStorage.getItem("sbo");
        sbo.cfg = env.cfg;

    }

    queryStringToJSON(queryString) {
        if(queryString.indexOf('?') > -1){
            queryString = queryString.split('?')[1];
        }
        var pairs = queryString.split('&');
        var result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }

    validate_unit() {
        var params = this.queryStringToJSON(window.location.search);
        //alert(JSON.stringify(params));
        if(params) {
            if(params.bord) sbo.cfg.bord = params.bord;
        }
    
        var hash = window.location.hash;
        if(hash) {
            //alert(JSON.stringify(hash));
            var bord = hash.substring(1);
            if(bord == "SS") {
                //sbo.cfg.ot = 9;
            }
            else {
                sbo.cfg.bord = bord;
                //sbo.cfg.ot = 1;
            }
        }
        
        sbo.cart.bord = sbo.cfg.bord;
    
        if(sbo.cfg.hours) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            if(hhmm < "0600") {
                wday = wday - 1;
                if(wday == 0) wday = 7;
            }
            //alert(JSON.stringify(wday));
    
            if((sbo.cfg.hours.wday) && (sbo.cfg.hours.wday[wday])) {
                var wo = sbo.cfg.hours.wday[wday];
                ftid = wo.ftid;
                ttid = wo.ttid;
            }
    
            if(hhmm < "0600") {
                if(ftid < "0600") {
                    if(hhmm < ftid) {
                        sbo.cfg.closed = 1;
                    }
                }
            }
            else {
                if(hhmm < ftid) {
                    sbo.cfg.closed = 1;
                }
            }
    
            if(hhmm > ttid) {
                sbo.cfg.closed = 1;
            }
            sbo.cfg.ftid = ftid;
            sbo.cfg.ttid = ttid;
        }
    
        if(sbo.cfg.campdts) {
            //alert(JSON.stringify(hash));
            var ts = moment();
            var dts = ts.format("YYMMDD");
            var hhmm = ts.format("HHmm");
            var wday = ts.format("E");
            var ftid = sbo.cfg.hours.ftid;
            var ttid = sbo.cfg.hours.ttid;
            //alert(JSON.stringify(wday));
            if(sbo.cfg.campdts[dts]) {
                var wo = sbo.cfg.campdts[dts];
                var ftid = wo.ftid;
                var ttid = wo.ttid;
                if((hhmm >= ftid) && (hhmm <= ttid)) {
                    sbo.cfg.campaign = wo.campaign;
                }
            }
        }
        
        if(sbo.cfg.closed == 1) {
            var self = this;
            var url = "/" + sbo.cfg.uid + "/closed";
            setTimeout(function() {
                self.props.navigate(url);
            }, 500);
        }
    
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    
    no_auth() {
        var self = this;
        var url = "/" + sbo.cfg.uid + "/noauth";
        setTimeout(function() {
            self.props.navigate(url);
        }, 500);
}

    get_cfg(uid) {
        var self = this;

        sbo.cfg = {};
        sbo.cfg.text = "INAKTIV";

        //var token = this.state.token;
        var req = {};
        req.fkn = "MDB";
        req.uid = uid;
        //alert(JSON.stringify(req));

        //axios.post("/mdb", req)
        //axios.post("http://127.0.0.1:9690/mdb", req)
        axios.post("https:/shop.svepos.se/mdb", req)
            //axios.post("/gsid", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res.data));
                var sdo = res.data;
                if(sdo.ok == "000") {
                    sbo.cfg = sdo.rco;
                    //self.setState({ valid: 1 });
                    self.validate_unit();
                }
                else {
                    self.no_auth();
                }
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
                self.no_auth();
            });
        //this.setState({ phase: 1, pau: {} });
    }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.navigate(url);
   };

   theme_sel() {
    var cls = "web-app";
    //alert(sbo.cfg.shoptheme);
    if(sbo.cfg.shoptheme && (sbo.cfg.shoptheme.length > 0)) {
        cls = cls + " " + sbo.cfg.shoptheme;
    }
    else cls = cls +  " shoptheme";

    return cls;
}

  render() {

    return (
        <div className={ this.theme_sel() }>
            <div className="txt_18">
                   FAS: { sbo.ddo.fas }
            </div>
            <div className="txt_18">
                   FXS: { sbo.ddo.fxs }
            </div>

            { sbo.ddo.fas == "s1" ? <Mcs_NoAuth ctx={this} store={this.props.store} /> : null }
            { sbo.ddo.fas == "s2" ? <Mcs_Ta ctx={this} store={this.props.store} /> : null }
            { sbo.ddo.fxs == "s2" ? <Mcs_Closed ctx={this} store={this.props.store} /> : null }
        { sbo.ddo.fas == "" ? <Mcs_Home ctx={this} store={this.props.store} /> : null }

        </div>
  );
}
}
export default observer(Mcs);
/*
        { sbo.unit.mode == "bas" ? <Mcom_Bas store={this.store} /> : null }
        { sbo.unit.mode == "kiosk" ? <Mcom_Kiosk store={this.store} /> : null }
        { sbo.unit.mode == "flow" ? <Mcom_Flow store={this.store} /> : null }
*/