import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../../lib/react/withRouter";

import * as net from "../../lib/net";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import sbo from "../sbo";
import * as utils from "../../assets/utils";
import * as srs from "../../lib/srs";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Mnu_Grps extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;

        this.state.user = {};
        this.state.sdo = {};
        this.state.gra = [];

        this.state.step = 1;
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    componentDidMount(){
        this.dsi_mnus();
    }

   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head-1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
    tb_style() {
        var css = {};
        //css.height = window.innerHeight - 185;
        //css.overflow = "auto";
        return css;
    }
    img_url(url){
        var img = null;
        img = "http://filpub.svepos.se/app/ct24/" + url;
        return img;
    }
    logo_css() {
        var css = {};
        if(sbo.cfg.uid == "bnb") css.width = "90%";
        else if(sbo.cfg.uid == "brovakt") css.width = "90%";
        else if(sbo.cfg.uid == "tgif01") css.width = "100%";
        else if(sbo.cfg.uid == "foodle") css.width = "90%";
        else if(sbo.cfg.uid == "domino") css.width = "90%";
        else if(sbo.cfg.uid == "megazone") css.width = "90%";
        else css.width = "100%";
        return css;
    }
    logo() {
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        //if(sbo.cfg.uid == "bnb") logo = require('../../img/logo-bnb.jpg');
        //else logo = require('../../img/logo-sk.png');
        //if(sbo.cfg.uid == "bnb") logo = require('../../img/logo-bnb.svg');
        if(sbo.cfg.uid == "bnb") logo = require('../../img/logo-bnb.gif');
        else if(sbo.cfg.uid == "brovakt") logo = require('../../img/logo-brovakten.gif');
        else if(sbo.cfg.uid == "tgif01") logo = require('../../img/logo-tgif.gif');
        else if(sbo.cfg.uid == "foodle") logo = require('../../img/logo-foodle.gif');
        else if(sbo.cfg.uid == "domino") logo = require('../../img/logo-domino.gif');
        else if(sbo.cfg.uid == "megazone") logo = require('../../img/logo-megazone.gif');
        else logo = require('../../img/logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
   };
    usr_info() {
   };
    fknGo(url) {
        //var op = this.state.op;
        //var pa = url.split("/");
        //if(pa.length > 1) op = pa[2];
        //this.setState({op: op });

        var urls = "/" + sbo.cfg.uid;
        url = urls + "/w1/" + url;
        this.props.navigate(url);
    };
    about() {
    };
    help() {
    };
    offer() {
    };
    offer_week() {
    };
    shop_exit() {
        sbo.cart.orderid = "";
        sbo.cart.rows = [];
        sbo.cart.belopp = 0;
        sbo.cfg.bord = "";

        var url = "/" + sbo.cfg.uid;
        this.props.navigate(url);
    };
    shop_begin() {
        this.fknGo("home");
    };

    login() {
        var sdo = {};
        sdo.text = "UUIIDD";
        var xdo = JSON.stringify(sdo);
        //alert(JSON.stringify(xdo));
        sessionStorage.setItem("sxp", xdo);
        //localStorage.setItem("sxp", xdo);
    };
    logout() {
        sessionStorage.removeItem("sxp");
        //localStorage.removeItem("sxp");
        //this.props.history.push("/");
    };
    dsi_mnus() {
        var self = this;

        var channel = sbo.cfg.chan["eatin"].channel;
        var prm = {};
        prm.req = "dsi.dsi_mnu.mnu_list";
        prm.sid = sbo.cfg.sid;
        prm.channel = channel;
        //prm.datum = this.state.dts;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ gra: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    dsi_mp() {
        var self = this;

        var prm = {};
        prm.sid = "";
        prm.req = "dsi.dsi_mp.mp_web";
        prm.sid = sbo.cfg.sid;
        prm.typ = "P";
        prm.mnuid = this.state.gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                self.setState({ mna: ret.rca });
                //self.syncTb("mnaTable");
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    grp_show(row) {
        var self = this;
        sbo.wso.grp = row;
        sbo.wso.gid = row.mnuid;
        this.fknGo("grp");
    }

    render() {

        return (
<div className="web-app">

    <div className="web-body flex-col">

        <div className="flex-center">
            <div className="txt_22">WEB</div>
        </div>
        <div className="flex-center">
            <div className="txt_18">{sbo.cfg.text}</div>
            <div className="txt_18">Bord: {sbo.cfg.bord}</div>
        </div>

        <div className="ws-area">
            <div style={ this.tb_style() }>
                <div className="mnu-grid">
                    { this.state.gra.map((row, key) =>
                        <div key={key} className="ws-grp text-center" onClick={() => this.grp_show(row) }>
                            <div className="txt_16">
                                { utils.toLang( row.text ) }
                            </div>
                            <div className="txt_14">
                                { utils.toLang( row.desc ) }
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.about() }>OM OSS</div>
            <div className="txt_18 web-btn flex" onClick={() => this.help() }>HJÄLP</div>
        </div>
        <div className="flex-row hide">
            <div className="txt_18 web-btn flex" onClick={() => this.offer() }>ERBJUDANDE</div>
            <div className="txt_18 web-btn flex" onClick={() => this.offer_week() }>VECKANS</div>
        </div>
        <div className="flex-row">
            <div className="txt_18 web-btn flex" onClick={() => this.shop_exit() }>AVBRYT KÖP</div>
            <div className="txt_18 web-btn flex" onClick={() => this.shop_begin() }>BÖRJA HANDLA</div>
        </div>

    </div>
</div>
        );
    }
}
export default withRouter(Mnu_Grps);