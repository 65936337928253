import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../../../lib/react/withRouter";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as utils from "../../../assets/utils";
import Fr_Input from "../../../lib/ui/fr_input";
import * as srs from "../../../lib/srs";
import * as q from '../../../lib/defer';

import sbo from '../../sbo.js'

import Ta_Kvitto from "./kvitto";
import Ta_PayOk from "./pay_ok";
import Ta_PayFail from "./pay_fail";
import Ta_Swish_SS from "./swish_ss";

class Ta_Direct extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.wnds = {};
      this.state.swish = 0;

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";
      this.state.info = "";
      this.state.error = "";
      this.state.errormsg = "";

      this.state.mga = [
          {id: 'dan', sid: 'S0000281', text: 'FOODLE DANDERYD', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'sic', sid: 'S0000283', text: 'FOODLE SICKLA', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'nyk', sid: 'S0000282', text: 'FOODLE NYKÖPING', img: '', txtimg: '', class: 'mg-sallad', desc: '' }
      ];
      this.state.dlt = [
          {id: '1', ftid: '1300', ttid: '1330', text: '' },
          {id: '2', ftid: '1330', ttid: '1400', text: '' },
          {id: '3', ftid: '1400', ttid: '1430', text: '' },
          {id: '4', ftid: '1430', ttid: '1500', text: '' },
          {id: '5', ftid: '1500', ttid: '1530', text: '' },
          {id: '6', ftid: '1530', ttid: '1600', text: '' },
      ];
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

      sbo.cart.levbelopp = 0;
      if(sbo.cart.levtyp == "deliver") {
          sbo.cart.levbelopp = 3900;
      }

      //alert(JSON.stringify(sbo.cdo));
      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
          this.state.street = "";
          this.state.city = "";
      }
      //if(!sbo.cdo) sbo.cdo = {};
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        this.env_init();

        sbo.cfg.nocart = 1;

        var pos = {};
        pos.deps = [];
        pos.vgrps = [];
        pos.plus = [];
        this.props.store.pos = pos;
        //alert(JSON.stringify(pos));
        //alert(JSON.stringify(sbo.cart));
    }

    env_init() {
        var jsonstr = sessionStorage.getItem("sbo");
        var env = JSON.parse(jsonstr);
        //alert(JSON.stringify(env));
        if(env) {
            if(env.cfg) sbo.cfg = env.cfg;
            if(env.unit) sbo.unit = env.unit;
            if(env.cdo) sbo.cdo = env.cdo;
            if(env.cart) sbo.cart = env.cart;
        }
    }

    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_style() {
        var css = {};
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "100%";
        //css.background = "#fee260";

        //css.backgroundImage = 'url("https://story.svepos.se/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

    ddo() {
        //alert("POS" + this.state.pgid );
        //this.setState({ status: 1 });
    }

    imgUrl(fil) {
      var url = "";
      url = "https://story.svepos.se/foodle/";
      url = url + fil;
      return url;
    }

    unit_open(mgo) {
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.sid = mgo.sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    unit_zip() {
        var sid = "";
        var zip = this.state.zip;
        //alert(zip);

        if(zip && (zip.substr(0, 2) == "11") ) {
            sid = "S0000281";
        }
        else if(zip && (zip.substr(0, 3) == "611") ) {
            sid = "S0000282";
        }
        else {
            alert("Ej aktivt postnummer");
            return;
        }
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.zip = zip;
        sbo.cdo.sid = sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    setZip(e) {
        //alert(e.currentTarget.value);
        this.setState({ zip: e.currentTarget.value});
    };
    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    row_del(row) {
        sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];

        //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
        var belopp = 0;
        var rows = [];
        for(var crow of sbo.cart.rows) {
            if(crow.plu != row.plu) {
                //alert(JSON.stringify(crow));
                belopp = belopp + (crow.belopp * 1);
                rows.push(crow);
            }
        }
        sbo.cart.rows = rows;
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }
    discount_change(e) {
        this.setState({discount: e});
    }
    val_save( id, val ) {
        var state = this.state;
        state[id] = val;
        this.setState(state);
    }
    val_anm_save( id, val ) {
        var state = this.state;
        state[id] = val.currentTarget.value;
        this.setState(state);
    }

    discount_clear() {
        var belopp = sbo.cart.belopp * 1;
        var discount = sbo.cart.discount * 1;
        belopp = belopp + discount;
        discount = 0;

        delete(sbo.cart.discount);
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    cart_discount() {
        if(sbo.cart.discount) {
            return;
        }
        var rproc = 10;
        if(this.state.discount == "rab50") rproc = 50;

        var discount = 0;
        var belopp = sbo.cart.belopp;
        belopp = belopp.replaceAll(".", "");
        belopp = belopp * 1;

        discount = belopp * (rproc / 100);
        belopp = belopp - discount;

        sbo.cart.discount = discount.toString();
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    xcart_swish() {
        var self = this;
       /*
        sbo.cart.belopp = "0";
        sbo.cart.levbelopp = "0";
        sbo.cart.discount = "0";
        sbo.cart.topay = "0";
        sbo.cart.rows = [];
        sbo.cart.pro = {};
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        var url = "kitchens";
        this.props.navigate(url);
        */
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.req = "vcm.ps.ps_ecom_sell";
        prm.sbo = sbo;
        prm.anm = self.state.msg;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
            var wnds = {};
            wnds.kvitto = true;
            //wnds.cb = self.cart_cb;
            self.setState({ wnds: wnds });
    })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });

    }

    cart_create_order() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cfg.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sbo = sbo;

        var defer = q.defer();
        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            if(ret.ok != "000") {
                throw "991 - CANT CREATE ORDER";
                return;
            }
            sbo.cart.orderid = ret.orderid;

            var prm = {};
            prm.req = "vcm.swish.mcom";
            prm.sid = sbo.cfg.sid;
            prm.sbo = sbo;
            //prm.notanr = "123457E";
            prm.notanr = sbo.cart.orderid;
            prm.topay = sbo.cart.belopp;
            //prm.topay = "100";
    
            //alert(JSON.stringify(sbo));
            //return;
            return srs.sio_req(prm);
        })
        .then(function(ret){
            //alert(JSON.stringify(ret));

            var rsp = {};
            rsp.ok = ret.ok;
            rsp.token = "";
            if(ret.ok == "000") rsp.token = ret.token;
            defer.resolve(rsp);
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
            //self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            var rsp = {};
            rsp.ok = "999";
            rsp.token = "";
            defer.resolve(rsp);
       });

        return defer.promise;
    }

    cart_swish() {
        var self = this;

        if(sbo.cfg.debug == "1") {
            self.cart_direct_pay();
            return;
        }

        //self.cart_swish_test();
        //return;

        if(sbo.cfg.swish == "1") self.cart_swish_do();
        if(sbo.cfg.swish == "2") self.cart_direct_pay();
        if(sbo.cfg.swish == "3") self.cart_swish_test();
        if(sbo.cfg.swish == "5") self.cart_swish_kiosk();
    }

    cart_swish_test() {
        var self = this;
        var self = this;
        sbo.cart.slot = "";
        sbo.cdo.msg = self.state.msg;

        self.setState({ info: "Skapar order" }, function() {
        self.forceUpdate();
            self.cart_create_order()
            .then(function(ret){
                //alert(JSON.stringify(ret));

                sbo.cart.token = ret.token;

                self.setState({ swish: 1, info: "" }, function() {
                    self.forceUpdate();
                })
            })
        });
    }

    cart_swish_send() {
        var self = this;

        var token = sbo.cart.token;
        var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
        var url = "swish://paymentrequest?token=" + token + "&callbackurl=" + cburl;
        window.top.location.href = url;
    }

    cart_swish_do() {
        var self = this;
        sbo.cart.slot = "";
        sbo.cdo.msg = self.state.msg;

        self.setState({ info: "Skapar order" }, function() {
        self.forceUpdate();
            self.cart_create_order()
            .then(function(ret){
                //alert(JSON.stringify(ret));

                sbo.cart.token = ret.token;

                self.setState({ swish: 1, info: "" }, function() {
                    self.forceUpdate();
                })

                //var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/deliver?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                var url = "swish://paymentrequest?token=" + ret.token + "&callbackurl=" + cburl;
                //alert(JSON.stringify(url));
                
                //window.open(url);
                //window.location = url;
                window.top.location.href = url;
                //self.props.navigate(url);

                /*
                var swTimeout = setTimeout(function() {
                    var url = "/" + sbo.cfg.uid + "/ta/noswish";
                    //alert(JSON.stringify(url));
                    //document.location = cburl;
                    //self.props.navigate(url);
                }, 500);

                window.addEventListener('blur',function(e)
                    {
                        clearTimeout(swTimeout);
                    }
                )
                */
            })
        });
    };

    cart_order_pay() {
        var self = this;

        sbo.cart.slot = "";
        sbo.cdo.msg = self.state.msg;

        self.setState({ info: "Betalar order" });
        var prm = {};
        prm.sid = sbo.cfg.sid;
        prm.req = "vcm.ecom.ecom_direct_pay";
        prm.sbo = sbo;
        prm.orderid = sbo.cart.orderid;

        srs.sio_req(prm)
        .then(function(ret) {
            //alert(JSON.stringify(ret));
            sbo.cart.notanr = ret.notanr;

            if(ret.ok == "000") {
                var cburl = "/" + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                //alert(JSON.stringify(cburl));
                self.props.navigate(cburl);
            }
            else {
                var wnds = {};
                wnds.cartpayfail = true;
                //wnds.cb = ctx.payok_cb;
                self.setState({ wnds: wnds });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    cart_direct_pay() {
        var self = this;

        sbo.cart.slot = "";
        sbo.cdo.msg = self.state.msg;

        self.setState({ info: "Skapar order" });
        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cfg.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            if(ret.ok != "000") {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
                return;
            }
            sbo.cart.orderid = ret.orderid;

            var prm = {};
            //prm.sid = sbo.cdo.sid;
            prm.sid = sbo.cfg.sid;
            prm.req = "vcm.ecom.ecom_direct_pay";
            prm.sbo = sbo;
            prm.orderid = sbo.cart.orderid;
            prm.result = "";

            //alert(JSON.stringify(sbo.cart.orderid));
            //return;
            return srs.sio_req(prm);
        })
        .then(function(ret){

            //alert(JSON.stringify(ret));
            sbo.cart.notanr = ret.notanr;

            if(ret.ok == "000") {

                //var cburl = "/" + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                var cburl = "/" + sbo.cfg.uid + "/ta/deliver?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
                //alert(JSON.stringify(cburl));
                self.props.navigate(cburl);
            }
            else {
                var wnds = {};
                wnds.cartpayfail = true;
                //wnds.cb = ctx.payok_cb;
                self.setState({ wnds: wnds });
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.levbelopp = "0";
        sbo.cart.discount = "0";
        sbo.cart.topay = "0";
        sbo.cart.rows = [];
        sbo.cart.pro = {};
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_pay() {
        var self = this;
        if(false) {
            this.cart_do();
            return;
        }

        if(true) {
            //alert(JSON.stringify(sbo.cdo));
            //sbo.cart.cdo = {};
            sbo.cdo.fnamn = this.state.fnamn;
            sbo.cdo.enamn = this.state.enamn;
            sbo.cdo.tele = this.state.tele;
            sbo.cdo.email = this.state.email;
            sbo.cdo.msg = this.state.msg;
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }
        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = sbo.cdo.unit.sid;
        //prm.req = "vcm.ps.ps_ecom_sell";
        prm.req = "vcm.ecom.ecom_new";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        //return;
        srs.sio_req(prm)
        .then(function(ret) {

            if(ret.ok == "000") {
                sbo.cart.orderid = ret.orderid;

                var wnds = {};
                wnds.cartpay = true;
                wnds.cb = self.cart_cb;
                self.setState({ wnds: wnds });
                return;
            }
            else {
                self.setState({ info: "", error: "Kan ej skapa order.", errormsg: "Vänligen försök igen" });
            }

        })
    }

    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var wnds = {};
            wnds.cartpayok = true;
            wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }
        else {
            var wnds = {};
            wnds.cartpayfail = true;
            //wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }

        //else ctx.setState({ wnds: {} });
    }
    payok_cb(ctx, rsp) {
        ctx.setState({ wnds: {} });
        if(true) {
            var url = "/foodle";
            ctx.props.history.push(url);
            return;
        }
    }


    cart_swish_kiosk() {
        var self = this;
        sbo.cart.slot = "";
        sbo.cdo.msg = self.state.msg;

        self.setState({ info: "Skapar order" }, function() {
        self.forceUpdate();
            self.cart_create_order()
            .then(function(ret){
                //alert(JSON.stringify(ret));

                sbo.cart.token = ret.token;

                //self.setState({ swish: 1, info: "" }, function() {
                //    self.forceUpdate();
                //})

                var wnds = {};
                wnds.swish_ss = true;
                //wnds.row = row;
                wnds.cb = self.cb_swish_kiosk;
                self.setState({wnds: wnds, info: ""});
            })

        });
    };
        cb_swish_kiosk(ctx, ret) {
            //alert(JSON.stringify(ret));
            if(ret && (ret.ok == "999")) {
                ctx.setState({ wnds: {} });
                return;
            }
            ctx.setState({ wnds: {} });

            //sbo.cart.orderid = "";
            //sbo.cart.rows = [];
            //sbo.cart.belopp = 0;
    
            var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/order?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
            //var cburl = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/deliver?orderid=" + sbo.cart.orderid + "&sid=" + sbo.cfg.sid;
            var url = "swish://paymentrequest?token=" + ret.token + "&callbackurl=" + cburl;
            //alert(JSON.stringify(url));

            //window.open(url);
            //window.location = url;
            window.top.location.href = url;
            //self.props.navigate(url);
        }
       
    back() {
        if(true) {
            //var url = "delivery";
            //var url = sbo.cfg.baseurl + sbo.cfg.uid + "/ta/sale";
            var url = "/" + sbo.cfg.uid + "/ta/sale";
            this.props.navigate(url);
            return;
        }
    }
    cart_do() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "vcm.ps.ps_ecom_sell";
        prm.sbo = sbo;

        //alert(JSON.stringify(sbo));
        return;
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    not_min() {
        var brc = false;
        var minbelopp = 100;
        var belopp = sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }

    bg_info_style() {
        var css = {};
        if(this.state.info.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#3e79bb";
        css.color = "#fff";
        return css;
    }
    bg_error_style() {
        var css = {};
        if(this.state.error.length < 1) {
            css.display = "none";
        }
        css.height = window.innerHeight * 0.75;
        css.background = "#d50a19";
        css.color = "#fff";
        return css;
    }

  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">KASSA</div>
            <div className="flex"></div>
        </div>

        <div className="flex-col flex-center flex" style={ this.bg_info_style() }>
            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.info) }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.back() }>STÄNG</div>
            </div>
        </div>
        <div className="flex-col flex-center flex" style={ this.bg_error_style() }>
            <div className="flex-col py-5 px-5">
                <div className="txt_22">{ utils.toLang(this.state.error) }</div>
                <div className="txt_22">{ utils.toLang(this.state.errormsg) }</div>
            </div>
            <div className="flex-row">
                <div className="mpay-btn" onClick={() => this.back() }>STÄNG</div>
            </div>
        </div>

        { this.state.swish == 0 ?
        <div className="flex black" style={ this.bg_style() }>
            <div className="dlt-body flex-col flex">
                <div className="flex-col py-5 px-5">

                    <div className="mpg">
                        <div className="flex-row">
                            <div className="txt_b16">Produkter</div>
                        </div>
                        <div className="flex-col px-2">
                            { sbo.cart.rows.map((crow, ckey) =>
                                <div key={ckey} className="flex-col py-2 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                                    </div>
                                    { this.pro_ixa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_txa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_sxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_dxa(crow).map((row, key) =>
                                        <div key={key} className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <div className="flex"></div>
                                        <FontAwesomeIcon icon="trash" size="lg" onClick={() => this.row_del(crow) } />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>

                    { !sbo.cart.discount ?
                    <div className="">
                    <div className="flex-row">
                        <div className="txt_b16">ANGE RABATTKOD</div>
                    </div>
                    <div className="flex-row">
                        <Fr_Input col noflex cls="col-md-3 col-sm-6" className="text-left foodle-input" text={this.state.discount} getValue={(e)=> this.discount_change(e) } />
                        <div className="gray-btn col-md-3 col-sm-6" onClick={() => this.cart_discount()}>LÄGG TILL</div>
                    </div>
                    </div>
                    : null }

                    { sbo.cart.discount ?
                    <div className="flex-row" onClick={() => this.discount_clear()}>
                        <div className="txt_18">AVGÅR RABATT</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.discount ) } kr</div>
                    </div>
                    : null }

                    { sbo.cart.fee > "0" ? 
                    <div className="flex-row" onClick={() => this.discount_clear()}>
                        <div className="txt_18">SERVICE AVGIFT</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.fee ) } kr</div>
                    </div>
                    : null }
                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_18">ATT BETALA</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
                    </div>
                    <div className="flex-row hide">
                        <div className="txt_14">Leveranskostnad</div>
                        <div className="flex"></div>
                        <div className="txt_b14">{ utils.toPris( sbo.cart.levbelopp ) } kr</div>
                    </div>

 
                </div>

                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>
                <div className="flex"></div>
                <div className="flex-col row py-3 px-3">
                    <span className="header">Meddelande (Valfritt)</span>
                    <textarea className="text-left col-md-12 col-sm-12" maxLength="30" rows="5" value={this.state.msg} onChange={(e) => this.val_anm_save("msg", e)}></textarea>
                </div>

                {this.not_min() ?
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.cart_pay()}>MINI BELOPP EJ UPPNÅTT</div>
                    </div>
                    :
                    <div className="flex-row">
                        { sbo.cfg.debug == "1" ? <div className="mpay-btn" onClick={() => this.cart_direct_pay() }>TEST BETAL</div> : null}
                        <div className="mpay-btn hide" onClick={() => this.cart_direct_pay() }>KORT</div>
                        <div className="mpay-btn hide" onClick={() => this.cart_direct_pay() }>SWISH</div>
                        <div className="mpay-btn" onClick={() => this.cart_swish() }>SWISH</div>
                    </div>
                }
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>
            </div>

        </div>
        :

        <div className="flex black" style={ this.bg_style() }>
            <div className="dlt-body flex-col flex">
                <div className="flex-col py-5 px-5">
                <div className="txt_22 flex-center flex">Avvaktar betalning</div>
                <div className="txt_22 flex-center flex">Orderid: { utils.toNum(sbo.cart.orderid) }</div>
                <div className="txt_22 flex-center flex hide">SID: { sbo.cfg.sid }</div>
                <div className="mpay-btn" onClick={() => this.cart_swish_send() }>SKICKA TILL SWISH IGEN</div>
                <div className="mpay-btn hide" onClick={() => this.cart_order_pay() }>BETALA ORDER</div>
                </div>
            </div>
        </div>

        }

        { this.state.wnds.kvitto ? <Ta_Kvitto wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayok ? <Ta_PayOk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayfail ? <Ta_PayFail wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.swish_ss ? <Ta_Swish_SS wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Ta_Direct));
