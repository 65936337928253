import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import logo from '../../../img/logo-sk.png';
import * as utils from "../../../assets/utils";
import {withRouter} from "../../../lib/react/withRouter";

import sbo from "../../sbo";

class Flow_Closed extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    //static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.webStore = props.store;
        this.state = {};
        this.state.open = false;
        this.state.seldate = new Date();
        this.state.row = null;
        this.state.info = "";
        this.state.top = 0;

        this.state.user = {};
        this.state.sdo = {};
        this.state.wnds = {};
        this.state.una = [];

        this.state.step = 1;
    }

    componentWillUnmount(){
            this.setState({info: "UUU" });
    }
    componentDidMount(){
        //var xdo = localStorage.getItem("sxp");
        var xdo = sessionStorage.getItem("sxp");
        if(xdo) {
            var sdo = JSON.parse(xdo);
            this.setState({ sdo: sdo });
        }
    }

    header_css() {
        var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
        var cls = "web-top";
		if(this.state.top) {
			//cls = cls + " web-head-1";
		}
		//else cls = cls +  " web-header";

		return cls;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 125;
        css.overflow = "auto";
        return css;
    }
    logo(){
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('../img/logo-cdi.png');
        //else logo = require('./logo-sk.png');
        //logo = require('./logo-sk.png');
        return logo;
    }

    cdi_show(id) {
        //alert(id);
        var brc = false;
        if(this.state.step == id) brc = true;
        return brc;
    }

    mnu_sel(mnuid) {

        this.setState({mnu_sel: mnuid});
        const element = document.getElementById(mnuid);
        if (element) element.scrollIntoView();
    }

   mnu_item_css(mnuid) {
  	    var css = {};

        css.color = "#222";
        if(this.state.mnu_sel == mnuid) {
			///css.background = "#080";
			//css.color = "#fff";
			css.color = "#080";
        }
		return css;
    }

    row_css(row) {
  	    var css = {};
		if(this.state.row) {
		if(this.state.row.ank == row.ank) {
			css.background = "#080";
			css.color = "#fff";
		}
		}
		return css;
    }
	wnd_close = () => {
		//this.props.ctx.setState({ login: false });
	};

    mnu_show() {
    };
    usr_info() {
    };
    fknGo(url) {
        var op = this.state.op;
        var pa = url.split("/");
        if(pa.length > 1) op = pa[2];
        this.setState({op: op });

        if(url == "/pos/") {
            //window.location.reload(true);
        }
        this.props.navigate(url);
    };

    render() {

        return (
<div className="web-app">

    <div className="web-body flex-col">

        <div className="flex-center">
            <div className="txt_22">SVEPOS SHOP</div>
        </div>
        <div className="divider_orange"></div>
        <div className="flex-center">
            <div className="txt_22">VÄLKOMNA</div>
        </div>
        <div className="flex-center">
            <div className="txt_18">ENHET ÄR STÄNGD</div>
        </div>
        <div className="flex-center">
            <div className="txt_18">MOBILORDER TIDER : { utils.toTid(sbo.cfg.ftid) } - { utils.toTid(sbo.cfg.ttid) }</div>
        </div>
        <div className="flex flex-center">
            <div className="txt_18">VÄLKOMMEN ÅTER</div>
        </div>
        <div className="divider_orange"></div>

        <div className="divider_orange"></div>
        <div className="flex-center py-2">
            <img className="svk-mini-logo" src={ logo } />
        </div>

    </div>
</div>
        );
    }
}
export default withRouter(observer(Flow_Closed));
